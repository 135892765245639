import React, { useEffect } from 'react';

function AreYouSure({ deleteFunc, itemToDelete, closeAreYouSure, closeImageModal }) {

      // Function to handle the click outside of the modal
      const handleClickOutside = (event) => {
        // Assuming 'p-modal-container' is the outermost container of the modal
        if (event.target.classList.contains('p-modal-container')) {
            closeAreYouSure();
        }
    }

    // Add event listener when the component mounts
    useEffect(() => {
        window.addEventListener('click', handleClickOutside);

        // Remove event listener on cleanup
        return () => window.removeEventListener('click', handleClickOutside);
    }, []); // Empty dependency array ensures this effect runs only once



    // return (
    //     <div className='p-modal-container'>
    //         <div className='p-modal' style={{ position: 'relative'}}>
    //             <p style={{ position: 'absolute', right:10, top: 5, fontSize: '24px' }} onClick={() => closeAreYouSure()}>&#10005;</p>
    //             <div className='are-you-sure' style={{ marginTop: '10px' }}>
    //                 <span>Choose an action for {itemToDelete}</span>
    //             </div>
    //             <div className='modal-body' style={{ paddingTop: 0 }}>
    //                 <button
    //                     data-dismiss={closeImageModal ? 'modal' : ''}
    //                     onClick={() => { makePrimaryFunc(); closeAreYouSure(); }}
    //                 >
    //                     Make <b>{itemToDelete}</b> Primary word
    //                 </button>
    //                 <button
    //                     data-dismiss={closeImageModal ? `modal` : ""}
    //                     onClick={() => { deleteFunc(); closeAreYouSure(); }}
    //                 >
    //                     Delete <b>{itemToDelete}</b>
    //                 </button>
    //             </div>
    //         </div>
    //     </div>
    // )
    return (
        <div className='p-modal-container'>
            <div className='p-modal'>
                <div className='are-you-sure'>
                    <span>Are you sure you want to delete {itemToDelete}?</span>
                </div>
                <div className='yes-no'>
                    <button
                        data-dismiss={closeImageModal ? `modal` : ""}
                        onClick={() => { deleteFunc(); closeAreYouSure(); }}
                    >
                        Yes
                    </button>
                    <button onClick={() => closeAreYouSure()}>
                        No
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AreYouSure