import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Feedback() {
    const { t } = useTranslation();


    return (
        <div style={{ height: 'calc(100vh)' }}>
            <h1 className='section-header'>{t('feedback')}</h1>
            <iframe 
                title='feedback-form' 
                src="https://docs.google.com/forms/d/e/1FAIpQLScsErDUdUqR1IkFZZTOOfB06ubXyR9N3cLTmY9CpLjjxHC8LQ/viewform?embedded=true" 
                width="100%" 
                height="80%" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0">
                    Loading…
                </iframe>
        </div>
    )
}
