import React from 'react';

export function MenuCard(props) {
    return (
        <div className={`menu-card ${props.className}`} onClick={props.myFunction}>
            <i className={props.icon_class}></i>
            <h2 className="card-title">{props.title}</h2>
        </div>
    )
}
