import React, { Component } from 'react';
import { Translation as I18Translation } from 'react-i18next';
import { toast } from 'react-toastify';
import AboutCard from '../../components/AboutCard';
import ImageContent from '../../components/ImageContent';
import Loading from '../../components/Loading';
import { translation } from '../../helper/api';
import { getRandomIndex } from '../../helper/randomizer';
import image1 from '../../images/img1.png';
import { Camera } from './Camera';


// activity 1; user is asked to find a prompted object near them
export class Activity1 extends Component {
    constructor() {
        super();

        this.state = {
            show: true,
            mode: 0,
            pictureArray: [],
            detectionArray: [],
            objectToFind: '',
            translatedObjectToFind: '',
            previousObject: '',
            picture: '',
            pictureTaken: true,
            labels: [],
            imageID: 0
        }
    }

    handleCameraShow = () => {
        this.setState({
            show: !this.state.show
        }, () => this.setState({ mode: 2 }));
    }

    componentDidMount() {
        console.log("Activity 1 mounted");
        console.log("Activity 1:", this.props.pictureArray, this.props.detectionArray);
        // save picture and detections to component
        this.setState({ pictureArray: this.props.pictureArray, detectionArray: this.props.detectionArray }, () => {
            // select random picture to find
            let index = getRandomIndex(this.state.pictureArray.length);

            // get random item from list [[] []], this is a 2d array, so index will select an array with the corresponding picture based on the index
            let detection = this.state.detectionArray[index];

            // select random item from list
            let object = detection[getRandomIndex(detection.length)];

            console.log(object, detection);
            
            translation(object)
                .then(data => this.setState({
                    translatedObjectToFind: data.translations[0] 
                }, () => {
                    // set object to find
                    this.setState({ 
                        objectToFind: object, 
                        // previousObject: object 
                        previousObject: this.state.translatedObjectToFind
                    }, () =>  {
                    console.log(this.state.objectToFind);
                    });
                }));

            
        })
    }

    componentWillUnmount() {
        console.log("Activity 1 Unmounted");
    }


    // handle saving the photo to get more information on it
    handlePhoto() {
        if (this.state.picture === null) return;

        this.setState({ pictureTaken: true }, async () => {
            try {
                const response = await fetch(`api/image`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        token: localStorage.token
                    },
                    body: JSON.stringify({ dataUri: this.state.picture })
                });
                const data = await response.json();

                console.log('data', data);

                this.setState({ 
                    labels: data[0].labels,
                    imageID: data[0].data_id
                });
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        })
    }
    setLabels = (newLabels) => this.setState({ labels: newLabels });

    render() {
        const { show, previousObject, objectToFind, picture, labels, imageID, translatedObjectToFind } = this.state;

        return (
            <div>
                { this.state.mode === 0 ? 
                    <AboutCard 
                        // iconImage={ 'fas fa-search' }
                        titleWord={ translatedObjectToFind }
                        image={ image1 }
                        cardTitle={ <I18Translation>{(t) => t('activity1Title')}</I18Translation> }
                        cardInstructions={ <>
                                            <I18Translation>{(t) => t('activity1Info5')}</I18Translation>{' '}
                                            <strong style={{ color: '#0275d8' }}>{translatedObjectToFind}</strong>, <I18Translation>{(t) => t('activity1Info6')}</I18Translation>.
                                        </> }
                        btnText={ 'Start Activity' }
                        StateFunction={() => this.setState({ mode: 1 })}
                        cssClass="about-popup"
                    />
                : null }
                { this.state.show && this.state.mode === 1 ? 
                    <Camera 
                        show={ show }
                        handleCameraShow={ this.handleCameraShow }
                        {...this.props}
                        sendPictureArray={(data) => this.setState({ pictureArray: data })}
                        sendDetections={(data) => {
                            // loop throught picture array then find individually
                            for (let pictureIdx = 0; pictureIdx < this.state.detectionArray.length; pictureIdx++) {
                                for (let detectionIdx = 0; detectionIdx < this.state.detectionArray[pictureIdx].length; detectionIdx++) {
                                    // store picture from picture array
                                    this.setState({ picture: this.state.pictureArray[pictureIdx] });
                                    console.log("Picture and word found");
                                }
                            }

                            // select random picture to find
                            let index = getRandomIndex(this.state.pictureArray.length);

                            // get random item from list [[] []], this is a 2 array, so index will select an array with the corresponding picture based on the index
                            let detection = data[index];
                                                    
                            // select random item from list
                            let object = detection[getRandomIndex(detection.length)];
                                                    
                            // set object to find
                            this.setState({ objectToFind: object }, () => console.log(this.state.objectToFind));
                        }}
                    />
                : null }
                { this.state.mode === 2 ? 
                    <div className='picture-mode-container'>
                        <div className='image-content'>
                            <div className='image-container'>
                                <h1 style={{ textAlign: 'center' }}>
                                    <I18Translation>{(t) => t('activity1Info3')}</I18Translation> 
                                     {' '}
                                    <h1 className='item-found'>{previousObject}</h1>
                                    {/* <Translation text={ previousObject } /> */}
                                </h1>
                                <div className='image-label'>
                                    <img src={ this.state.picture } alt='user-pic-taken' />
                                </div>
                                <div className='instructions'>
                                    <p><I18Translation>{(t) => t('activity1Info4')}</I18Translation>.</p>
                                    
                                </div>
                            </div>
                            <div className='image-info'>
                                <div className='image-nav'>
                                    <div className='back' onClick={() => {
                                            this.setState({ mode: 3 });
                                            this.handlePhoto();
                                        }}>
                                        <i class="fas fa-solid fa-download"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('saveImage')}</I18Translation></div>
                                    </div>

                                    <div className='back' onClick={() => {
                                            this.props.handleOptionChange();
                                        }}>
                                        <i class="fas fa-solid fa-arrow-right"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('nextActivity')}</I18Translation></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 3 && this.state.pictureTaken && labels.length > 0 ? 
                    <div className='picture-mode-container'>
                        <div className='image-content'>
                            <ImageContent picture={ picture } labels={ labels } setLabels={ this.setLabels } imageID={ imageID } />
                            <div className='image-info'>
                                <div className='image-nav'>
                                    <div className='back' onClick={() => this.setState({ pictureTaken: false, picture: "", labels: [] })}>
                                        <i className="fas fa-camera"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('takeAnother')}</I18Translation></div>
                                    </div>
                                    <div className='back' onClick={() => this.props.history.push("/my-memories")}>
                                        <i className="fas fa-image"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('memories')}</I18Translation></div>
                                    </div>
                                    <div className='back' onClick={() => this.props.handleOptionChange()}>
                                        <i className='fas fa-solid fa-arrow-right'></i>
                                        <div className='take-another'><I18Translation>{(t) => t('continue')}</I18Translation></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 3 && labels.length === 0 && <Loading /> }
            </div>
        )
    }
}