import React, { useContext, useEffect } from 'react';
import { Navbar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { LoginContext } from '../helper/LoginContext';


function BottomBar({ previousPage }) { // This just takes you home - see about tracking the current and previous routes to make this go back to the previous route
    // const location = useLocation();
    const history = useHistory();
    const { expanded, setExpanded, logout, language } = useContext(LoginContext);

    const { t } = useTranslation(); 
    // const { pathname } = location;

    useEffect(() => {}, [language]);

    return (
        <Navbar id="navbar" className="BottomBar" collapseOnSelect expand="lg" expanded={expanded}>
            <div
                className='back'
                onClick={() => {
                    history.push("/dash-board");
                }}
            >
                <i className="fas fa-angle-left"></i><div>{t('back')}</div>
            </div>
            <div className='language'>
                    <Link to="/settings" className="nav-link" onClick={() => setExpanded(false)}>
                        { language }
                    </Link>
            </div>
            <>
                <i className={`fas ${!expanded ? 'fa-bars' : 'fa-chevron-right'}`} onClick={() => setExpanded(!expanded)}></i>
                {expanded &&
                    <div className='menu'>
                        <Link to="/dash-board" className="nav-link" onClick={() => setExpanded(false)}>
                            Home
                        </Link>
                        <hr></hr>
                        <Link to="" className="nav-link" onClick={(e) => { setExpanded(false); logout(e); }}>
                            Logout
                        </Link>
                    </div>
                }
            </>
        </Navbar>
    )
}

export default BottomBar