import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { PhotoUpload } from '../../components/PhotoUpload';
import { LoginContext } from '../../helper/LoginContext';
import { ImageModal } from './ImageModal';
// import { GalleryGrid } from './GalleryGrid';
import { useTranslation } from 'react-i18next';
import useLongPress from '../../helper/useLongPress';

export function Memories() {
     let history = useHistory();

     const { imageCount } = useContext(LoginContext);

     const baseLoadingTime = 15000;
     const loadingTimePerImage = 175;
     const [isLoading, setIsLoading] = useState(false);
     const [images, setImages] = useState([]);
     const [labels, setLabels] = useState([]);
     const [ID, setID] = useState(0);
     const HTTP_TIMEOUT = baseLoadingTime + (imageCount * loadingTimePerImage);

     const onLongPress = useLongPress();
     const [selectedImages, setSelectedImages] = useState([]);

     const { t } = useTranslation();

     useEffect(() => {
          // console.log(HTTP_TIMEOUT);
          // console.log("Gallery loaded");
          setIsLoading(true);
          localStorage.setItem("whichUrl", "/my-memories");
          fetchImages()
               .then((data) => setImages(data))
               .finally(() => setIsLoading(false));
     }, []);

     async function fetchImages() {
          const abortController = new AbortController();
          const timeoutId = setTimeout(() => abortController.abort(), HTTP_TIMEOUT);
          try {
               const response = await fetch(`api/gallery-images`, {
                    method: "GET",
                    headers: { token: localStorage.token },
                    signal: abortController.signal
               });
               
               const parseResponse = await response.json();

               return parseResponse;
          } catch (error) {
               if (error.name === 'AbortError') {
                    history.push("/dash-board");
                    toast.error("There was an error getting your images. Please try again later.")
               } else {
                    console.error(error.message);
                    toast.error(error.message);
               }
          } finally {
               clearTimeout(timeoutId);
          }
     }

     async function deleteImage(id) {
          try {
               const response = await fetch(`api/image/${id}`, {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json", token: localStorage.token }
               });
               const data = await response.json();
               // if (data !== undefined) alert(`Picture #${id} has been deleted.`);
               if (data !== undefined) toast.success(`Picture #${id} has been deleted.`);
          } catch (error) {
               console.error(error.message);
               toast.error(error.message);
          }
          setImages(images?.filter(item => item?.id !== id));
     }

     async function handleNewLabel(newLabel, imageId) {
          console.log(`sending ${newLabel} for image ${imageId}`);
     }

     // used for updating state when a new image is uploaded
     const updateImagesList = (newImage) => {
          setImages(prevImages => [newImage, ...prevImages]);
     }

     // used for long pressing on image
     const toggleImageSelection = (imageId) => {
          setSelectedImages(prevSelectedImages => {
              if (!prevSelectedImages.includes(imageId)) {
                  return [...prevSelectedImages, imageId];
              }
              return prevSelectedImages;
          });
      };
      


     return (
          <>
               <div className='gallery-wrap'>
                    <div className='section-header-container'>
                         <h1 className='section-header'>
                              {t('memories')}
                         </h1>
                         {
                              <div style={{ position: 'fixed', top: 0, right: 0 }}>
                                   {
                                        selectedImages.length > 0 && 
                                        <div className='selected-container-delete' onClick={() => {
                                             // run the delete function in a loop
                                             selectedImages.forEach(imageId => {
                                                  deleteImage(imageId);
                                             });

                                             // reset selected images
                                             setSelectedImages([]);
                                        }}>
                                             <i className='fas fa-solid fa-trash'></i>
                                        </div>
                                   }
                                   {/* Picture mode button on header */}
                                   {/* <div className='selected-container-picture' onClick={() => window.location.replace('/eye-mode')}>
                                        <i className="fas fa-camera"></i>
                                   </div> */}
                              </div>
                         }
                    </div>
                    
                    { isLoading ? 
                         <div style={{ paddingTop: '60px' }}>
                              <Loading /> 
                         </div>
                    :
                    <div className='gallery-container' style={{ paddingTop: '80px' }}>
                              <>
                                   {typeof images === 'string' || images instanceof String ?
                                        <div>{images}</div>
                                        :
                                        images.length > 0 &&
                                        images.map((item, i) => (
                                             <div key={i} className="gallery-wrapper">
                                                  <div className='grid-gallery-wrapper' style={{ backgroundColor: selectedImages.includes(item.id) && '#0d968b'  }}>
                                                       <img src={item.image}
                                                            alt="pic-taken"
                                                            className='gallery-img'
                                                            data-toggle="modal"
                                                            data-target={`#id${item.id}`}
                                                            onClick={() => {
                                                                 // log which item clicked action
                                                                 // logAction('memories', `Image viewed: ${item.id} of ${item.labels[0]}`);
                                                                 // console.log('image id:',item.id);

                                                                 // when image is selected then run remove selected function
                                                                 if (selectedImages.includes(item.id)) {
                                                                      setSelectedImages(prevSelectedImages => {
                                                                           if (prevSelectedImages.includes(item.id)) {
                                                                                return prevSelectedImages.filter(id => id !== item.id);
                                                                           }
                                                                      })
                                                                 } else {
                                                                      setID(item.id);
                                                                      setLabels(item.labels);
                                                                      setSelectedImages([]);
                                                                 }
                                                            }}
                                                            onContextMenu={(e) => e.preventDefault()} // prevents default browser context menu
                                                            { ...onLongPress(() => toggleImageSelection(item.id)) }
                                                       />
                                                  </div>
                                                  {ID === item.id &&
                                                       <ImageModal
                                                            id={item.id}
                                                            image={item.image}
                                                            labels={labels}
                                                            setLabels={setLabels}
                                                            deleteImage={deleteImage}
                                                       />
                                                  }
                                             </div>
                                        ))
                                   }
                              </>
                    </div>
                    }
               </div>
               
               {/* <div 
                    style={{ 
                         position: 'fixed', 
                         right: 10, 
                         bottom: 60, 
                         backgroundColor: 'white',
                         borderRadius: '5px', 
                         padding: '5px' }} 
                    className='upload-wrapper'
                    >
                         <PhotoUpload onImageUpload={updateImagesList} />
               </div> */}
          </>
     );
}