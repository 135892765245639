import React from 'react'

export function Modal(props) {
    return (
        <div className="modal fade" id={ props.id } tabIndex="-1" role="dialog" aria-labelledby={ props.labelby } aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={ props.id }>Select most appropriate label:</h5>
                        <button type="button"  className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ul>
                            {
                                props.labels &&
                                props.labels.map((label, index) => (
                                    <li key={ index } onClick={ (e) => props.myFunction(e) } data-dismiss="modal" >{ label }</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save Label</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

// eslint-disable-next-line no-lone-blocks
{/* <div className="modal fade" id="selectModal" tabIndex="-1" role="dialog" aria-labelledby="selectModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="selectModalLabel">Select most appropriate label:</h5>
                                        <button type="button"  className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            labels &&
                                            labels.map((label, index) => (
                                                <ul>
                                                    <li key={ index } onClick={(e) => setWordToHunt(e.target.innerText) }>{ label }</li>
                                                </ul>
                                            ))
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save Label</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
