import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export function AccessCode() {
    const [inputs, setInputs] = useState({ access_code: "" });
    const [accessCode, setAccessCode] = useState('');
    
    let history = useHistory();
    
    const { email } = inputs;

    const onChange = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value});
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        try {
            const body = { email };
            const response = await fetch(`auth/access-code`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });
            const parseResponse = await response.json();

            if (parseResponse.access_code) {
                toast.success(`An Email Has Been Sent To ${email}`);
                setAccessCode(parseResponse.access_code);
            } else {
                toast.error(parseResponse);
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    return (
        <div className="page-wrapper light">
            <div className="login-container">
                <h1 className="login-title">Get Access Code</h1>
                <form onSubmit={ e => onSubmitForm(e) } className="form-container">
                    <input type="email" name="email" placeholder="Email..." className="form-input" value={ email } onChange={ e => onChange(e) } required />
                    <button className="sign-in-btn" type="submit">Get Access Code</button>
                </form>
                {accessCode.length > 0 ? <h1>Your access code: <strong onClick={() => navigator.clipboard.writeText(accessCode).then(() => toast.info('Access copied to clipboard'))}>{accessCode}</strong></h1> : <></>}
                <div>Want to Login? <Link to="/login">Login</Link></div>
            </div>
        </div>
    )
}