import React, { Component } from 'react';

import * as workerTimers from 'worker-timers';
import { WebCam } from './WebCam';
import { Modal } from './Modal';

export class Option4 extends Component {
     constructor() {
          super();

          this.state = {
               initialTime: 15,
               objectDetections: [],
               filterDone: false,
               progress: 100,
               cameraReady: false
          }
     }

     componentDidMount() {
          this.interval = workerTimers.setInterval(() => {
               this.setState({ initialTime: this.state.initialTime - 1}); 
               if (this.state.initialTime <= 0) {
                    workerTimers.clearInterval(this.interval);
               }
          }, 1000);
     }

     render() {
          const { progress } = this.state;
          return (
               <div className='App'>
                    <div className='App-header'>
                         <div className='timer'>
                              <p>Time Remaining: { this.state.initialTime }s</p>
                              <div className='progress' style={{ width: `${(progress/15) * this.state.initialTime}%`, transition: '0.2s' }}></div>
                         </div>
                         {
                              !this.state.filterDone && <WebCam toFind={""} finished={ this.state.initialTime <= 0 ? true : false } allDetections={(detections) => this.setState({ objectDetections: [...new Set(detections)] }, () => { this.setState({ filterDone: true }) })} />      
                         }
                         {
                              this.state.filterDone && <Modal option={ 4 } detections={ this.state.objectDetections } />
                         }
                    </div>
               </div>
          );
     }

     componentWillUnmount() {
          workerTimers.clearInterval(this.interval);
     }
}