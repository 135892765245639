import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { LoginContext } from '../../helper/LoginContext';

function Achievements() {
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [achievements, setAchievements] = useState([]);
    const [chosenWordHistory, setChosenWordHistory] = useState([]);
    const [bestToWorst, setBestToWorst] = useState(true);
    const [image, setImage] = useState(null);
    const [loadingImg, setLoadingImg] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const { language } = useContext(LoginContext);
    const HTTP_TIMEOUT = 10000;
    const { t } = useTranslation();

    const languages = { en: "English", fr: "French", es: "Spanish" };

    const iconAndLabels = [
        { icon: 'fa-images', label: "No. of attempts" },
        { icon: 'fa-image', label: "Chose correct image" },
        { icon: 'fa-keyboard', label: "Filled in blank" }
    ];

    useEffect(() => { getResults() }, []);

    // useEffect(() => {
    //     if (chosenWordHistory.length > 0) {
    //         // fetch image related to word in chose word history
    //         const word = chosenWordHistory[0].label.toLowerCase();
    //         async function fetchImage(_word) {
    //             setLoadingImg(true);
    //             try {
    //                 const response = await fetch(`api/achievement-image?word=${_word}`, {
    //                     method: "GET",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                         token: localStorage.token
    //                     }
    //                 });
    //                 const data = await response.json();
    //                 console.log(data);
    //                 if (data.image) {
    //                     setImage(data);
    //                 }
    //             } catch (error) {
    //                 console.error(error);
    //             } finally {
    //                 setLoadingImg(false);
    //             }
    //         }

    //         fetchImage(word);
    //     }
    // }, [chosenWordHistory]);

    // useEffect(() => {
    //     achievements.map((ach, a) => { console.log(ach.totalForRanking, ach.label) })
    // }, [bestToWorst]);

    async function getResults() {
        const abortController = new AbortController();
        const timeoutId = setTimeout(() => abortController.abort(), HTTP_TIMEOUT);
        try {
            const response = await fetch('results/getAchievements', {
                method: "GET",
                headers: { "Content-Type": "application/json ", token: localStorage.token },
                signal: abortController.signal
            });
            const data = await response.json();

            if (data.achievements.length <= 0) {
                history.push("/dash-board")
                toast.warning(`You have no achievements in ${languages[language]}, try the Review Memories mode to view your achievements.`)
            }
            console.log(data)

            setAchievements(data.achievements.sort((a, b) => b.proficiencyRatio - a.proficiencyRatio));
            setIsLoading(false);
        } catch (error) {
            if (error.name === 'AbortError') {
                history.push("/dash-board");
                toast.error("Unable to view your achievements, please contact the supervisor for this app.");
            } else {
                console.error(error.message);
            }
        } finally {
            clearTimeout(timeoutId);
        }
    }

    function switchOrder() {
        setAchievements(
            achievements.sort((a, b) => {
                if (bestToWorst) return a.proficiencyRatio - b.proficiencyRatio;
                return b.proficiencyRatio - a.proficiencyRatio;
            })
        );
        setBestToWorst(!bestToWorst);
    }

    return (
        <>
            <div className='Achievements'>
                <h1 className='section-header'>{t('achievements')}</h1>
                {isLoading && <Loading />}
                {!isLoading && achievements.length > 0 && chosenWordHistory.length === 0 &&
                    <div className='achievement-list'>
                        {achievements.map((achievement, a) => {
                            const { label, wordHistory, averageAttempts, averageChosenCorrectPicture, averageSpelledCorrect, proficiencyRatio, image } = achievement;

                            return (
                                <div key={a} className='word-achievement' onClick={() => {
                                    setChosenWordHistory(wordHistory); 
                                    setImage(image)
                                }}>
                                    <div className='head'>
                                        <div className='head-corner left'>{a + 1}.</div>
                                        <div className='word'>
                                            {/* <TranslationNew text={label.toLowerCase()} /> */}
                                            <h1>{label}</h1>
                                            <span style={{ fontSize: 12, margin: 0 }}>
                                                <p style={{ margin: 0, fontWeight: 'bold'}}>{ wordHistory.length <= 1 ? `You have reviewed this word ${wordHistory.length} time` : `You have reviewed this word ${wordHistory.length} times` }</p>
                                                <p style={{ margin: 0 }}>proficiency ratio: {Math.round(proficiencyRatio)} %</p>
                                            </span>
                                        </div>
                                        <i className='head-corner fas fa-history' onClick={() => { 
                                            // setChosenWordHistory(wordHistory); 
                                            // setImage(image)
                                        }}></i>
                                    </div>
                                    <div className='averages'>
                                        {iconAndLabels.map((iconAndLabel, i) =>
                                            <div className='average' key={i}>
                                                <div className='title'>
                                                    <i className={`fas ${iconAndLabel.icon}`}></i>
                                                    <div className='label'>{iconAndLabel.label}</div>
                                                </div>
                                                <div
                                                    className='value'
                                                    style={{
                                                        backgroundColor:
                                                            `${{ // switch case
                                                                0: `hsla(0, 0%, 57%, ${100 - ((averageAttempts / 4) * 100) + 20}%)`,
                                                                1: `hsla(0, 0%, 57%, ${averageChosenCorrectPicture + 20}%)`,
                                                                2: `hsla(0, 0%, 57%, ${averageSpelledCorrect + 20}%)`
                                                            }[i]}`
                                                    }}
                                                >
                                                    {{ // switch case
                                                        0: <>{averageAttempts}</>,
                                                        1: <>{averageChosenCorrectPicture === null ? <span style={{ opacity: 0.5 }}>N/A</span> : <>{averageChosenCorrectPicture}%</>}</>,
                                                        2: <>{averageSpelledCorrect === null ? <span style={{ opacity: 0.5 }}>N/A</span> : <>{averageSpelledCorrect}%</>}</>
                                                    }[i]}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
                
                {
                    chosenWordHistory.length > 0 &&
                    <div
                    // className='modal' //////////////// MODAL DOESN'T WORK /////////////////
                    // tabIndex="-1"
                    // role="dialog"
                    // aria-labelledby='exampleModalLabel'
                    // aria-hidden="true"
                    >
                        <div className='achievement-list'>
                            <div className='word-achievement'>
                                <div className='head'>
                                    <div className='head-corner left'></div>
                                    <div className='word'>
                                        {/* <TranslationNew text={chosenWordHistory[0].label.toLowerCase()} /> */}
                                        { chosenWordHistory[0].label }
                                    </div>
                                    <span className='close' aria-hidden="true" data-dismiss="modal" aria-label='Close' onClick={() => {
                                        setChosenWordHistory([]);
                                        setImage(null);
                                    }}>&times;</span>
                                </div>
                                <div className='averages'>
                                    {iconAndLabels.map((iconAndLabel, i) =>
                                        <div className='average' key={i}>
                                            <div className='title'>
                                                <i className={`fas ${iconAndLabel.icon}`}></i>
                                                <div className='label'>{iconAndLabel.label}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                { 
                                    loadingImg ? <Loading /> : 
                                    image && <img className='achievement-img' src={image} alt={`${chosenWordHistory[0].label.toLowerCase()}-img`}></img> 
                                }
                                {chosenWordHistory.map((item, i) => {
                                    const { attempts, chosen_correct_picture, created_at, spelled_correct } = item;

                                    return (
                                        <div key={i} className='history-achievement'>
                                            <div className='date'>{created_at.substr(0, 10)}</div>
                                            <div className='averages'>
                                                <div className='value history-value'>{attempts}/4</div>
                                                {[chosen_correct_picture, spelled_correct].map((value, v) =>
                                                    <div className='value history-value' key={v}>
                                                        {{ // switch case
                                                            null: <>N/A</>,
                                                            true: <i className='fas fa-check-circle'></i>,
                                                            false: <i className='fas fa-times-circle'></i>
                                                        }[value]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='sort' onClick={() => switchOrder()}>
                <div className='take-another'>{bestToWorst ? <>Best</> : <>Worst</>}</div>
                <i className="fas fa-sort"></i>
                <div className='take-another'>{bestToWorst ? <>Worst</> : <>Best</>}</div>
            </div>
        </>
    )
}

export default Achievements