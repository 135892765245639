import React, { Component } from 'react';
import { Translation as I18Translation } from 'react-i18next';
import { toast } from 'react-toastify';
import AboutCard from '../../components/AboutCard';
import Loading from '../../components/Loading';
import Translation from '../../components/Translation';
import { getRandomIndex } from '../../helper/randomizer';
import image2 from '../../images/img2.png';



// activity 2: user is asked to find the 'fake' item present in the list
export class Activity2 extends Component {
    constructor() {
        super();

        this.state = {
            show: true,
            mode: 0,
            pictureArray: [],
            detectionArray: [],
            guess: '',
            randomItem: '',
            isCorrect: false,
            guessSentence: '',
            guessDefinition: '',
            randomItemSentence: '',
            randomItemDefinition: '',
            pressmonGuessSentence: { translated: '', english: '' },
            pressmonRandomSentence: { translated: '', english: '' },
            showDef: true,
            showSent: false
        }
    }

    /**
     * TODO: RECHECK LOGIC
     */
    componentDidMount() {
        console.log("Activity 2 mounted");
        console.log("Activity 2:", this.props.pictureArray, this.props.detectionArray);
        this.setState({ detectionArray: this.props.detectionArray, pictureArray: this.props.pictureArray }, () => {
            // flatten detection array
            let flattenDetections = [...this.state.detectionArray.flat()];

            // get random item from list
            let randomItem = flattenDetections[getRandomIndex(this.state.detectionArray[0].length)];

            console.log("Random item", randomItem);

            // get related object to random item
            this.getRelatedObject(randomItem).then(data => {
                console.log("Related word is", data[0].word);
                // randomItem: randomItem
                this.setState({ guess: data[0].word, randomItem: randomItem }, () => {
                    var rand = Math.floor(Math.random() * (this.state.detectionArray.flat().length - 0)) + 1
                    flattenDetections.splice(rand, 0, this.state.guess);

                    // update state of detection array and remove all duplicates after inserting element (!might have to remove duplicates before!)
                    this.setState({ detectionArray: [...new Set(flattenDetections)] });
                });
            });
        })
    }

    componentWillUnmount() {
        console.log("Activity 2 Unmounted");
    }

    // triggers the redo functionality when mode changes to back to 1 or anything but 2
    componentDidUpdate(prevProps, prevState) {
        if (this.state.mode !== prevState.mode) {
            // if the previous state was 2 (not current state) meaning the mode changed to something but 2
            if (prevState.mode === 2) {
                this.setState({ detectionArray: this.props.detectionArray, pictureArray: this.props.pictureArray }, () => {
                    // flatten detection array
                    let flattenDetections = [...this.state.detectionArray.flat()];
        
                    // get random item from list
                    let randomItem = flattenDetections[getRandomIndex(this.state.detectionArray[0].length)];
        
                    console.log("Random item", randomItem);
        
                    // get related object to random item
                    this.getRelatedObject(randomItem).then(data => {
                        console.log("Related word is", data[0].word);
                        // randomItem: randomItem
                        this.setState({ guess: data[0].word, randomItem: randomItem }, () => {
                            var rand = Math.floor(Math.random() * (this.state.detectionArray.flat().length - 0)) + 1
                            flattenDetections.splice(rand, 0, this.state.guess);
        
                            // update state of detection array and remove all duplicates after inserting element (!might have to remove duplicates before!)
                            this.setState({ detectionArray: [...new Set(flattenDetections)] });
                        });
                    });
                });
            }
            if (this.state.mode === 2) {
                // if mode 2 is mounted then fetch word info
                this.wordInfo(this.state.guess).then(data => this.setState({ guessSentence: data.example, guessDefinition: data.definition }));
                this.wordInfo(this.state.randomItem).then(data => this.setState({ randomItemSentence: data.example, randomItemDefinition: data.definition }) );
                // GET PRESSMON sentence
                this.getPressmonSentence(this.state.guess).then(data => {
                    this.setState({
                        pressmonGuessSentence: {
                            translated: data.translated === undefined ? "Could not find example for this word." : data.translated,
                            english: data.english === undefined ? "Could not find example for this word." : data.english[0]
                        }
                    });

                });
                this.getPressmonSentence(this.state.randomItem).then(data => {
                    this.setState({
                        pressmonRandomSentence: {
                            translated: data.translated === undefined ? "Could not find example for this word." : data.translated,
                            english: data.english === undefined ? "Could not find example for this word." : data.english[0]
                        }
                    });
                });
            }
            
        }
    }


    // function to get a related item to a word
    async getRelatedObject(item) {
        try {
            const abortController = new AbortController();

            const response = await fetch(`word/datamuse?word=${item}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                    signal: abortController.signal
                }
            });

            const parseResponse = await response.json();
            return parseResponse;
        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    }

    // fetch example sentence and definition guess and randomItem words
    async wordInfo(word) {        
        try {
            const response = await fetch(`word/word-info`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.token
                },
                body: JSON.stringify({ label: word })
            });
            const data = await response.json();
            console.log('word', word);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    // TODO: Get example sentence from pressmon
    async getPressmonSentence(text) {
        try {
            const response = await fetch(`word/pressmon?word=?${text}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        return (
            <div>
                { this.state.mode === 0 ? 
                    <AboutCard 
                        image={ image2 }
                        cardTitle={ <I18Translation>{(t) => t('activity2Title')}</I18Translation> }
                        // cardInstructions={ 'Which item was not present in the scan you made' }
                        cardInstructions={<I18Translation>{(t) => t('activity2Info')}</I18Translation>}
                        btnText={ 'Start Activity' }
                        StateFunction={() => this.setState({ mode: 1 }) }
                        cssClass="about-popup"
                    />
                : null }
                { this.state.mode === 1 ? 
                <div className='Home'>
                    <h1 className='section-header' style={{ marginTop: 0 }}>LangEye</h1>
                    <div className='c-container'>
                        <div className='c-header'>
                            <h1><I18Translation>{(t) => t('activity2Title')}</I18Translation></h1>
                        </div>
                        <p><I18Translation>{(t) => t('activity2Info3')}</I18Translation>.</p>
                        <ul className='c-body'>
                            {
                                this.state.detectionArray.map((item, index) => (
                                    <li key={ index } onClick={(e) => {
                                        // console.log(e.target.innerText);
                                        // console.log("activity 2 debug:", item);
                                        if (item !== this.state.guess) {
                                            e.target.classList.add("incorrect");
                                        } else if (item === this.state.guess) {
                                            e.target.classList.add("correct");
                                            setTimeout(() => {
                                                this.setState({ isCorrect: true, mode: 2 })
                                            }, 1500);
                                        }
                                    }}><Translation text={ item } /></li>
                                ))
                            }
                        </ul>

                        {/* <div className='p-modal-footer'>
                            <button className='btn' onClick={() => this.props.handleOptionChange() }>Next</button>
                        </div> */}
                    </div>
                </div>
                : null }
                { this.state.mode === 2 ? 
                    <div className='picture-mode-container-a2' 
                        // style={{ height: '100vh', overflowY: 'scroll' }}
                    >
                        <div className='image-content'>
                            <div className='image-container'>
                                <div className='inline-text-container'>
                                    <h3 className='title'><I18Translation>{(t) => t('activity2Info1')}</I18Translation>: </h3>
                                    <p className='display-items'><Translation text={ this.state.guess }/></p>
                                </div>
                                <div className='inline-text-container'>
                                    <h3 className='title'><I18Translation>{(t) => t('activity2Info2')}</I18Translation>:</h3> 
                                    <p className='display-items'><Translation text={ this.state.randomItem } /></p>
                                </div>
                                
                                <div className='info-container'>
                                    <div className='inline-text-container-sub'>
                                        <h3 className='display-items'><Translation text={ this.state.guess } /> </h3>
                                        <p className='sentence'><strong><I18Translation>{(t) => t('definition')}</I18Translation>:</strong> { this.state.guessDefinition === '' ? <Loading /> : this.state.guessDefinition }</p>
                                        {/* <p className='sentence'><strong>Example Sentence:</strong> { this.state.guessSentence === '' ? <Loading /> : this.state.guessSentence }</p> */}
                                        <p className='sentence'><strong><I18Translation>{(t) => t('exampleSentence')}</I18Translation>:</strong> { this.state.pressmonGuessSentence.translated === '' ? <Loading /> : this.state.pressmonGuessSentence.translated }</p>
                                    </div>

                                    <div className='inline-text-container-sub'>
                                        <h3 className='display-items'><Translation text={ this.state.randomItem } /></h3>
                                        <p className='sentence'><strong><I18Translation>{(t) => t('definition')}</I18Translation>:</strong> { this.state.randomItemDefinition  === '' ? <Loading /> : this.state.randomItemDefinition }</p>
                                        {/* <p className='sentence'><strong>Example Sentence:</strong> { this.state.randomItemSentence === '' ? <Loading /> : this.state.randomItemSentence }</p> */}
                                        <p className='sentence'><strong><I18Translation>{(t) => t('exampleSentence')}</I18Translation>:</strong> { this.state.pressmonRandomSentence.translated === '' ? <Loading /> : this.state.pressmonRandomSentence.translated }</p>
                                    </div>
                                </div>
                            </div>
                            <div className='image-info-a2'>
                                <div className='image-nav'>
                                    <div className='back' onClick={() => this.setState({ mode: 1, guessDefinition: '', guessSentence: '', randomItemDefinition: '', randomItemSentence: '' })}>
                                        <i class="fas fa-solid fa-arrow-left"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('redo')}</I18Translation></div>
                                    </div>
                                    <div className='back' onClick={() => this.props.history.push("/my-memories")}>
                                        <i className='fas fa-image'></i>
                                        <div className='take-another'><I18Translation>{(t) => t('memories')}</I18Translation></div>
                                    </div>
                                    <div className='back' onClick={() => this.props.handleOptionChange()}>
                                        <i className='fas fa-solid fa-arrow-right'></i>
                                        <div className='take-another'><I18Translation>{(t) => t('continue')}</I18Translation></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        )
    }
}