import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { toast } from 'react-toastify';
import Webcam from 'react-webcam';
import * as workerTimers from 'worker-timers';
import RotateIcon from '../../images/ui-rotation.svg';

export class Camera extends Component {
    constructor() {
        super();

        this.cameraRef = React.createRef();

        this.state = {
            initialTime: 10,
            progress: 100,
            screenWidth: 0,
            screenHeight: 0,
            intervalId: 0,
            videoConstraints: {
                facingMode: "environment"
            },
            pictureArray: [],
            detectionArray: [],
            key: 0
        }
    }


    // set screen height and width and start timer when camera mounts
    componentDidMount() {
        // set screen height and width
        this.setState({ screenWidth: window.screen.width, screenHeight: window.screen.height });

        // start timer
        this.interval = workerTimers.setInterval(() => {
            this.setState({ initialTime: this.state.initialTime - 0.1 });
            if (this.state.initialTime <= 0.1) {
                workerTimers.clearInterval(this.interval);
            }
        }, 100);

        // scan new images every 2 seconds
        let timerInterval = setInterval(() => this.scan(), 2000);

        this.setState({ intervalId: timerInterval });

        console.log("Camera mounted");
    }

    // unmount component when initialTime state updates to <= 0.01
    componentDidUpdate(prevProps, prevState) {
        if (this.state.initialTime < prevState.initialTime) {
            // check when timer has ended
            if (this.state.initialTime <= 0.01) {
                console.log("timer ended");

                toast.success('Please wait while we finish processing the images', { autoClose: 3500 });
                
                // send picture to activities
                this.props.sendPictureArray(this.state.pictureArray);

                // process images and then unmount camera and send detections after 2 seconds
                this.handleImages().then(() => setTimeout(() => {
                    this.props.sendDetections(this.state.detectionArray);
                    this.props.handleCameraShow();
                }, 2000));
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        console.log("Camera unmounted");
    }

    // processes each picture taken
    async handleImages() {
        let labelList = [];
        for (let i = 0; i < this.state.pictureArray.length; i++) {
            let dataUri = this.state.pictureArray[i];
            let body = { dataUri };
            try {
                const response = await fetch(`api/detect-images`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        token: localStorage.token
                    },
                    body: JSON.stringify(body)
                });

                const data = await response.json();

                labelList.push(data);
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        }
        console.log("label list:", labelList);

        if (labelList.flat().length === 0) {
            this.props.handleCameraUnmountRemount(this.state.key + 1);
        }

        console.log("Camera debug:", labelList.map(arr => arr.filter(v => v.length > 0)));

        // set detections made
        this.setState({ detectionArray: labelList.map(arr => arr.filter(v => v.length > 0)) }, () => console.log(this.state.detectionArray));
    }

    // close camera
    async handleImagesOnClose() {
        let labelList = [];
        for (let i = 0; i < this.state.pictureArray.length; i++) {
            let dataUri = this.state.pictureArray[i];
            let body = { dataUri };
            try {
                const response = await fetch(`api/detect-images`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        token: localStorage.token
                    },
                    body: JSON.stringify(body)
                });

                const data = await response.json();

                labelList.push(data);

            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        }

        return labelList.map(arr => arr.filter(v => v.length > 0));
        // console.log("label list:", labelList);

        // if (labelList.flat().length === 0) {
        //     this.props.handleCameraUnmountRemount(this.state.key + 1);
        // }

        // console.log("Camera debug:", labelList.map(arr => arr.filter(v => v.length > 0)));

        // // set detections made
        // this.setState({ detectionArray: labelList.map(arr => arr.filter(v => v.length > 0)) }, () => console.log(this.state.detectionArray));
    }


    // function to scan area around you within the timer and pushes new images every 2 seconds
    scan() {
        console.log("Scanning");
        let newTime = this.state.initialTime - 1;
        if (newTime >= 0) {
            if (this.cameraRef.current !== 'undefined' || this.cameraRef.current !== null) {
                const imageSrc = this.cameraRef.current.getScreenshot();

                this.setState({ pictureArray: [...this.state.pictureArray, imageSrc] });
            }
        } else {
            clearInterval(this.state.intervalId);
            console.log("Scan finished");
        }
    }

    // close camera
    close() {
        console.log("closing, timer stopped");
        clearInterval(this.state.intervalId);

        // close camera
        this.props.handleCameraShow();

        toast.success('Please wait while we finish processing the images', { autoClose: 3500 });
                
        // process images and then unmount camera and send detections after 2 seconds
        this.handleImagesOnClose().then((data) => {
            console.log(this.state.pictureArray, data);
            // this.props.sendDetections(this.state.detectionArray);
            this.props.sendDetections(data);
            // send picture to activities
            this.props.sendPictureArray(this.state.pictureArray);
        });
    }

    render() {
        const { initialTime, progress } = this.state;

        return (
            <div className='picture-mode-container'>
                <div className='camera-container'>
                    <div className='timer-scan-mode'>
                        <p>{<Translation>{(t) => t('timeRemaining')}</Translation>}: { initialTime >= 0 ? Math.round(initialTime) : 0 }s</p>
                        <div className='progress' style={{ width: `${(progress/10) * this.state.initialTime}%`, transition: '0.2s' }}></div>
                    </div>

                    <div>
                        <Webcam 
                            audio={ false }
                            ref={ this.cameraRef }
                            width={ this.state.screenWidth }
                            screenshotFormat="image/png"
                            className='webcam'
                            videoConstraints={ this.state.videoConstraints }
                            onUserMediaError={() => {
                                console.log("Camera cannot be found");
                                this.props.history.push("/dash-board");
                            }}
                        />
                    </div>

                    <div className='camera-nav'>
                        <div className='flip-mode' onClick={() => {
                            let { videoConstraints } = this.state;

                            if (videoConstraints.facingMode === 'environment') {
                                this.setState({ videoConstraints: { facingMode: "user" }})
                            } else {
                                this.setState({ videoConstraints: { facingMode: "environment" }})
                            }
                        }}
                        ><img src={ RotateIcon } alt="Camera Rotate" /></div>

                        <div onClick={() => this.close()}>
                            Close
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}