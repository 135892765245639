import React, { useState } from 'react';
// import { toast } from 'react-toastify';
import AreYouSure from '../../components/AreYouSure';
import ImageContent from '../../components/ImageContent';
import NewLabel from '../../components/NewLabel';

export function ImageModal(props) {
     const [showAreYouSure, setShowAreYouSure] = useState(false);
     const [showNewLabel, setShowNewLabel] = useState(false);
     const [labels, setLabels] = useState(props.labels);
     const [newLabelData, setNewLabelData] = useState(null);

     function handleLabelInModal(label, id) {
          console.log('Label from new label:', label);
          console.log('Id from new label:', id);
     }

     async function handleNewLabel(newLabel, imageId) {
          console.log(`sending ${newLabel} for image ${imageId}`);
     }

     return (
          <div className={`modal fade`}
               id={`id${props.id}`}
               tabIndex="-1"
               role="dialog"
               aria-labelledby='exampleModalLabel'
               aria-hidden="true"
          >
               {showAreYouSure &&
                    <AreYouSure
                         deleteFunc={() => props.deleteImage(props.id)}
                         itemToDelete={`Picture #${props.id}`}
                         closeAreYouSure={() => setShowAreYouSure(false)}
                         closeImageModal={true}
                    />
               }
               {showNewLabel &&
                    <NewLabel 
                         imageId={props.id}
                         handleNewLabel={(label) => handleNewLabel(label, props.id)}
                         closeAreYouSure={() => setShowNewLabel(false)}
                         setNewLabelData={setNewLabelData}
                    />
               }
               <div className={`modal-dialog`} role="document">
                    <div className='modal-content'>
                         <div className='modal-header'>
                              <h5 className='modal-title' id='exampleModalLabel'>
                                   Picture #{props.id}
                              </h5>
                              <span className='close' aria-hidden="true" data-dismiss="modal" aria-label='Close'>&times;</span>
                         </div>
                         <ImageContent
                              imageID={props.id}
                              picture={props.image}
                              labels={labels}
                              setLabels={setLabels}
                              newLabelData={newLabelData}
                              handleLabel={(label) => handleLabelInModal(label, props.id)}
                         />
                         <div className='modal-footer'>
                              <button
                                   className='modal-btn danger'
                                   onClick={() => setShowAreYouSure(true)}
                              >
                                   Delete Image
                              </button>
                              <button 
                                   className='modal-btn primary'
                                   onClick={() => setShowNewLabel(true)}
                              >
                                   Add new label
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     )
}


{/* <WordModal show={this.state.show} handleClose={this.hideModal} changeToPrimary={this.changeToPrimary} deleteWord={this.deleteWord} > 
                     <h1>Edit Mode</h1>
                    <p className='display-word'>{this.state.queryWord}</p> 
                     <p>Set <p className='display-word'>{ this.state.queryWord }</p> as the primary word for this image?</p>
                         <p>Delete <p className='display-word'>{ this.state.queryWord }</p></p> 
                    </WordModal> 
                     <WordModal show={ this.state.showPopup } handleClose={ this.hidePopup }>
                         <h1>Edit Mode</h1>
                    </WordModal> */}


{/* 
                                   <button type='button' className='modal-btn primary' data-dismiss="modal" >Treasure Hunt</button>
                                   {this.state.editMode ? (
                                        <button className='modal-btn primary' onClick={() => this.setState({ editMode: !this.state.editMode })}>Normal Mode</button>
                                   ) : (
                                        <button className='modal-btn primary' onClick={() => this.setState({ editMode: !this.state.editMode })}>Edit Mode</button>
                                   )
                                   }
                              */}

// <WordModal show={ this.state.showPopup } handleClose={ this.hidePopup } toggler={ this.state.showPopup }>
//                          <h1 className='text-center'>View sentence or definition</h1>
//                          <form className='toggler-form' onChange={this.onChangeValue}>
//                               <input type="radio" id="definition" name="toggler" />
//                               <label for="definition"> Definition</label> { /** true */}
//                               <br />
//                               <input type="radio" id="sentence" name="toggler" defaultChecked />
//                               <label for="sentence"> Sentence</label> { /** false */}
//                               <br />
//                          </form>
//                     </WordModal>