import React from 'react';
import Loading from './Loading';
import { v4 as uuidv4 } from 'uuid';

export function Grid(props) {

     const displayGrid = () => {
          let grid = [];
          if (props.array !== null) {
               for (let row = 0; row < props.array.length; row++) {
                    let currentRow = [];
                    for (let col = 0; col < props.array[0].length; col++) {
                         if (props.array[row][col] === "-") {
                              currentRow.push(
                                   <div className="grid-blank" key={uuidv4()}></div>
                              )
                         } else {
                              currentRow.push(
                                   <div className="grid-column" onClick={() => {
                                        if (props.array[row + 1][col] === "-") {
                                             console.log(props.array[row])
                                        }
                                        if (props.array[row][col + 1] === "-") {
                                             const columns = (arr, cols) => arr.map(e => e.slice(...cols))
                                             let whichCol = columns(props.array, [2, 3])
                                             let filteredCol = []
                                             for (let i = 0; i < whichCol.length; i++) {
                                                  console.log(whichCol[i]);
                                             }
                                             console.log(filteredCol)
                                        }
                                        console.log("col + 1:", props.array[row][col + 1])
                                        console.log("row + 1:", props.array[row + 1][col])

                                   }} key={uuidv4()}>
                                        {props.array[row][col]}
                                   </div>
                              )
                         }
                    }
                    grid.push(
                         <div className="grid" key={uuidv4()} >{currentRow}</div>
                    )
               }
          } else {
               return (
                    <Loading />
               )
          }
          return grid;
     }

     return (
          <div>
               <div className="crossword-container">
                    <div className="crossword-wrapper">
                         {displayGrid()}
                    </div>
               </div>
          </div>
     )
}