import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/style.css";

// USER RELATED ROUTES (AUTH, APP ACCESS)
import { LoginContext } from './helper/LoginContext';
import PrivateRoute from './helper/PrivateRoute';
import { AccessCode } from './views/UserInfo/AccessCode';
import { ChooseColor } from './views/UserInfo/ChooseColor';
import { DeleteUser } from './views/UserInfo/DeleteUser';
import { Login } from './views/UserInfo/Login';
import { Register } from './views/UserInfo/Register';
import { Settings } from './views/UserInfo/Settings';
// MEMORIES ROUTES
import BottomBar from './components/BottomBar';
import { Dashboard } from './components/Dashboard';
import { Memories } from './views/Memory/Memories';
// EYE MODE ROUTES
import PictureMode from './views/PictureMode/PictureMode';
// import { EyeMode } from './views/PictureMode/EyeMode';
// import { ImageInfo } from './views/PictureMode/ImageInfo';
// TREASURE HUNT ROUTES
import { TreasureHunt } from './views/TreasureHunt/TreasureHunt';
import { TreasureHuntOptions } from './views/TreasureHunt/TreasureHuntOptions';
// AROUND ME ROUTES
// import { AroundMeOptions } from './views/AroundMe/AroundMeOptions';
import { Crossword } from './components/Crossword';
import Achievements from './views/Achievements/Achievements';
import About from './views/AroundMe/About';
import { Option2re } from './views/AroundMe/Option2re';
import { Option3 } from './views/AroundMe/Option3';
import { Option3One } from './views/AroundMe/Option3.1';
import { Option4 } from './views/AroundMe/Option4';
import ScanMode from './views/AroundMe/ScanMode';
import { TFCamera } from './views/AroundMe/TFCamera';
import { TensorflowCamera } from './views/AroundMe/TensorflowCamera';
import { Quiz } from './views/Quiz/Quiz';
import Feedback from "./views/UserInfo/Feedback";

// import { NavigationBar } from './components/NavigationBar';
// import { Homepage } from './components/Homepage';
// import { Gallery } from './components/Gallery'
// import { TestContinuous } from './component/ContinuousCamera/TestContinuous';
// import Test2 from './component/ContinuousCamera/Test2';
// import { Option2 } from './component/AroundMe/Option2';
// import { Option31 } from './component/AroundMe/Option31';

toast.configure();

export function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasLoginCheckRun, setHasLoginCheckRun] = useState(false);
    const [questionAmount, setQuestionAmount] = useState(3);
    const [language, setLanguage] = useState("en");
    const [selectedLanguage, setSelectedLanguage] = useState("en");

    const [infoImage, setInfoImage] = useState("");
    const [huntWord, setHuntWord] = useState("");
    const [huntImage, setHuntImage] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [whichUrl, setWhichUrl] = useState("/dash-board");
    const [galleryImages, setGalleryImages] = useState([]);
    const [isRandom, setIsRandom] = useState(false);
    const [imageID, setImageID] = useState(0);
    const [imageCount, setImageCount] = useState(0);

    const [color, setColor] = useState("");
    const [navbarColor, setNavbarColor] = useState("");
    const [fontColor, setFontColor] = useState("");

    useEffect(() => {
        loginCheck();
        // .then(data => console.log(data));
        if (localStorage.getItem("whichUrl")) setWhichUrl(localStorage.getItem("whichUrl"));
        //else console.log("Nothing to route to");
    }, []);

   

    async function loginCheck() {
        try {
            const response = await fetch(`auth/is-verify`, {
                method: "GET",
                headers: { token: localStorage.token }
            });
            const data = await response.json();
            if (data.isAuthenticated === true) {
                setIsAuthenticated(data.isAuthenticated);
                setQuestionAmount(data.questionAmount);
                setLanguage(data.target_language);
                setSelectedLanguage(data.selected_language);
            }
            setHasLoginCheckRun(true);
            return data;
        } catch (error) {
            console.error(error.message);
        }
    }


    const logout = (e) => {
        e.preventDefault();
        localStorage.removeItem("token");
        localStorage.removeItem("galleryImages");
        localStorage.removeItem("whichUrl");
        setIsAuthenticated(false);
        toast.success("Logged Out Successfully");
    }

    return (
        <LoginContext.Provider
            className="App"
            value={{
                isAuthenticated, infoImage, huntWord, huntImage, color, navbarColor, fontColor, expanded, whichUrl, galleryImages, isRandom, imageID, questionAmount, language, selectedLanguage, imageCount,
                logout, setIsAuthenticated, setInfoImage, setHuntWord, setHuntImage, setColor, setNavbarColor, setFontColor, setExpanded, setWhichUrl, setGalleryImages, setIsRandom, setImageID, setQuestionAmount, setLanguage, setSelectedLanguage, setImageCount
            }}
        >
            {hasLoginCheckRun &&
                <BrowserRouter>
                    <Route exact path="/">
                        <Redirect to="/dash-board" />
                    </Route>
                    <Switch>
                        <Route exact path="/login" render={props => <Login {...props} />} />
                        <Route exact path="/register" component={Register} />
                        <Route path="/access-code" component={AccessCode} />
                        <PrivateRoute path="/dash-board" component={Dashboard} />
                        <PrivateRoute path="/eye-mode" component={PictureMode} />
                        <PrivateRoute path="/my-memories" component={Memories} />
                        <PrivateRoute path="/quiz" component={Quiz} />
                        <PrivateRoute path="/achievements" component={Achievements} />

                        <PrivateRoute path="/treasure-hunt" component={TreasureHunt} />
                        <PrivateRoute path="/hunt-options" component={TreasureHuntOptions} />
                        <PrivateRoute path="/tensorflow-camera" component={TensorflowCamera} />
                        <PrivateRoute path="/color-picker" component={ChooseColor} />
                        <PrivateRoute path="/tensor" component={TFCamera} />
                        <PrivateRoute path="/crossword-game" component={Crossword} />

                        {/* <PrivateRoute path="/around-me" component={AroundMeOptions} /> */}
                        <PrivateRoute path="/around-me-instructions" component={About} /> 

                        <PrivateRoute path="/around-me" component={ScanMode} />

                        <PrivateRoute path="/fake-item" component={Option2re} />
                        <PrivateRoute path="/option-3" component={Option3} />
                        <PrivateRoute path="/option-31" component={Option3One} />
                        <PrivateRoute path="/option-4" component={Option4} />

                        <PrivateRoute path="/settings" component={Settings} />
                        <PrivateRoute path="/delete-user" component={DeleteUser} />

                        <PrivateRoute path='/feedback' component={Feedback} />

                        {/* <PrivateRoute path="/around-me-mode" component={TestContinuous}/> */}
                        {/* <PrivateRoute path="/image-info" component={ImageInfo} /> */}
                    </Switch>
                    {isAuthenticated && <BottomBar previousPage="" />}
                </BrowserRouter>
            }
        </LoginContext.Provider>
    )
}