import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as workerTimers from 'worker-timers';
import { getRandomIndex } from '../../helper/randomizer';
import { Modal } from './Modal';
import { WebCam } from './WebCam';

// step 1: start with continuous camera
// step 2: run cocossd
// step 3: save cocossd results
// step 4: filter results
// step 5: inject a fake item in the filtered results
// step 6: ask user which item is fake
export class Option2re extends Component {
     constructor() {
          super();

          this.state = {
               initialTime: 15,
               objectDetections: [],
               filterDone: false,
               progress: 100,
               relatedItem: ""
          }
     }

     async getRelatedObject(item) {
          try {
               const abortController = new AbortController();
               // let randomItem = this.state.objectDetections[getRandomIndex(this.state.objectDetections)];
               // console.log(randomItem);
               const response = await fetch(`word/datamuse?word=${item}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json", token: localStorage.token },
                    signal: abortController.signal
               });
               const parseResponse = await response.json();
               return parseResponse;
          } catch (error) {
               console.error(error.message);
               toast.error(error);
          }
     }


     filterSetDetectionArray(detections) {
          let filteredArray = [...new Set(detections)];

          this.setState({ objectDetections: filteredArray }, () => {
               const { objectDetections } = this.state;
               let randomItem = objectDetections[getRandomIndex(objectDetections.length)];
               this.getRelatedObject(randomItem).then((data) => {
                    let randomWordIndex = getRandomIndex(data.length);
                    let randomWord = data[randomWordIndex].word;
                    this.setState({ relatedItem: randomWord}, () => {
                         const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
                         let res = insert(this.state.objectDetections, randomWordIndex, randomWord);
                         this.setState({ objectDetections: res }, () => {
                              this.setState({ filterDone: true });
                         });
                    });
               });
          });

     }

     componentDidMount() {
          this.interval = workerTimers.setInterval(() => {
               this.setState({ initialTime: this.state.initialTime - 1});
               if (this.state.initialTime <= 0) {
                    workerTimers.clearInterval(this.interval);
               }
          }, 1000);
     }

     render() {
          return (
               <div className='App'>
                    <div className='App-header'>
                         <div className='timer'>
                              <p>Time Remaining: { this.state.initialTime }s</p>
                              <div className='progress' style={{ width: `${(this.state.progress/15) * this.state.initialTime}%`, transition: '0.2s' }}></div>
                         </div>
                         {
                              !this.state.filterDone && <WebCam toFind={""} finished={ this.state.initialTime <= 0 ? true : false }  allDetections={(detections) => this.filterSetDetectionArray(detections)} />
                         }
                         {
                              this.state.filterDone && <Modal option={ 2 } detections={ this.state.objectDetections } relatedItem={ this.state.relatedItem } />
                         }
                    </div>
               </div>
          )
     }

     componentWillUnmount() {
          workerTimers.clearInterval(this.interval);
     }
}
