/**
 * @param {String}} s 
 * @param {String} t 
 * @returns {Number}
 */
export const levenshteinDistance = (s, t) => {
    let n = s.length;
    let m = t.length;
    let d = [];

    for (let i = 0; i <= m; i++) {
         d[i] = [i];
    }

    for (let j = 0; j <= n; j++) {
         d[0][j] = j;
    }

    for (let j = 1; j <= n; j++) {
         for (let i = 1; i <= m; i++) {
              // replace cost = 0 where match string
              if (s[i - 1] === t[j - 1]) {
                   d[i][j]= d[i - 1][j - 1];
              } else {
                   d[i][j] = Math.min(
                        d[i - 1][j] + 1,    // deletion
                        d[i][j - 1]  + 1,   // insertion
                        d[i - 1][j - 1] + 1 // substitution
                   );
              }
         }
    }

    return d[m][n];
}

/**
 * 
 * @param {String} str1 
 * @param {String} str2 
 * @returns positions array of mispelled letters
 */
export function findSpellingMistakePositions(str1, str2) {
     const isCorrectlySpelled = [];
   
     const len = Math.min(str1.length, str2.length);
   
     for (let i = 0; i < len; i++) {
       isCorrectlySpelled.push(str1[i] === str2[i]);
     }
   
     if (str1.length > str2.length) {
       for (let i = len; i < str1.length; i++) {
         isCorrectlySpelled.push(false);
       }
     } else if (str2.length > str1.length) {
       for (let i = len; i < str2.length; i++) {
         isCorrectlySpelled.push(false);
       }
     }
   
     return isCorrectlySpelled;
}