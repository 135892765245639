import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AboutCard from '../../components/AboutCard';

class About extends Component {



    constructor() {
        super();

        

        this.state = {
            page: 1
        }
    }

    renderInstructions(pageNum) {
        switch (pageNum) {
            case 1:
                return (
                    <AboutCard 
                        iconImage={ 'fas fa-solid fa-video' }
                        cardTitle={ <Translation>{(t) => t('eyeModeInstructions')}</Translation> }
                        cardInstructions={ <Translation>{(t) => t('eyeModeInstructionsInfo')}</Translation> }
                        StateFunction={() => this.setState({ page: this.state.page + 1 }) }
                        btnText={ <Translation>{(t) => t('next')}</Translation> }
                    />
                )  
            case 2:
                return (
                    <AboutCard
                        iconImage={ 'fas fa-regular fa-clock' }
                        cardTitle={ <Translation>{(t) => t('eyeModeInstructions')}</Translation> }
                        cardInstructions={ <Translation>{(t) => t('eyeModeInstructionsInfo2')}</Translation> }
                        StateFunction={() => this.setState({ page: this.state.page + 1 })}
                        btnText={ <Translation>{(t) => t('next')}</Translation> }
                    />
                )   
            case 3:
                return (
                    <AboutCard 
                        iconImage={ 'fas fa-regular fa-clock' }
                        cardTitle={ <Translation>{(t) => t('eyeModeInstructions')}</Translation> }
                        cardInstructions={ <Translation>{(t) => t('eyeModeInstructionsInfo3')}</Translation> }
                        StateFunction={() => {
                            this.props.history.push("/around-me")
                        }}
                        btnText={ <Translation>{(t) => t('showList')}</Translation> }
                    />
                )  
            default:
                break;
        }
    }

    render() {
        const { page } = this.state;

        return (
            <>
                <button className='skip-btn' onClick={() => {
                    this.props.history.push("/around-me");
                }} ><Translation>{(t) => t('skip')}</Translation></button>
                { this.renderInstructions(page) }
            </>
        )
    }
}

export default withRouter(About);