import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoginContext } from '../../helper/LoginContext';

export function Login() {
    const { isAuthenticated, setIsAuthenticated } = useContext(LoginContext);
    const history = useHistory();

    const [inputs, setInputs] = useState({ email: "", access_code: "" });

    const { email, access_code } = inputs;

    useEffect(() => {
        // console.log(isAuthenticated)
        if (isAuthenticated) window.location.replace("/dash-board");
    }, []);

    const onChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    }

    const clearOnSubmit = () => {
        setTimeout(() => {
            setInputs({ email: "", access_code: "" });
            localStorage.setItem("whichUrl", "/dash-board");
        }, 100);
    }

    const onSubmitForm = async (e) => { // login
        e.preventDefault();
        try {
            const body = { email, access_code };
            const response = await fetch(`auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });
            const parseResponse = await response.json();

            if (parseResponse.token) {
                localStorage.setItem("token", parseResponse.token);
                setIsAuthenticated(true);
                history.push("/dash-board");
                // toast.success("Logged In Successfully");
            } else {
                toast.error(parseResponse);
            }

        } catch (error) {
            console.error(error.message);
            toast.error("Invalid Credentials");
        }
    }

    return (
        <div className="page-wrapper">
            {/* <img className="logo" src={ logo } alt="logo"  /> */}
            <div className="login-container">
                <h1 className="login-title">Login</h1>

                <form onSubmit={e => onSubmitForm(e)} className="form-container" >
                    <input type="email" name="email" placeholder="Email..." className="form-input" value={email} onChange={e => onChange(e)} required />
                    <input type="text" name="access_code" placeholder="Access Code..." className="form-input" value={access_code} onChange={e => onChange(e)} autoComplete="off" required />
                    <button className="sign-in-btn" type="submit" onClick={clearOnSubmit}>Login</button>
                </form>
                <div>Not Registered? <Link to="/register">Register</Link></div>
                <div>Lost Access Code? <Link to="/access-code">Get Access Code</Link></div>
            </div>
        </div>
    )
}