import React, { useContext, useEffect, useState } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';
import { toast } from 'react-toastify';
import TranslationNew from '../../components/TranslationNew';
import { LoginContext } from '../../helper/LoginContext';
import { findSpellingMistakePositions, levenshteinDistance } from '../../helper/levenstheinDistance';
import Results from './Results';

import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';

export function Quiz() {
     const { questionAmount } = useContext(LoginContext);

     const [isLoading, setIsLoading] = useState(false);

     const [quizContent, setQuizContent] = useState([]); // question content and results
     const [results, setResults] = useState(Array(questionAmount).fill({ word: "", attempts: 0, chosenCorrectPicture: null, spelledCorrect: null }));

     const [itemFound, setItemFound] = useState(false); // manages current state of quiz
     const [attempts, setAttempts] = useState(1);
     const [questionNumber, setQuestionNumber] = useState(0);
     const [phase, setPhase] = useState(1);

     const [checked, setChecked] = useState(true); // phase 2 states
     const [chosenImage, setChosenImage] = useState({ e: "", item: "" });
     const [textInBlank, setTextInBlank] = useState("");

     const [inputText, setInputText] = useState(""); // phase 3 states
     const [showClue, setShowClue] = useState(false);
     const [showHint, setShowHint] = useState(false);

     const { language } = useContext(LoginContext);
     const [voice, setVoice] = useState(1);
     const { speak, voices } = useSpeechSynthesis();

     const [audioSrc, setAudioSrc] = useState('');

     // array of spelling mistakes
     const [spellingMistake, setSpellingMistake] = useState(null);
     // translated word 
     const [translatedWordToFind, setTranslatedWordToFind] = useState(null);

     const [translation, setTranslation] = useState('');

     const { imageSet, sentence, wordToFind, definition, fullSentence, exampleSentence } = quizContent[questionNumber] ?? {}; // Destructure quiz content to place in HTML nicely
     const isFillInBlankQ = phase > 1; // If the current question is the type where you'd fill in the blanks (Phase 2 and 3)
     const correctType = phase === 2 ? 'chosenCorrectPicture' : 'spelledCorrect'; // Correct type of result to alter (Phase 2 and 3)

     const { t } = useTranslation();

     useEffect(() => { fetchQuizContent() }, []); // on component mount

     useEffect(() => { isFillInBlankQ && fetchSentences() }, [phase]); // Generate new sentences for Phase 2 and 3

     useEffect(() => { // Skips question with empty sentence 
          if (isFillInBlankQ && !isLoading && sentence?.length === 0) nextQuestion();
     }, [isLoading, questionNumber]);

     useEffect(() => {
          if (audioSrc) {
               const audioElement = document.getElementById("audioElement");
               audioElement.play();
          }
     }, [audioSrc]);

     useEffect(() => {
          // added january 17, 2023 
          // if (wordToFind !== undefined) translate(wordToFind).then(data => setTranslatedWord(data.translations[0]));
          // else console.log("already loaded")
          // translate(wordToFind);
          async function fetchTranslationBatch() {
               try {
                    const response = await fetch(`translation/translate-batch`, {
                         method: "POST",
                         headers: {
                              "Content-Type": "application/json"
                         },
                         body: JSON.stringify({ texts: [wordToFind] })
                    });
                    const data = await response.json();

                    setTranslation(data.translation);
               } catch (error) {
                    console.error(error);
               }
          }
          fetchTranslationBatch();
     }, [questionNumber, wordToFind]);

     useEffect(() => {
          if (phase === 3) {
               console.log()
          }
     }, [phase]);


     useEffect(() => {
          if (language === 'fr') setVoice(11)
          else if (language === 'es') setVoice(9)
          else setVoice(0);
     }, [language]);

     function nextPhase() {
          setIsLoading(true); // Load screen for new sentences - needs to run early so question skip above works properly
          setQuestionNumber(0);
          setPhase(phase + 1);
     }

     // mix up images too?

     // Prepare quiz 
     async function fetchQuizContent() {
          try {// handle fetch abort midway of this transaction
               setIsLoading(true);
               const abortController = new AbortController();
               const response = await fetch(`api/quiz-mode`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json", token: localStorage.token },
                    signal: abortController.signal,
                    body: JSON.stringify({ questionAmount: questionAmount })
               });
               const data = await response.json();
               // console.log(data)
               setQuizContent(data.quizContent);
               setIsLoading(false);
               return data;
               // console.log(data);
          } catch (error) {
               if (error.name === "AbortError") console.error("Fetch aborted");
               console.error(error);
               toast.error("An error occured when constructing the quiz mode.");
          }
     }


     async function fetchSentences() { // For Phase 2 and 3
          let queryString = "wordArray=";
          let imageString = "imageId=";
          const wordsToFind = [...quizContent.map(q => q.wordToFind)];

          console.log("called fetch sentences");

          // go through each wordToFind for the question amounts
          for (let i = 0; i < wordsToFind.length; i++) {
               let matchingImage = await imageSet.find(image => 
                    image.labels.some(label => 
                         label.toLowerCase() === wordsToFind[i].toLowerCase()
                    )
               );
               
               // console.log('wordToFind:', wordsToFind[i], 'matching image:', (matchingImage && matchingImage.imageId));
               queryString += wordsToFind[i] + (i + 1 < wordsToFind.length ? "&wordArray=" : "");
               imageString += (matchingImage ? matchingImage.imageId : -1) + (i + 1 < wordsToFind.length ? "&imageId=" : "");
               
               // if (wordsToFind[i].indexOf(" ") >= 0) {
               //      wordsToFind[i] = wordsToFind[i].replace(/\s/g, '');
               // }
          }

          // console.log("img set", imageSet, 'image string:', imageString);

          try {
               if (queryString) {
                    const response = await fetch(`word/get-sentences?${queryString}&${imageString}`, {
                         method: "GET",
                         headers: { "Content-Type": "application/json ", token: localStorage.token }
                    });
                    const data = await response.json();

                    const quizContentClone = [...quizContent];
                    for (let i = 0; i < questionAmount; i++) { // Insert sentences and definitions
                         quizContentClone[i].sentence = data[i]?.sentence;
                         quizContentClone[i].definition = data[i]?.definition;
                         quizContentClone[i].fullSentence = data[i]?.fullSentence;
                         quizContentClone[i].exampleSentence = data[i]?.example;
                    }

                    const resultsClone = [...results];
                    for (let j = questionAmount - 1; j > 0; j--) { // Mix question order along with results // commenting this out helps troubleshoot issues with skipping questions
                         const k = Math.floor(Math.random() * (j + 1));
                         [quizContentClone[j], quizContentClone[k]] = [quizContentClone[k], quizContentClone[j]];
                         [resultsClone[j], resultsClone[k]] = [resultsClone[k], resultsClone[j]];
                    }

                    // console.log(data)

                    setQuizContent([...quizContentClone]);
                    setResults([...resultsClone]);
                    setIsLoading(false); // set useeffect if you wanna console.log

                    setItemFound(false); // mimick nextQuestion?
                    setChecked(false);
               }
          } catch (error) {
               console.error(error.message);
               toast.error(error);
          }
     }

     async function translate(text) {
        try {
            let body = { text };
            const response = await fetch(`translation/translate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                },
                body: JSON.stringify(body)
            });
            const data = await response.json();
          //   console.log("api called");
            return data;
        } catch (error) {
            console.error(error);
        }
    }

     function modifyResults(isCorrect) {
          let resultsClone = [...results];          
          if (isFillInBlankQ) resultsClone[questionNumber][correctType] = isCorrect;
          else resultsClone[questionNumber] = { ...resultsClone[questionNumber], word: wordToFind, attempts: attempts }; // Just phase 1
          setResults([...resultsClone]);
     }


     function checkIfCorrect(e = chosenImage.e, item = chosenImage.item) {
          if (itemFound) return;

          if (phase === 3) { // Check input text - convert input text to lowercase and trim spaces around it4
               // added in translation on April 4, 2023
               translate(wordToFind).then((translatedWord) => {
                    // originalText
                    setTranslatedWordToFind(translatedWord.translations[0].toLowerCase());
                    
                    let nStringDiff = levenshteinDistance(inputText.toLowerCase(), translatedWord.translations[0].toLowerCase());
                    let spellingMistake = findSpellingMistakePositions(inputText.toLowerCase(), translatedWord.translations[0].toLowerCase());
                    setSpellingMistake(spellingMistake);
                    // console.log("spelling mistake:", spellingMistake);
                    // if (inputText.toLowerCase().trim() === translatedWord.translations[0].toLowerCase()) modifyResults(true);
                    if (nStringDiff <= 3) modifyResults(true);
                    else modifyResults(false);
                    setTextInBlank(translatedWord.translations[0]?.toLowerCase());

                    // console.log('Comparison:', translatedWord.translations[0].toLowerCase(), inputText.toLowerCase());
               });

               // if (inputText.toLowerCase().trim() === wordToFind.toLowerCase()) modifyResults(true);
               // else modifyResults(false);
               // setTextInBlank(wordToFind?.toLowerCase()); // Show correct answer in blank regardless
          } else {
               // console.log("Checking if item selected is correct one:", item, item.label, wordToFind);
               // if (item.label === wordToFind) { // Checks if picture selection is correct | DEPRECATED (2023-10-30): now using includes
               // if (item.labels.some(label => label.toLowerCase().includes(wordToFind.toLowerCase()))) {
               // console.log(`Quiz (255): Checking label comparison: ${item.label} and ${wordToFind}`);
               if (item.label === wordToFind) {
                    e.target.parentElement.classList.add("selected-right");
                    modifyResults(true);
                    setItemFound(true);
               } else {
                    if (e.target.parentElement.classList.contains("selected-wrong")) e.target.parentElement.classList.add("shake"); // Shake if incorrect is chosen again
                    else {
                         modifyResults(false);
                         e.target.parentElement.classList.add("selected-wrong"); // Add red incorrect background
                    }
                    setAttempts(attempts + 1); // Increase number of attemtps
               }
          }

          if (isFillInBlankQ) setChecked(true);
     }

     function fillInBlank(e = chosenImage.e, item = chosenImage.item) {
          if (checked) return; // Can't choose again after one is checked for phase 2

          resetImageSelectionColours();
          e.target.parentElement.classList.add('chosen');
          setChosenImage({ e: e, item: item });

          // Input will always be lowercase
          const wordToFill = item.label === wordToFind ? wordToFind.toLowerCase() : item.label.toLowerCase();
          setTextInBlank(wordToFill);
     }

     function resetImageSelectionColours() { // Removes all classNames that signified correct or incorrect on the chosen images 
          const classNames = ["selected-right", "selected-wrong", "chosen"];
          document.querySelectorAll(".grid-gallery-wrapper").forEach((item) => {
               if (classNames.some(className => item.classList.contains(className))) {
                    item.classList.remove(...classNames);
               }
          });
     }

     function nextQuestion() {
          if (isFillInBlankQ) {
               setChecked(false);
               setTextInBlank("");
               setInputText("");
               setShowClue(false); // only 3 needs this
               setSpellingMistake(null);
               setTranslatedWordToFind(null);
          }

          // added january 17, 2023 
          translate(wordToFind);
          
          setQuestionNumber(questionNumber + 1);
          setItemFound(false);
          setAttempts(1); // only phase 1 needs this
          resetImageSelectionColours();
     }


     async function handleTextToSpeech(text) {
          try {
               const response = await fetch(`translation/tts?text=${encodeURIComponent(text)}`, {
                    method: "GET",
                    headers: {
                         "Content-Type": "application/json",
                         token: localStorage.token
                    }
               });
               const data = await response.json();

               setAudioSrc(data.audio);
          } catch (error) {
               console.error("Error fetching audio:", error);
               toast.error("Audio generation temporarily unavailable");
          }
     }

     if (questionNumber >= questionAmount) return <Results nextPhase={nextPhase} phase={phase} results={results} />

     if (isLoading) {
          return (
               <div>
                    <Loading />
                    <h2 className='loading-text-2'>
                        { phase === 1 ? "Please wait while we load the first phase" : "Please wait while we load the next phase..." }
                    </h2>
               </div>
          )
     }

     let blankColor = '';
     if (checked) { 
          // console.log(`CHECKING QUIZ (353): checked ${checked}, results ${results[questionNumber]?.[correctType]}, question# ${questionNumber}, correctType ${correctType}`);
          if (results[questionNumber]?.[correctType] !== null) {
               blankColor = results[questionNumber]?.[correctType] ? 'selected-right' : 'selected-wrong';
          }
     }

     // Correct picture is found - // Image is chosen - // Text has been inputted
     const disableNextButton = (phase === 1 && !itemFound) || (phase === 2 && textInBlank.length === 0) || (phase === 3 && inputText.length === 0);

     return (
          <div className='Quiz'>
               {phase === 1 &&
                    <div className='question-container'>
                         {t('quiz1')} 
                         <b>
                         <TranslationNew text={ wordToFind } />
                         </b>?
                    </div>
               }
               {isFillInBlankQ && // Fill in blank
                    <>
                         <div className='question-container'>
                              {phase === 2 ?
                                   <>{t('quiz3')}</> :
                                   <>{t('quiz4')}</>
                              }
                              <div className='sentence-and-input'>
                                   <p id="speech-id">{sentence?.length > 0 &&
                                        sentence?.map(
                                             (piece, p) => <span key={p}>
                                                       {phase === 3 ? 
                                                            <TranslationNew text={piece}/>
                                                             : piece}
                                                       { p === 0 && 
                                                            <span className={`blank ${blankColor} ${correctType}`}>
                                                                 {
                                                                      textInBlank.length > 0 ? 
                                                                      <TranslationNew text={textInBlank} />
                                                                      : "_".repeat(wordToFind.length)
                                                                 }
                                                            </span>
                                                       }
                                                  </span>
                                        )}
                                        .
                                   </p>
                                   {
                                        phase === 3 && textInBlank.length > 0 &&
                                        <>
                                             <i className="fas fa-volume-up" onClick={(e) => {
                                                  handleTextToSpeech(document.getElementById("speech-id").innerText || "");
                                             // speak({ 
                                             //      text: document.getElementById(`speech-id`).innerText, // should probably not use this way of get text but using now because translation throwing mad errors
                                             //      voice: voices[voice] 
                                             // });
                                             }} ></i>
                                             <audio id="audioElement" src={audioSrc}></audio>
                                        </>
                                   }
                                   {/* {
                                   <p>The correct picture is: {}</p>
                                   } */}
                                   {phase === 3 && // Input field
                                        <div className='blank-wrapper'>
                                             <input
                                                  className={`blank ${blankColor}`}
                                                  type="text"
                                                  value={inputText}
                                                  onChange={(e) => setInputText(e.target.value)}
                                                  onKeyDown={e => e.key === 'Enter' &&
                                                       ((checked && !disableNextButton) ? nextQuestion() : checkIfCorrect())
                                                  }
                                             />
                                        </div>
                                   }
                                   {phase===3 && <div>
                                        {translatedWordToFind && translatedWordToFind.split('').map((char, index) => {
                                             let colorArray = null;
                                             if (spellingMistake) {
                                                  colorArray = spellingMistake[index] ?  '' : 'red';

                                             }
                                             return <span key={index} style={{ color: colorArray }}>{char}</span>
                                        })}
                                   </div>}
                              </div>
                         </div>
                         {phase === 3 && // Definition clue is shown in phase 3
                              <div className="expandable-clues">
                                   <div className="clue-toggle" onClick={() => setShowClue(!showClue)}>{t('clue')}</div>
                                   <div className={showClue ? 'clues-collapsible' : 'clues'} >
                                        <div className='clue'>
                                             { showHint ? <h4>{t('englishExample')}</h4> : <h4>{t('definition')}</h4> }
                                             <button onClick={() => setShowHint(!showHint)}>{ showHint ? <>{t('showDefinition')}</> : <>{t('showHint')}</>}</button>
                                             {/* { showHint && <p className='definition'>{fullSentence}</p>} */}
                                             { showHint && <p className='definition'>{exampleSentence}</p> }
                                             { !showHint && <p className='definition'>{definition}</p> }
                                        </div>
                                   </div>
                              </div>
                         }
                    </>
               }
               {(phase < 3) && // Picture selection
                    <div className='gallery-wrap'>
                         <div className='gallery-container'>
                              {typeof imageSet === 'string' || imageSet instanceof String ? // temp
                                   <div>{imageSet}</div>
                                   :
                                   imageSet?.length > 0 &&
                                   imageSet.map((item, index) => (
                                        <div key={index} className="gallery-wrapper">
                                             <div className='grid-gallery-wrapper'>
                                                  <img
                                                       src={item.image}
                                                       alt={`quiz-pic-${index}`}
                                                       className="gallery-img"
                                                       onClick={(e) => phase === 1 ? checkIfCorrect(e, item) : fillInBlank(e, item)}
                                                  />
                                             </div>
                                        </div>
                                   ))
                              }
                         </div>
                    </div>
               }
               <button
                    className="continue-btn"
                    disabled={disableNextButton}
                    style={{ opacity: disableNextButton ? 0.5 : 1 }}
                    onClick={() => checked ? nextQuestion() : checkIfCorrect()}
               >
                    {checked ? t('next') : t('check')}
               </button>
          </div>
     )
}