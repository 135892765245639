import React, { Component } from 'react'

export class ModalDialog extends Component {
     constructor(props) {
          super(props);

          this.state = {
               counter: 5
          }
     }

     render() {
          return (
               <div className='p-modal-container'>
                    <div className='p-modal'>
                         <div className='p-modal-header'>
                              <h2>{this.props.title}</h2>
                              {this.props.option === 2 && <p><strong>Guesses: </strong>{this.state.counter}</p>}
                         </div>
                         <div className={Array.isArray(this.props.body) ? 'word-list' : 'p-modal'}>
                              {
                                   Array.isArray(this.props.body) ? (
                                        this.props.body.map((item, index) => (
                                             <div className='unselected'
                                                  key={index}
                                                  onClick={() => {
                                                       this.props.sendItem(item);
                                                       this.setState({ counter: this.state.counter - 1 });
                                                       if (this.state.counter <= 1) {
                                                            this.props.sendItem(true);
                                                       }
                                                  }}
                                             >
                                                  {item}
                                             </div>
                                        ))
                                   ) : (this.props.body)
                              }

                         </div>
                         <div className='p-modal-footer'>
                              {this.props.children}
                         </div>
                    </div>
               </div>
          )
     }
}
