import React, { Component } from 'react';

export default class AboutCard extends Component {
    render() {
        return (
            <div className='Home' style={{ height: '90%' }}>
                <h1 className='section-header' style={{ marginTop: 0 }}>LangEye</h1>
                <div className='menu-wrapper'>
                    <div className={ this.props.cssClass ? this.props.cssClass :  'about-instructions'}>
                        { this.props.iconImage ? <i className={ this.props.iconImage }></i> : <></>}
                        <div className='img-container'>
                            { this.props.image ? <img alt="findObject" src={this.props.image} /> : <></> }
                            { this.props.titleWord ? <h1 className='img-title'>{ this.props.titleWord }</h1> : <></>}
                        </div>
                        <h1>{ this.props.cardTitle }</h1>
                        <p className='card-info'>{ this.props.cardInstructions }</p>
                        <button className='menu-card' onClick={() => this.props.StateFunction()}>{ this.props.btnText }</button>
                    </div>
                    <div className='activities'>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}