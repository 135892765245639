import { toast } from "react-toastify";


async function getPressmonSentence(word) {
    try {
        const response = await fetch(`word/pressmon?word=${word}`, {
            method: "GET",
            headers: { 
                "Content-Type": "application/json",
                token: localStorage.token
            }
        });
        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
        toast.error(error);
    }
}

async function translation(word) {
    try {
        const body = { text: word };
        const abortController = new AbortController();

        const response = await fetch(`translation/translate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token,
                signal: abortController.signal
            },
            body: JSON.stringify(body)
        });
        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
    }
}

export { getPressmonSentence, translation };