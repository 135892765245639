export const getRandomNumber = () => {
     // n - 1 options -> 5 => 4 options
     return Math.floor(Math.random() * 5)
}

export const getRandomIndex = (n) => {
     return Math.floor(Math.random() * n);
}

export const generateRandomNumber = (n) => {
     return Math.floor(Math.random() * n) + 1
}