import React, { Component } from 'react';
import { Translation as I18Translation } from 'react-i18next';
import { toast } from 'react-toastify';
import AboutCard from '../../components/AboutCard';
import ImageContent from '../../components/ImageContent';
import Loading from '../../components/Loading';
import TranslationNew from '../../components/TranslationNew';
import { Utility_DrawRect } from '../../helper/utilities';
import image4 from '../../images/img4.png';


// activity 4; select any word found in the scan, display image, and localize selected word
export class Activity4 extends Component {
    constructor() {
        super();

        this.canvasRef = React.createRef();
        this.imageRef = React.createRef();

        this.state = {
            mode: 0,
            pictureArray: [],
            detectionArray: [],
            pictures: [],
            objectToLearn: '',
            currentPictureIndex: 0,
            currentIndex: 0,
            toShow: [],
            key: 0,
            labels: [],
            imageID: 0,
            pictureTaken: false,
            showInfo: false,
            imageData: [],
            touchStart: 0,
            touchEnd: 0
        }
    }

    componentDidMount() {
        console.log("Activity 4 mounted");
        console.log(this.props.pictureArray, this.props.detectionArray);
        this.setState({ pictureArray: this.props.pictureArray, detectionArray: this.props.detectionArray })
    }

    componentWillUnmount() {
        console.log("Activity 4 unmounted");
    }

    // subtract current picture index
    handlePreviousImage = () => {
        // if the current image index is 0 then set to the last image index to ensure an image is always shown, then run the localization api on the image and draw the bounding boxes
        if (this.state.currentPictureIndex === 0) this.setState({ currentPictureIndex: this.state.toShow.length - 1}, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
        else this.setState({ currentPictureIndex: this.state.currentPictureIndex - 1}, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
    }

    // add current picture index
    handleNextImage = () => {
        // if the current image index is at the max length then set to the first index to ensure an image is always shown, then run the localization api on the image and draw the bounding boxes
        if (this.state.currentPictureIndex >= this.state.toShow.length - 1) this.setState({ currentPictureIndex: 0 }, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
        else this.setState({ currentPictureIndex: this.state.currentPictureIndex + 1 }, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
    }

    // function to send image from the current posted image and get all image localizations
    handleGetImageLocalization = async () => {
        try {
            let dataUri = this.state.toShow[this.state.currentPictureIndex];
            let body = { dataUri };
            const response = await fetch(`api/bound-image`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.token
                },
                body: JSON.stringify(body)
            });
            const data = await response.json();

            // remove duplicates from list
            let filteredData = data.filter((item, pos) => data.findIndex(v2 => (item.name === v2.name)) === pos)

            this.setState({ imageData: [...filteredData] });

            return data;
        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    }

    // when the mode is 2 (initial displaying of images) then run the image localizations
    componentDidUpdate(prevProps, prevState) {
        if (this.state.mode !== prevState.mode) {
            if (this.state.mode === 2) {
                this.handlePhoto();
                // this.handleGetImageLocalization().then(data => {
                //     const ctx = this.canvasRef.current.getContext("2d");
                //     Utility_DrawRect(data, ctx);
                //     console.log(data)
                // });
            }
        }
    }

    // handle saving the photo to get more information on it
    handlePhoto() {
        if (this.state.toShow[this.state.currentPictureIndex] === null) return;

        this.setState({ pictureTaken: true }, async () => {
            try {
                const response = await fetch(`api/image`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        token: localStorage.token
                    },
                    body: JSON.stringify({ dataUri: this.state.toShow[this.state.currentPictureIndex] })
                });
                const data = await response.json();

                console.log('data', data);

                this.setState({ 
                    labels: data[0].labels,
                    imageID: data[0].data_id
                });
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        })
    }

    // handle right swipe
    handleSwipeStart = (e) => {
        this.setState({ touchStart: e.targetTouches[0].clientX });
    }

    // handle swipe end
    handleSwipeEnd = (e) => {
        this.setState({ touchEnd: e.targetTouches[0].clientX })
    }

    // handle swipe
    handleSwipe = () => {
        const { touchStart, touchEnd } = this.state;

        // left swipe
        if (touchStart - touchEnd > 75) {
            // go to next picture
            this.handleNextImage();
        }

        // right swipe
        if (touchStart - touchEnd < -75) {
            // go to last picture
            this.handlePreviousImage();
        }
    }


    setLabels = (newLabels) => this.setState({ labels: newLabels });

    render() {
        const { objectToLearn } = this.state;

        return(
            <div>
                {this.state.mode === 0 ? 
                    <AboutCard
                        // cardTitle={ 'Where was this?' }
                        // cardInstructions={ 'Select any object from the list retrieved from your scan and view the object information in depth and find where it was?' }
                        image={ image4 }
                        cardTitle={<I18Translation>{(t) => t('activity4Title')}</I18Translation>}
                        cardInstructions={<I18Translation>{(t) => t('activity4Info3')}</I18Translation>}
                        btnText={ <I18Translation>{(t) => t('startActivity')}</I18Translation> }
                        StateFunction={() => this.setState({ mode: 1 }) }    
                        cssClass='about-popup'                   
                    />
                : null }
                
                { this.state.mode === 1 ?
                    <div className='p-modal-container'>
                        <div className='p-modal'>
                            <div className='p-modal-header'>
                                <h1><I18Translation>{(t) => t('activity4Info')}</I18Translation></h1>
                                <h3 style={{ fontWeight: 'bold', color: '#0275d8' }}><TranslationNew text={ this.state.objectToLearn } /></h3>
                            </div>

                            <ul className='p-modal-body'>
                                {  
                                    // flatten array, filter remove duplicates and map through the distinct items
                                    this.state.detectionArray.flat().filter((i, pos) => this.state.detectionArray.flat().indexOf(i) === pos).map((item, index) => (
                                        <li key={ index } onClick={(e) => {
                                            this.setState({ objectToLearn: item }, () => {                                              
                                                let occurences = new Map();
                                                let pictures = [];
  
                                                // loop through array of detections
                                                for (let pictureIndex = 0; pictureIndex < this.state.detectionArray.length; pictureIndex++) {
                                                    // if the detection array at index includes the selected word then push to the MAP the picture index with value [objectSelected, and object to learn]
                                                    if (this.state.detectionArray[pictureIndex].includes(this.state.objectToLearn)) {
                                                        occurences.set(pictureIndex, [this.state.objectToLearn, pictureIndex]);
                                                    }
                                                }

                                                occurences.forEach((item) => {
                                                    // push picture index
                                                    pictures.push(item[1]);
                                                })

                                                // set all indexes of the pictures with the word
                                                this.setState({ pictures: [...pictures] }, () => { 
                                                    this.setState({ currentPictureIndex: this.state.pictures[0] }, () => {
                                                        // push all pictures in toShow state
                                                        for (let i = 0; i < this.state.pictures.length; i++) {
                                                            this.setState({ toShow: [...this.state.pictureArray, this.state.pictureArray[i]] })
                                                        }
                                                    });
                                                })
                                            });
                                        }}><TranslationNew text={ item } /></li>
                                    ))
                                }
                            </ul>

                            <div className='p-modal-footer'>
                                <button onClick={() => this.setState({ mode: 2 })}><I18Translation>{(t) => t('next')}</I18Translation></button>
                            </div>
                        </div>
                    </div>
                : null }

                { this.state.mode === 2 ?
                    <div className='picture-mode-container' style={{ overflowY: 'scroll', paddingBottom: 100 }}>
                        <div className='image-content'>
                            <ImageContent 
                                picture={ this.state.toShow[this.state.currentPictureIndex] } 
                                labels={ this.state.labels } 
                                setLabels={ this.setLabels } 
                                imageID={ this.state.imageID }
                            />
                            {/* <div className='image-container'>
                                <div className='image-label'>
                                    <h1><Translation text={ objectToLearn } /></h1>
                                    <i onClick={() => this.setState({ showInfo: !this.state.showInfo })}  className='fas fa-info-circle' style={{ zIndex: 20 }}></i>
                                    <img 
                                        ref={ this.imageRef } 
                                        src={ this.state.toShow[this.state.currentPictureIndex] } 
                                        alt='user-pic-taken' 
                                    />
                                    <canvas 
                                        onTouchStart={ this.handleSwipeStart } 
                                        onTouchMove={ this.handleSwipeEnd }
                                        onTouchEnd={ this.handleSwipe }
                                        ref={ this.canvasRef } 
                                        width='325' 
                                        height='243' 
                                        className="image-canvas" 
                                    />  
                                </div>
                                <div className='instructions'>
                                    <p><I18Translation>{(t) => t('activity4Info1')}</I18Translation></p>
                                </div>
                            </div> */}
                            <div className='image-info' style={{ width: '100%' }}>
                                <div className='image-nav'> 
                                    <div className='back' onClick={() => this.setState({ mode: 1 })}>
                                        <i class="fas fa-solid fa-backward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('back')}</I18Translation></div>
                                    </div>

                                    {/* <div className='back' onClick={() => {
                                        this.handlePhoto();
                                        this.setState({ mode: 3 });   
                                    }}>
                                        <i className="fas fa-solid fa-images"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('save')}</I18Translation></div>
                                    </div>           */}

                                    <div className='back' onClick={ () => this.props.handleOptionChange() }>
                                        <i className="fas fa-solid fa-forward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('newActivity')}</I18Translation></div>
                                    </div>         
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 3 ?
                    <div className='picture-mode-container' style={{ overflowY: 'scroll' }}>
                        <div className='image-content'>
                            <ImageContent picture={ this.state.toShow[this.state.currentPictureIndex] } labels={ this.state.labels } setLabels={ this.setLabels } imageID={ this.state.imageID }  />
                            <div className='image-info'>
                                <div className='image-nav'> 
                                    <div className='back' onClick={() => this.setState({ mode: 2 })}>
                                        <i class="fas fa-solid fa-arrow-left"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('back')}</I18Translation></div>
                                    </div>
                                    <div className='back' onClick={() => this.props.handleOptionChange()}>
                                        <i class="fas fa-solid fa-forward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('newActivity')}</I18Translation></div>
                                    </div>                 
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 3 && this.state.labels.length === 0 ? <Loading /> : null }
                { this.state.showInfo ? 
                    <div className='p-modal-container'>
                        <div className='p-modal'>
                            <div className='p-modal-header'>
                                <h1 className='display-items'><I18Translation>{(t) => t('activity4Info2')}</I18Translation></h1>
                            </div>

                            <hr className='divider' />
                            
                            <div className='p-modal-body'>
                                <ul>
                                    { this.state.imageData.map((item, index) => 
                                        <li key={ index } className='display-items'><TranslationNew text={ item.name } /></li>
                                    )}
                                </ul>
                            </div>

                            <div className='p-modal-footer'>
                                <button className='btn' onClick={() => this.setState({ showInfo: !this.state.showInfo }) }><I18Translation>{(t) => t('close')}</I18Translation></button>
                            </div>
                        </div>
                    </div>
                : null }
            </div>
        )
    }
}