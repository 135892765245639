import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuCard } from '../../components/MenuCard';
// import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LoginContext } from '../../helper/LoginContext';

export function Settings() {
    const { questionAmount, setQuestionAmount, isRandom, setIsRandom, logout, language, setLanguage, selectedLanguage, setSelectedLanguage } = useContext(LoginContext);

    const [questionInput, setQuestionInput] = useState(questionAmount);

    const [languageType] = useState({ "en": "English", "fr": "French" /*, "es": "Spanish", "pt": "Portuguese" , "it": "Italian", "de": "German" */ });
    const [targetLanguage, setTargetLanguage] = useState(languageType[language]);
    const [userSelectedLanguage, setUserSelectedLanguage] = useState(languageType[selectedLanguage]);

    const { i18n, t } = useTranslation();

    // const [lang, setLang] = useState("");

    let history = useHistory();

    useEffect(() => {
        localStorage.setItem("whichUrl", "/settings");
        // return () => {
        //     changeQuestionAmount(); // tried to do it on unmount, had no luck
        // }
    }, []);

    async function changeQuestionAmount(newAmount) { // setQuestionAmount while updating the value in db
        setQuestionInput(newAmount);
        if (newAmount < 3 || newAmount === '') return;
        newAmount = Math.trunc(newAmount); // Make sure it's an int
        setQuestionAmount(newAmount);
        try {
            await fetch('auth/change-question-amount', {
                method: "POST",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                body: JSON.stringify({ newAmount })
            });
        } catch (error) {
            console.error(error.message);
        }
    }

    // changes users target language
    async function changeLanguage(language, type) {
        let body = { Language: language, type: type };
        try {
            const response = await fetch("translation/user-language", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json",
                    token: localStorage.token
                },
                body: JSON.stringify(body)
            });
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error(error);
            toast.error("An issue occurred when changing the language, either reload the page or try again later.")
        }
    }

    // (Object.keys(languageType).find(key => languageType[key] === e.target.value))
    const handleLanguageChange = (e) => {
        let langCode = Object.keys(languageType).find(key => languageType[key] === e.target.value);
        // target language
        setLanguage(langCode);
        changeLanguage(langCode, "target");
        setTargetLanguage(e.target.value);
    }

    const handleSelectedLanguageChange = (e) => {
        let langCode = Object.keys(languageType).find(key => languageType[key] === e.target.value)
        // selected language
        setSelectedLanguage(langCode);
        changeLanguage(langCode, "selected");
        setUserSelectedLanguage(e.target.value);
        i18n.changeLanguage(langCode);
    }

    // get images of users
    const fetchUserImages = async () => {
        try {
            const response = await fetch('api/get-images', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.token
                }
            });
            const data = await response.json();
            
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div className='Settings'>
            {/* <h1 className='section-header'>Settings</h1> */}
            <h1 className='section-header'>{t('settings')}</h1>
            <div className="settings-container">
                {/* <div className="settings-card" onClick={() => { //Interesting
                        history.push("/color-picker")
                    }}>
                        <h3>Customize</h3>
                        <p>Customize the background color of your app</p>
                    </div> */}
                <div>
                    <div className="settings-card randomizer">
                        <div className='randomizer-head'>
                            {/* <h2>Question Amount</h2> */}
                            <h2>{ t("questionAmount") }</h2>
                            <input
                                type='number'
                                value={questionInput}
                                min={3}
                                max={30}
                                maxLength={2} // min,max,maxLength don't work
                                className='question-amount'
                                onChange={e => changeQuestionAmount(e.target.value)}
                            />
                        </div>
                        {/* <p>How many words do you want to be tested on in Review Memories?</p> */}
                        <p>{ t("questionInfo") }</p>
                    </div>
                    {/* <p>Toggle between a randomizer for Eye Mode.</p> */}
                    {/* <div className="settings-card randomizer">
                        <div className='randomizer-head'>
                            <h2>{ t("randomizer") }</h2>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    onClick={() => setIsRandom(!isRandom)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <p>{ t("randomizerInfo") }</p>
                    </div> */}

                    <div className="settings-card randomizer">
                        <div className='randomizer-head'>
                            {/* <i class="fas fa-solid fa-language"></i> */}
                            {/* <h2>Change selected language</h2> */}
                            <h2>{ t("selectedLanguage") }</h2>
                            <select 
                                onChange={e => handleSelectedLanguageChange(e)}
                                value={userSelectedLanguage}
                            >
                                { 
                                    Object.keys(languageType).map((key, lang) => (
                                        <option key={key}>{ languageType[key] }</option>
                                    ))
                                }                            
                            </select>
                        </div>
                        {/* <p className='setting-info'>Change the language that you are trying to learn.</p> */}
                        <p>{ t("selectedLanguageInfo") }</p>
                        <span><i>* you are comfortable with <strong>{userSelectedLanguage}</strong></i></span>
                    </div>

                    <div className="settings-card randomizer">
                        <div className='randomizer-head'>
                            {/* <i class="fas fa-solid fa-language"></i> */}
                            {/* <h2>Change target language</h2> */}
                            <h2>{ t("targetLanguage") }</h2>
                            <select 
                                onChange={e => handleLanguageChange(e)}
                                value={targetLanguage}
                            >
                                { 
                                    Object.keys(languageType).map((key, lang) => (
                                        <option key={key}>{ languageType[key] }</option>
                                    ))
                                }                            
                            </select>
                        </div>
                        {/* <p className='setting-info'>Change the language that you are trying to learn.</p> */}
                        <p>{ t("targetLanguageInfo") }</p>
                        <span><i>* you are learning <strong>{targetLanguage}</strong></i></span>
                    </div>

                    {/* <div className="settings-card randomizer" onClick={() => console.log("lol")}>
                        <div className='randomizer-head'>
                            <i class="fas fa-download"></i>
                            <h2>Download your images</h2>
                            <p></p> 
                            <p></p>
                        </div>
                    </div> */}
                    
                    <MenuCard
                        icon_class={"fas fa-sign-out-alt"}
                        title={"Logout"}
                        myFunction={(e) => logout(e)}
                    />
                </div>
                <MenuCard
                    icon_class={"fas fa-trash-alt"}
                    className={"delete-button"}
                    title={"Delete Account"}
                    myFunction={() => history.push("/delete-user")}
                />
            </div>
        </div>
    )
}