import React from 'react';
import { useHistory } from 'react-router-dom';

export function TreasureHuntOptions() {
    let history = useHistory();

    return (
        <div className="option-container">
            <button className="exit-btn" onClick={() => { history.push("/dash-board") }}><i className="fas fa-angle-left"></i></button>
            <div className="banner">
                <p>Find and match related objects near you.</p>
            </div>
            <div className="options">
                <div className="option-item">
                    <p>Start from something you see</p>
                    <button className="round-btn" onClick={() => {
                        history.push("/treasure-hunt");
                    }}><i className="fas fa-eye"></i></button>
                </div>
                <div className="option-item">
                    <p>Start from something I remember</p>
                    <button className="round-btn" onClick={() => {
                        history.push("/my-memories");
                    }}><i className="fas fa-image"></i></button>
                </div>
            </div>
        </div>
    )
}
