import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LoginContext } from './LoginContext';

// Checks if the user is logged in, if not the user is redirected to the login page
const PrivateRoute = (props) => {
    const { isAuthenticated } = useContext(LoginContext);

    return (
        <Route
            render={() => (
                isAuthenticated ?
                    <props.component {...props} /> :
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )}
        />
    );
}

export default PrivateRoute;