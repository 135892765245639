import React, { useState } from 'react';
import TranslationNew from './TranslationNew';

function NewLabel({ handleNewLabel, closeAreYouSure, imageId, setNewLabelData }) {
    const [editedLabel, setEditedLabel] = useState('');
    const [checkTranslation, setCheckTranslation] = useState(true);

    // handle new label
    // async function handleNewLabel(newLabel) {
    //     try {
    //         const response = await fetch('word/new-label', {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 token: localStorage.token
    //             },
    //             body: JSON.stringify({ newLabel, imageId })
    //         });

    //         const data = await response.json();
    //         console.log(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }


    return (
        <div className='p-modal-container'>
            <div className='p-modal'>
                <div className='are-you-sure'>
                    <span>What is the new label you would like to add for this object in English?</span>
                    <button className='close-btn-modal' onClick={() => closeAreYouSure()}>
                        X
                    </button>
                </div>
                
                <div className='yes-no'>
                    <div className='new-lbl-form'>
                        <input 
                            type="text" 
                            onChange={(e) => {
                                setEditedLabel(e.target.value)
                            }} 
                            placeholder='Enter label'
                        />
                        {
                            checkTranslation ? 
                            <button onClick={() => {
                                setCheckTranslation(!checkTranslation);
                            }}>Check translation</button>
                            :
                            <button onClick={() => {
                                handleNewLabel(editedLabel, imageId);
                                setNewLabelData({ label: editedLabel, id: imageId });
                                closeAreYouSure();
                            }}>Save</button>
                        }
                    </div>
                </div>
                {
                    !checkTranslation && editedLabel.length > 0  ?
                    <div className='yes-no'>
                        <h1>Translation:</h1>
                        <h1 style={{ color: '#0d968b' }}><TranslationNew text={editedLabel} /></h1>
                    </div> : <></>
                }
            </div>
        </div>
    )
}

export default NewLabel;