import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpeechSynthesis } from 'react-speech-kit';
import { toast } from 'react-toastify';
import { LoginContext } from '../helper/LoginContext';
import useLongPress from '../helper/useLongPress';
import AreYouSure from './AreYouSure';
import TranslationNew from './TranslationNew';

export default function ImageContent(props) {

    const { picture, labels, imageID } = props;
    const onLongPress = useLongPress();

    const { language } = useContext(LoginContext);

    const [showDefinition, setShowDefinition] = useState(false);
    const [wordInfo, setWordInfo] = useState({ definition: "", example: "" });

    const [audioSrc, setAudioSrc] = useState('');

    const [infoOnWord, setInfoOnWord] = useState(
        { 
            sentence: { 
                english: '', french: ''
            },
            definition: {
                english: '', french: ''
            } 
        }
    );

    const [translations, setTranslation] = useState([]);
    const prevLabelsRef = useRef([]);

    const { t } = useTranslation();

    const [selectedWord, setSelectedWord] = useState("");
    const [showAreYouSure, setShowAreYouSure] = useState({ show: false, label: "" });

    // speech synthesis
    const [value, setValue] = useState("");
    const { speak, voices } = useSpeechSynthesis();
    const [voice, setVoice] = useState(1); 

    // added january 17, 2023
    const [canTranslateDefinition, setCanTranslateDefinition] = useState(language !== 'en' ? true : false) //useState(false);
    const [canTranslateExample, setCanTranslateExample] = useState(language !== 'en' ? true : false) //useState(true);

    const [mainLabel, setMainLabel] = useState("");

    useEffect(() => { // On unmount
        // console.log(language);

        // this code gets all voices
        // let voice = speechSynthesis.getVoices();
        // for (let i = 0; i < voice.length; i++) {
        //     console.log(voice[i]);
        // }

        return () => {
            setWordInfo({ definition: "", example: "" });
            setSelectedWord("");
            setShowDefinition(false);
        }
    }, []);

    useEffect(() => {
        if (audioSrc) {
            const audioElement = document.getElementById("audioElement");
            audioElement.play();
        }
    }, [audioSrc]);


    // NEW BATCH TRANSLATION TEST (2023-10-03 | 5:51 pm)
    useEffect(() => {
        // translation batch
        async function fetchTranslationBatch() {
            if (JSON.stringify(prevLabelsRef.current) !== JSON.stringify(props.labels)) {
                try {
                    const response = await fetch(`translation/translate-batch`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.token
                        },
                        body: JSON.stringify({ texts: props.labels })
                    });
    
                    const data = await response.json();
                    // console.log("From translation batch", data);
                    setTranslation(data.translations);

                    // Update the ref to the current labels after fetching translations
                    prevLabelsRef.current = props.labels;
                } catch (error) {
                    console.error(error);
                }
            }
        }

        fetchTranslationBatch();
    }, [props.labels]);

    useEffect(() => {
        if(language === 'fr') setVoice(11)
        else if (language === "es") setVoice(9);
        else if (language === "pt") setVoice(9);
        else setVoice(0);
        // getWordInfo();
        // console.log(labels);
        getInfoWord();
        // getPressmonSentence();
    }, [labels]);


    useEffect(() => {
        // handles adding new label
        async function handleNewLabel(labels) {
            // adds new label at the front and pushes the rest of the labels after the new label
            props.setLabels([props.newLabelData.label, ...props.labels]);
            try {
                const response = await fetch('word/new-label', {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token
                    },
                    body: JSON.stringify({ labels, imageId: imageID })
                });
                const data = await response.json();

                // console.log(data);

                if (data) toast.success("Label added successfully");

                return data;
            } catch (error) {
                console.error(error);
            }
        }

        // when the label changes perform another request to update label sentences based on new labels for image
        async function handleSentenceUpdate(labels, newLabel) {
            try {
                const response = await fetch('word/update-sentence', {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.token
                    },
                    body: JSON.stringify({ labels, newLabel })
                });
                const data = await response.json();

                // console.log(data);

                return data;
            } catch (error) {
                console.error(error);
            }
        }

        if (props.newLabelData && props.newLabelData.label !== null) {    
            // console.log('Label changes:', [...props.labels, props.newLabelData.label]);
            handleSentenceUpdate([...props.labels, props.newLabelData.label], props.newLabelData.label);
            handleNewLabel([props.newLabelData.label, ...props.labels]);
        }
    }, [props.newLabelData]);

    // disabled on October 4, 2023 8:16 pm
    // useEffect(() => { 
    //     if (selectedWord.length > 0) {
    //         editWord('new'); 
    //     }
    // }, [selectedWord]);

    async function editWord(editType, wordToDelete = "") { // Changes primary word or can delete word
        let newLabels = [...labels];

        if (editType === 'new') { // change primary word
            newLabels.unshift(selectedWord);
            console.log("Selected Word:", selectedWord);
            newLabels = newLabels.filter((label, index) => newLabels.indexOf(label) === index);

            console.log('New Labels:',newLabels);

            // set labels for props
            props.setLabels(newLabels);
        } else if (editType === 'delete') { // delete selected word
            newLabels = newLabels.filter((label) => label !== wordToDelete); 
        }

        try {
            const body = { labels: newLabels, image_id: imageID };
            await fetch(`word/${editType}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                body: JSON.stringify(body)
            });
            props.setLabels(newLabels);

            // if (editType === 'delete') alert(`${wordToDelete} has been deleted from this word list.`);
            if (editType === 'delete') toast.success(`${wordToDelete} has been deleted from this word list`);
        } catch (error) {
            console.error(error);
            toast.err(error.message);
        }
    }

    // open ai | chatgpt result
    async function getInfoWord() {
        try {
            let body = JSON.stringify({ "word": labels[0], "imageID": imageID });

            const response = await fetch(`word/sentence`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json", 
                    token: localStorage.token 
                },
                body: body
            });
            const data = await response.json();

            // console.log('chatgpt:',data);

            setInfoOnWord({
                sentence: { 
                    targetLanguage: data.sentence.targetLanguage || "Could not find example sentence for this word.",
                    selectedLanguage: data.sentence.selectedLanguage || "Could not find example for this word."
                },
                definition: {
                    targetLanguage: data.definition.targetLanguage || "Could not find example sentence for this word.",
                    selectedLanguage: data.definition.selectedLanguage || "Could not find example for this word."
                }
            });
            
        } catch (error) {
            console.error(error.message);
            toast.error(error);
        }
    }


    // action log
    async function logAction(action, detail) {
        try {
            const abortController = new AbortController();
            await fetch('auth/user-action', {
                method: "POST",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                body: JSON.stringify({
                    action: action,
                    detail: detail
                }),
                signal: abortController.signal
            });

        } catch (error) {
            console.error(error);
        }
    }

    async function handleTextToSpeech(text) {
        try {
            const response = await fetch(`translation/tts?text=${encodeURIComponent(text)}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                }
            });

            const data = await response.json();
            
            // console.log(data);
            setAudioSrc('');

            setAudioSrc(data.audio);
        } catch (error) {
            console.error("Error fetching audio:", error);
            toast.error("Audio generation temporarily unavailable");
        }
    }


    function makePrimary(label) {
        const newLabels = [label, ...labels.filter(lbl => lbl !== label)];
        // console.log('new labels:', newLabels);

        // sending the updated labels to the server
        fetch('word/new', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                token: localStorage.token
            },
            body: JSON.stringify({
                labels: newLabels,
                image_id: imageID
            })
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
        }).then(data => {
            // console.log(data);
            props.setLabels(newLabels);
            setMainLabel(label);
        }).catch(error => {
            console.error("There was a problem with the fetch operation:", error);
        })

        // props.setLabels(newLabels);
    }


    return (
        <>
            {showAreYouSure.show &&
                <AreYouSure
                    deleteFunc={() => editWord('delete', showAreYouSure.label)}
                    itemToDelete={<span><span className='item-to-delete'><TranslationNew text={showAreYouSure.label.toLowerCase()} /></span></span>}
                    closeAreYouSure={() => setShowAreYouSure({ show: false, label: "" })}
                    closeImageModal={false}
                />
            }
            <div className='image-container' >
                <div className='image-label'>
                    <img src={picture} width={window.screen.width - 70} alt="camera screenshot" />
                    {labels.length > 0 &&
                        <>
                            <h1 id="speech-id" src={audioSrc}>
                                <i className="fas fa-volume-up" onClick={(e) => {
                                    e.stopPropagation();
                                    logAction('text-to-speech', `used on: ${labels[0]}`)
                                    handleTextToSpeech(document.getElementById('speech-id').innerText || "");
                                }}></i>
                                <h2 className='img-main-word'>
                                    {mainLabel.length > 0 ? <TranslationNew text={mainLabel} /> : translations[0] || "loading..."} 
                                    {/* {translations[0] || "loading..."} */}
                                </h2>
                            </h1>
                            <audio id="audioElement" src={audioSrc}></audio>
                            <i
                                className={showDefinition ? "fas fa-arrow-left" : "fas fa-info-circle"}
                                onClick={() => setShowDefinition(!showDefinition)}
                            >
                            </i>
                        </>
                    }
                </div>
                {!showDefinition ?
                    <>
                        <div className='instruction'>
                            Tap the word that best represents the image. Long press a word that you want to delete.
                        </div>
                        <div className='word-list'>
                            {props.labels.length > 0 && (
                                /*props.labels.slice(0, 6).map((label, l)*/
                                props.labels.map((label, l) => (
                                    <div
                                        key={label}
                                        className={l === 0 ? 'selected label' : 'unselected'}
                                        style={!translations[l] ? { pointerEvents: 'none' } : {}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // console.log("current label:", label);

                                            // make current label the main one
                                            makePrimary(label);
                                            // setShowAreYouSure({ show: true, label: label });
                                            
                                            // show a pop up that displays if the user wants to make label primary or delete

                                            setWordInfo({ definition: "", example: "" });
                                        }}
                                        {...onLongPress(() => setShowAreYouSure({ show: true, label: label }))}
                                    >
                                        {/* before it was just { label }, ie. without the translation wrapper */}
                                        {/* { <Translation text={ label } /> } */}

                                        {/* <TranslationNew text={ label } /> */}
                                        {translations[l] || <span className='loading-text'>"loading..."</span>}
                                    </div>
                                )))
                            }
                        </div>
                    </>
                    :
                    <div className='example-wrapper'>
                        <h3>{
                            t('definition')} 
                            <i class="fas fa-solid fa-language" onClick={() => setCanTranslateDefinition(!canTranslateDefinition)}></i>
                        </h3>
                        { canTranslateDefinition ? infoOnWord.definition.targetLanguage : infoOnWord.definition.selectedLanguage }
                        <h3>{t('example')} <i class="fas fa-solid fa-language" onClick={() => setCanTranslateExample(!canTranslateExample)}></i></h3>
                        { canTranslateExample ? infoOnWord.sentence.targetLanguage : infoOnWord.sentence.selectedLanguage }
                    </div>
                }
            </div>
        </>
    )
}