import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../helper/LoginContext';


export function ChooseColor() {
    const { color, navbarColor, fontColor, setColor, setNavbarColor, setFontColor } = useContext(LoginContext);

    let history = useHistory();

    const onColorChange = (e) => {
        setColor(e.target.value)
    }

    const onNavbarColorChange = (e) => {
        console.log(e.target.value);
        setNavbarColor(e.target.value);
    }

    const onFontColorChange = (e) => {
        setFontColor(e.target.value);
    }

    return (
        <div className="color-picker" style={{ backgroundColor: color }}>
            <label style={{ color: fontColor }}>Pick a color you want to choose for your background</label>
            <input type="color" value={ color } onChange={(e) => onColorChange(e)} />
            <label style={{ color: fontColor }}>Pick a color you want to choose for your navbar</label>
            <input type="color" value={ navbarColor } onChange={(e) => onNavbarColorChange(e)} />
            <label style={{ color: fontColor }}>Pick a color you want to choose for you text</label>
            <input type="color" value={ fontColor } onChange={(e) => onFontColorChange(e)} />
            <button onClick={() => {
                console.log(navbarColor);
                history.push("/temp");
                history.push("/dashboard");
            }}>Finalize</button>
        </div>
    )
}