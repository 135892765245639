import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import Webcam from 'react-webcam';
import { LoginContext } from '../../helper/LoginContext';
import { getRandomIndex } from '../../helper/randomizer';

import { VIDEO_CONSTRAINTS } from "../../helper/VideoConstraints";
// import { Timer } from '../Timer';

// what object is this? mode
export function Option3() {
     const { setIsRandom } = useContext(LoginContext);
     const webcamRef = useRef(null);

     const [second, setSecond] = useState(0);
     const [image, setImage] = useState("");
     const [camState, setCamState] = useState("");

     const [imageResponse, setImageResponse] = useState(null);

     const [images, setImages] = useState([]);
     const [guess, setGuess] = useState("");
     const [sucess, setSucess] = useState(null);
     const [save, setSave] = useState(false);

     const [collapsible, setCollapsible] = useState("clues");

     const hoursMinSecs = { hours: 0, minutes: 0, seconds: 15 };
     
     let history = useHistory();

     const handleSubmit = (e) => {
          e.preventDefault();
          setGuess("");          
          let detectedImages = [];
          imageResponse.forEach((item) => {
               let x = item.name;
               detectedImages.push(x.toLowerCase());
          });
          if (detectedImages.includes(guess.toLowerCase())) {
               setSucess(true);
          } else {
               setSucess(false);
          }
     }

     const handleObjectDetection = async (dataUri) => {
          try {
               const body = { dataUri };
               const response = await fetch(`api/object-detection`, {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                         token: localStorage.token
                    },
                    body: JSON.stringify(body)
               });
               const parseResponse = await response.json();
               console.log(parseResponse);
               return parseResponse;
          } catch (error) {
               console.error(error.message);
               toast.error(error.message);
          }
     }

     const capture = useCallback(() => {
          if (webcamRef !== null && webcamRef !== undefined) {
               const imageSrc = webcamRef.current.getScreenshot();
               return imageSrc;
          }
     }, [webcamRef]);

     const detect = async () => {
          if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
               // const video = webcamRef.current.video;
               const videoWidth = webcamRef.current.video.videoWidth;
               const videoHeight = webcamRef.current.video.videoHeight;

               // Set Video Width
               webcamRef.current.video.width = videoWidth;
               webcamRef.current.video.height = videoHeight;

               // Make detections
               let obj = await capture();

               setImages([...images, obj]);
          }
     }

     useEffect(() => {
          if (camState === "TAKE") {
               while (second <= hoursMinSecs.seconds) {
                    const interval = setInterval(() => {
                         setSecond(second + 1);
                         detect();
                    }, 1000);
                    //console.log(`${second} seconds have elapsed since mounting`);
                    return () => clearInterval(interval);
               }
               // console.log("Time Over");
               setCamState("FINISH");
          } else if (camState === "FINISH") {
               // console.log("DONE CALLING API");
               if (images.length > 0) {
                    // console.log(images);
                    let randomImage = images[getRandomIndex(images.length)];
                    setImage(randomImage);     
               } 
          }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [second, camState]);

     async function saveImage(dataUri) {
          try {
               const body = { dataUri };
               const response = await fetch(`api/image`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json", token: localStorage.token },
                    body: JSON.stringify(body)
               });
               const parseResponse = await response.json();
               return parseResponse;
          } catch (error) {
               console.error(error.message);
               toast.error(error.message);
          }
     }

     useEffect(() => {
          async function getResponse() {
               try {
                    if (image !== "") {
                         const response = await handleObjectDetection(image);
                         // console.log(response);
                         setImageResponse(response);
                    }
               } catch (error) {
                    console.error(error.message);
                    toast.error(error.message);
               }
          }
          getResponse();
     }, [image]);

     return (
          <div className="App">
               <div className="App-header">
                    {
                         image !== "" ? (
                              <div className="mode-content">
                                   {
                                        sucess === true ? (
                                             <div className="p-modal-container">
                                                  <div className={ `p-modal` }>
                                                       <div className="p-modal-header">
                                                           <h2>Correct</h2>
                                                       </div>
                                                       <div className="p-modal-footer">
                                                            <button onClick={() => {
                                                                 setSucess(null);
                                                                 setIsRandom(true);
                                                                 history.push("/around-me");
                                                            }}>Continue</button>
                                                            <button onClick={() => {
                                                                 setSucess(null);
                                                                 history.push("/dash-board");
                                                            }}>Exit</button>
                                                            <button className={ save ? "saved" : ""} onClick={() => {
                                                                 saveImage(image).then(() => setSave(true));
                                                            }}>{ save ? <>Saved</> : <>Save Image</>}</button>
                                                       </div>
                                                  </div>
                                             </div>
                                        ) : 
                                        sucess === false ? (
                                             <div className="p-modal-container">
                                                  <div className={ `p-modal` }>
                                                       <div className="p-modal-header">
                                                           <h2>Incorrect</h2>
                                                       </div>
                                                       <div className="p-modal-footer">
                                                            <button onClick={() => {
                                                                 setSucess(null);
                                                            
                                                            }}>Retry</button>
                                                            <button onClick={() => {
                                                                 setSucess(null);
                                                                 setIsRandom(true);
                                                                 history.push("/around-me");
                                                            }}>Continue</button>
                                                            <button onClick={() => {
                                                                 setSucess(null);
                                                                 history.push("/dash-board");
                                                            }}>Exit</button>
                                                       </div>
                                                  </div>
                                             </div>
                                        ) : (<></>)
                                   }
                                   <div className="image-container">
                                        <img src={ image } alt="capturedimage" />
                                   </div>
                                   <form className="guess-form" onSubmit={ handleSubmit }>
                                        <input className="guess-bar" type="text" value={ guess } onChange={(e) => setGuess(e.target.value) } />
                                        <input className="check-btn" type="submit" value="Check" />
                                   </form>
                                   <div className="expandable-clues">
                                       <h1 onClick={() => {
                                           if (collapsible === "clues") {
                                               setCollapsible("clues-collapsible")
                                           } else if (collapsible === "clues-collapsible") {
                                               setCollapsible("clues");
                                           }
                                       }}>{ collapsible === "clues" ? <>&#8593;</> : <>&#8595;</> }</h1>
                                       <div className={ collapsible } >
                                           <div className="clue">
                                               <h3 style={{ fontWeight: "bold" }}>Down</h3>
                                               {
                                                    imageResponse !== null &&
                                                    imageResponse.map((item, index) => (
                                                       <div key={ index }>
                                                            {item.name}
                                                       </div>
                                                    ))
                                               }
                                           </div> 
                                       </div>
                                   </div>
                              </div>
                         ) : (
                              <div className="camera-container">
                                   <div className="timer">
                                        <p>Time Remaining: { 10 - second }s</p>
                                        <div className='progress' style={{ width: `${(100/15) * (15 - second)}%`, transition: '0.2s' }}></div>
                                   </div>
                                   <Webcam audio={ false } ref={ webcamRef } muted={ true } className="webcam" videoConstraints={ VIDEO_CONSTRAINTS } screenshotFormat="image/png" screenshotQuality={ 0.5 } onUserMedia={() => setCamState("TAKE")} />
                              </div>
                         )
                    }
               </div>
          </div>
     )
}