import { translation } from "./api";

export const drawRect = (detections, ctx) => {
    
    detections.forEach(prediction => {
        // Get Prediction Results
        const [x, y, width, height] = prediction['bbox'];
        const text = prediction['class'];

        // Set Styling
        const color = "#fff";
        ctx.StrokeStyle = color;
        
        ctx.fillStyle = color;

        // Draw Rectangles And Text
        ctx.beginPath();
        ctx.shadowColor = "black";
        ctx.shadowBlur = 1;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.font = "24px Arial";
        ctx.fillText(text, x, y);
        ctx.shadowColor = "black";
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.font = "24px Arial";
        ctx.rect(x, y, width, height);
        ctx.stroke();
    });
}

export const clearCanvas = (ctx) => {
    ctx.clearRect(0, 0, 600, 600);
}

export const Utility_DrawRect = (detections, ctx) => {
    let dimX = 325;
    let dimY = 243;
    ctx.fillStyle = '#ffffff';
    detections.forEach(prediction => {
        // Get Prediction Results
        // const [x, y, width, height] = prediction.boundingPoly['normalizedVertices'];
        let x = prediction.boundingPoly['normalizedVertices'][0].x;
        let y = prediction.boundingPoly['normalizedVertices'][0].x;
        let width = prediction.boundingPoly['normalizedVertices'][2].x;
        let height = prediction.boundingPoly['normalizedVertices'][2].y;
        let text = prediction['name'];

        


        // clear rectangle so when switching images canvas doesn't overlap
        ctx.clearRect(0, 0, dimX, dimY);

        // width = width * ctx.width; // canvas width
        // width = width * ctx.height; // canvas height
        // console.log(`x: ${x}, y: ${y}, width: ${width}, height: ${height}`, ctx.width, ctx.height);

        
        

        // Draw Rectangles and Text
        ctx.beginPath();

        // Set Styling
        ctx.StrokeStyle = "#000000";

        // console.log("RECTANGLE DEBUG:", x, y, width, height);
        // show bounding boxes if they are near thresholds (borders)
        if(x < 0.1) x = 0.1;
        if(y < 0.1) y = 0.1;
        if(width > 0.9) width = 0.8;

        ctx.font = '24px Arial';
        translation(text).then(data => {
            console.log(data);
            ctx.fillText(data.translations, x * dimX, y * dimY);
        });

        

        // if (x * dimX > dimX - 5 || x * dimX > dimX + 5) {
        //     ctx.fillText(text, x * (dimX - 10), y * dimY)
        // }

        // if (y * dimY > dimY - 5 || y * dimY < dimY + 5) {
        //     ctx.fillText(text, x * dimX, y * (dimY + 10))
        // }

        // ctx.rect(x * ctx.width, y * ctx.height, width * ctx.width, height * ctx.height);
        ctx.rect(x * dimX, y * dimY, width * dimX, height * dimY);
        ctx.stroke();

        ctx.closePath();
    });
    // console.log("Detections:", detections, 'ctx:', ctx);
}

export const DrawRect_Utility = (detections, ctx) => {
    let CANVAS_WIDTH = 640;
    let CANVAS_HEIGHT = 480;
    for (let i = 0; i < detections.length; i++) {

        
        ctx.beginPath();
        console.log(detections);
        const text = detections[i].name;
        
        const startingPos = detections[i].boundingPoly.normalizedVertices[0];

        // Set Styling
        const color = "#fff";
        ctx.StrokeStyle = color;

        ctx.fillStyle = color;

        // Draw Rectangles and Text
        ctx.beginPath();
        ctx.shadowColor = "white";
        ctx.shadowBlur = 1;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.font = "24px Arial";
        ctx.fillText(text, startingPos.x * CANVAS_WIDTH, startingPos.y * CANVAS_HEIGHT);

        ctx.moveTo(startingPos.x * CANVAS_WIDTH, startingPos.y * CANVAS_HEIGHT);
        for (let j = 1; j < detections[i].boundingPoly.normalizedVertices.length; j++) {
            
            let pos = detections[i].boundingPoly.normalizedVertices[j];
            ctx.shadowBlur = 0;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.lineTo(pos.x * CANVAS_WIDTH, pos.y * CANVAS_HEIGHT);
        }
        ctx.lineTo(startingPos.x * CANVAS_WIDTH, startingPos.y * CANVAS_HEIGHT);
        ctx.StrokeStyle = "black";
        ctx.stroke();

    }
}