import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../helper/LoginContext';

export default function TranslationNew({ text, imageID }) {
    const [translation, setTranslation] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { language } = useContext(LoginContext);


    const fetchTranslation = async () => {
        // console.log('text received:', text);
        setLoading(true);
        try {
            let body = { text: text };

            if (text === undefined || text === null || text.length <= 0) {
                return '';
            }
            
            // console.log("Image ID", imageID);
            const response = await fetch(`translation/translate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                },
                body: JSON.stringify(body)
            });

            const data = await response.json();
            
            // console.log('Response', data);

            setTranslation(data.translations[0]);
            // if (data.translations && data.translations.length > 0) {
            //     setTranslation(data.translations[0]);
            // } else {
            //     throw new Error("No translations received");
            // }
            
        } catch (error) {
            console.error(error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (language !== "en") {
            fetchTranslation();
        } else {
            setTranslation(text);
        }
    }, [language, text]);

    if (loading) return "loading..."

    return <>{translation.toLowerCase()}</>;
}


// import React, { useContext, useEffect, useState } from 'react';
// import { LoginContext } from '../helper/LoginContext';

// export default function TranslationNew({ text, imageID }) {
//     const [translation, setTranslation] = useState('');
//     const { language } = useContext(LoginContext);

//     useEffect(() => {
//         async function fetchTranslation() {
//             try {
//                 let body = { text: text };
                
//                 // console.log("Image ID", imageID);
//                 const response = await fetch(`translation/translate`, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                         token: localStorage.token
//                     },
//                     body: JSON.stringify(body)
//                 });
//                 const data = await response.json();
//                 console.log(data);
//                 setTranslation(data.translations[0]);
//             } catch (error) {
//                 console.error(error);
//             }
//         }

//         if (language !== "en") {
//             fetchTranslation();
//         } else {
//             setTranslation(text);
//         }
//     }, [language, text]);

//     return (
//     //   <>{ text.length > 0 ? this.state.text : this.props.text }</>
//     <>{translation.toLowerCase()}</>
//     )
// }

