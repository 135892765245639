import React, { Component } from 'react'
import { toast } from 'react-toastify';

import * as workerTimers from 'worker-timers';
import { WebCam } from './WebCam';


export class FindObject extends Component {
     constructor() {
          super();

          this.state = {
               initialTime: 20,
               objectDetections: [],
               filterDone: false,
               progress: 100,
               image: "",
               saved: false
          }
     }

     async SaveImage(dataUri) {
          try {
               const body = { dataUri };
               const response = await fetch(`api/image`, {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                         token: localStorage.token
                    },
                    body: JSON.stringify(body)
               });
               const parseResponse = await response.json();
               return parseResponse;
          } catch (error) {
               console.error(error.message);
               toast.error(error.message);
          }
     }

     componentDidMount() {
          console.log(this.props.option);
          this.interval = workerTimers.setInterval(() => {
               this.setState({ initialTime: this.state.initialTime - 1 });
               if (this.state.initialTime <= 0) {
                    workerTimers.clearInterval(this.interval);
               }
          }, 1000);
     }

     renderSwitch(filter) {
          const { progress, image, save } = this.state;
          const { toFind } = this.props;
          switch (filter) {
               case true:
                    return (
                         <div className='p-modal-container'>
                              <div className='p-modal'>
                                   <div className='p-modal-header'>
                                        {
                                             this.props.option === 31 ? (
                                                  <h1>Great Job! You found {toFind.answer}</h1>
                                             ) : this.props.option === 4 ? (
                                                  <h1>Correct! You can <p className='display-word'>{toFind.hint}</p> with <p className='display-word'>{toFind.answer}</p></h1>
                                             ) : (<></>)
                                        }
                                   </div>
                                   <div className='p-modal-body'>
                                        <img src={image} alt="found-img" />
                                   </div>
                                   <div className='p-modal-footer'>
                                        <button className={save ? "saved" : ""} onClick={() => this.SaveImage(image).then(() => this.setState({ save: true }))}>{save ? <>Saved</> : <>Save Image</>}</button>
                                        <button onClick={() => window.location.pathname = "/dash-board"}>Back</button>
                                   </div>
                              </div>
                         </div>
                    );
               default:
                    return (
                         <div className='App'>
                              <div className='App-header'>
                                   <div className='timer'>
                                        <p>Time Remaining: {this.state.initialTime}s</p>
                                        <div className='progress' style={{ width: `${(progress / 20) * this.state.initialTime}%`, transition: '0.2s' }}></div>
                                   </div>
                                   {
                                        !this.state.filterDone && <WebCam toFind={this.props.toFind} finished={this.state.initialTime <= 0 ? true : false} allDetections={(detections) => this.setState({ objectDetections: [...new Set(detections)] })} sendImage={(image) => this.setState({ image: image }, () => this.setState({ filterDone: true }))} />
                                   }
                              </div>
                         </div>
                    );
          }
     }

     render() {
          return this.renderSwitch(this.state.filterDone);
     }

     componentWillUnmount() {
          workerTimers.clearInterval(this.interval);
     }
}