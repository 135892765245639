import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// import * as tf from "@tensorflow/tfjs";
import * as cocossd from "@tensorflow-models/coco-ssd";

import 'react-html5-camera-photo/build/css/index.css';
import Webcam from 'react-webcam';

import { toast } from 'react-toastify';
// import { DetectedItems } from '../component/DetectedItems';
// import { EyeMode } from './EyeMode';
// import { Timer } from '../component/Timer';
import { TFCamera } from './TFCamera';
import { Crossword } from '../../components/Crossword';
import { LoginContext } from '../../helper/LoginContext';


const VIDEO_CONSTRAINTS = {
    audio: false,
    facingMode: "environment"
}

export function TensorflowCamera() {
    const { setIsRandom } = useContext(LoginContext);

    const [detectionArray, setDetectionArray] = useState([]);
    const [gameState, setGameState] = useState("INITIAL");
    const [wantToDetect, setWantToDetect] = useState("");

    const [selectTime, setSelectTime] = useState(0);

    const [relatedWords, setRelatedWords] = useState([]);

    // let hoursMinSecs = { hours: 0, minutes: 0, seconds: 20 };

    const webcamRef = useRef(null);
    // const canvasRef = useRef(null);

    let history = useHistory();

    // const hoursMinSecs = { hours: 0, minutes: 0, seconds: 10 };

    const [timerFinished, setTimerFinished] = useState(false);

    const runCoco = async () => {
        const net = await cocossd.load();

        setInterval(() => {
            detect(net)
        }, 100);
    }

    // main detection
    const detect = async (net) => {
        // Check data is available
        if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
            // Get Video Properties
            const video = webcamRef.current.video;
            const videoWidth = webcamRef.current.video.videoWidth;
            const videoHeight = webcamRef.current.video.videoHeight;
            // Set video width
            webcamRef.current.video.width = videoWidth;
            webcamRef.current.video.height = videoHeight;
            // Set canvas height and width
            // canvasRef.current.width = videoWidth;
            // canvasRef.current.height = videoHeight;
            // 4. TODO - Make Detections
            const obj = await net.detect(video);
            // console.log(obj);
            // make sure tensorflow is always detecting something
            if (obj.length !== 0) {
                // console.log(obj);
                setDetectionArray(oldArray => [...oldArray, obj[0].class]);
            }
            // 5. TODO - Update drawing utility
            // drawSomething(obj, ctx)  
        }
    }

    // filter the array
    const filterArray = () => {
        setDetectionArray(detectionArray => [...new Set(detectionArray)])
    }

    useEffect(() => {
        if (window.location.pathname === "/tensorflow-camera" && gameState === "INITIAL") {
            setTimeout(() => {
                toast('Timer', {
                    position: "top-center",
                    autoClose: 15 * 1000,
                    hideProgressBar: false,
                    pauseOnHover: false,
                    progress: 0,
                    onClose: () => {
                        setTimerFinished(true)
                        filterArray()
                    },
                    theme: 'dark'
                })
            }, 2000);
        }
    }, [selectTime, gameState]);

    useEffect(() => {
        runCoco();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (wantToDetect !== "") {
            console.log(wantToDetect);
        }
    }, [wantToDetect]);

    useEffect(() => {
        async function getRelatedWords() {
            if (gameState === "QUIZ") {
                try {
                    const response = await fetch(`word/datamuse?word=${wantToDetect}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.token
                        }
                    });
                    const data = await response.json();
                    console.log(data);
                    if (data) {
                        console.log(data)
                        setRelatedWords(data);
                    }
                } catch (error) {
                    console.error(error.message);
                    toast.error(error);
                }
            }
        }
        getRelatedWords();
    }, [gameState, history, wantToDetect]);

    function StateOfGame() {
        switch (gameState) {
            case "SELECTTIMER":
                return (
                    <div className="App-header">
                        <div className="info-container">
                            <h3>In This Game Mode You Will...</h3>
                            <ul>
                                <li>Stand In One Place Of Your Choosing</li>
                                <li>Within A Time Of Your Choosing You Will</li>
                                <li>Take A Video of the View Around You</li>
                            </ul>
                        </div>
                        <form className="form-container" onSubmit={() => setGameState("INITIAL")}>
                            <label>How Many Seconds Do You Want To View Items Around You?</label>
                            <input type="number" value={selectTime} max={30} min={5} onChange={(e) => setSelectTime(e.target.value)} />
                            <button className="submit-btn" type="submit">Confirm</button>
                        </form>
                    </div>
                )
            case "INITIAL":
                return (
                    <div className="App-header">
                        {/* <Timer hoursMinSecs={ hoursMinSecs } /> */}
                        {
                            !timerFinished &&
                            <>
                                {/* <h3>env</h3> */}
                                <Webcam ref={webcamRef} muted={true} className="webcam" videoConstraints={VIDEO_CONSTRAINTS} onUserMediaError={() => setGameState("NO_CAMERA")} />
                            </>

                        }

                        <div className="detected-items">
                            {/* {
                                timerFinished && <h3>Some Items we detected were...</h3>
                            } */}
                            {
                                timerFinished && <h3>Select an item you want to take a picture of...</h3>
                            }
                            <div className="word-list">
                                {

                                    // TODO: Give ability to reselect from detection array
                                    detectionArray.length > 0 && timerFinished ? (
                                        detectionArray.map((detection, index) => (
                                            <div className="unselected" key={index} data-toggle="modal" data-target="#select" onClick={() => {
                                                setWantToDetect(detection);
                                            }}>{detection}</div>
                                        ))
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            <div className="modal fade" id="select" tabIndex="-1" role="dialog" aria-labelledby="select" aria-hidden="true">
                                <div className="modal-dialog are-you-sure" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Are you sure you want to find {wantToDetect} near you?</h5>
                                            <span className="close" aria-hidden="true" data-dismiss="modal" aria-label="Close">×</span>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => setGameState("PICTURE")} >Yes</button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="btn-container">
                            <button onClick={() => {
                                history.push("/dash-board");
                            }}>Exit</button>
                        </div> */}
                    </div>
                );
            case "PICTURE":
                return (
                    <>
                        {/* <h3>env</h3> */}
                        <TFCamera wordToDetect={wantToDetect} myFunction={() => setGameState("QUIZ")} resetFunction={() => setGameState("INITIAL")} />
                    </>
                );
            case "QUIZ":
                // TODO: - Ask for related terms to the detection they made
                //       - Also give them multiple choice to make them select something the correct sentence corresponding to the word they wanted to detect
                return (
                    <div className="App-header">
                        <div className="detected-items">
                            <h1>Recap</h1>
                            <h3>These items are related to {wantToDetect}</h3>
                            <div className='word-list'>
                                {
                                    relatedWords.length > 0 &&
                                    relatedWords.map((words, index) => (
                                        <div className='unselected' key={index}>{words.word}</div>
                                    ))
                                }
                            </div>
                            <div>
                                {/* <button className="default-btn" onClick={() => setGameState("CROSSWORD")}>Crossword</button> */}
                                <button className='continue-btn' onClick={() => {
                                    setIsRandom(true);
                                    history.push("around-me");
                                }}
                                >
                                    Continue
                                </button>
                                {/* <button
                                    className="default-btn"
                                    onClick={() => history.push("/dash-board")}
                                >
                                    Exit
                                </button> */}
                            </div>
                        </div>
                    </div>
                )
            case "CROSSWORD":
                return (
                    // requires input array
                    <Crossword detectedItems={detectionArray} />
                );
            case "NO_CAMERA":
                return (
                    <div className="App-header">
                        <h1 className="text-center">Sorry We Couldn't Detect A Camera On Your Device</h1>
                        <h3 className="text-center">To Resolve this issue</h3>
                        <ul className="container text-center">
                            <ul>Check your browsers' camera accessibility</ul>
                            <ul>Check phone settings to check if your browser has been given access</ul>
                            <ul>If none of the above work, then clear cookies on your browser, or try using a different browser</ul>
                        </ul>
                    </div>
                );
            default:
                break;
        }
    }

    return (
        <div className="App">
            {StateOfGame()}
        </div>
    )
}