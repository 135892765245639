import React, { Component } from 'react';
import Webcam from 'react-webcam';
// import { WordModal } from '../../components/WordModal';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ImageContent from '../../components/ImageContent';
import Loading from '../../components/Loading';
import NewLabel from '../../components/NewLabel';
import { toast } from 'react-toastify';

class PictureMode extends Component {
    constructor() {
        super();
        this.cameraRef = React.createRef();

        this.state = {
            cameraIsOn: true,
            videoConstraints: {
                facingMode: "environment"
            },
            error: null,
            // screenWidth: 0,
            // screenHeight: 0,
            image: "",
            pictureTaken: false,
            picture: "",
            imageID: 0,
            labels: [],
            showNewLabel: false,
            newLabelData: { label: null }
        }
    }

    async componentDidMount() {
        // this.setState({ screenWidth: window.screen.width, screenHeight: window.screen.height })
        this.fetchImage()
            .then((data) => { this.setState({ image: data.picture }); })
            .catch((err) => console.error(err));
    }

    setLabels = (newLabels) => this.setState({ labels: newLabels });

    async fetchImage() {
        try {
            const response = await fetch("api/last-image", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                }
            });
            const parseResponse = await response.json();
            return parseResponse;
        } catch (error) {
            console.error(error);
        }
    }

    async handleNewLabel(newLabel, imageId) {
        // console.log(`sending ${newLabel} for image ${imageId}`);
   }

    handlePhoto() {
        const imageSrc = this.cameraRef.current.getScreenshot();
        if (imageSrc === null) return; // Image can be null if the user takes a photo before the camera loads or doesn't have one

        this.setState({
            pictureTaken: true,
            cameraIsOn: false,
            picture: imageSrc,
        }, async () => {
            try {
                const response = await fetch(`api/image`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json", token: localStorage.token },
                    body: JSON.stringify({ dataUri: this.state.picture })
                });
                const data = await response.json();
                this.setState({
                    labels: data[0].labels,
                    imageID: data[0].data_id
                });
            } catch (error) {
                console.error(error);
            }
        });
    }

    render() {
        const { pictureTaken, picture, cameraIsOn, videoConstraints, labels, imageID } = this.state;

        return (
            <div className='picture-mode-container' style={{ overflow: pictureTaken ? 'scroll' : 'hidden' }}>
                <div className={!pictureTaken ? 'camera-container' : 'image-container'}>
                    {cameraIsOn && // render camera
                        <>
                            <Webcam
                                className="webcam"
                                audio={false}
                                ref={this.cameraRef}
                                width={window.screen.width}
                                screenshotFormat="image/png"
                                videoConstraints={videoConstraints}
                                onUserMediaError={() =>
                                    this.setState({ cameraIsOn: false, error: true },
                                        () => {
                                            console.log("Unable to find camera on your device");
                                            toast.warning("Unable to find camera on your device");
                                            this.props.history.push('/');
                                        })
                                }
                            >
                                {/* {({ getScreenshot }) => {
                                <button onClick={() => {
                                    const imageSrc = getScreenshot();
                                    console.log("Picture Taken:", imageSrc);
                                    this.setState({ cameraIsOn : false });
                                }}>Capture Photo</button>
                            }} */}
                            </Webcam>
                            <div className='camera-nav'>
                                <i
                                    className="fas fa-sync-alt camera-nav-option"
                                    onClick={() => {
                                        if (videoConstraints.facingMode === "environment") this.setState({ videoConstraints: { facingMode: "user" } })
                                        else this.setState({ videoConstraints: { facingMode: "environment" } })
                                    }}
                                >
                                </i>
                                <div className='camera-outer-ring'>
                                    <button
                                        className='camera-inner-ring'
                                        onClick={() => this.handlePhoto()}
                                    >
                                    </button>
                                </div>
                                <div className='gallery' onClick={() => this.props.history.push("/my-memories")}>
                                    <img src={this.state.image} alt="" />
                                </div>
                            </div>
                        </>
                    }
                    {(pictureTaken && labels.length > 0) &&
                        <div className='image-content'>
                            <ImageContent
                                picture={picture}
                                labels={labels}
                                setLabels={this.setLabels}
                                imageID={imageID}
                                newLabelData={this.state.newLabelData}
                            />
                            <div className='image-info'>
                                <div className='image-nav'>
                                    {/* <div 
                                    className='back'
                                        onClick={() => this.setState({ cameraIsOn: true, pictureTaken: false })}
                                    >
                                        <i className="fas fa-undo"></i>
                                        <div className='take-another'>Retake</div>
                                    </div> */}
                                    <div
                                        className='back'
                                        onClick={() => this.setState({ cameraIsOn: true, pictureTaken: false, picture: "", labels: [] })}
                                    >
                                        <i className="fas fa-camera"></i>
                                        <div className='take-another'>{<Translation>{(t) => t('takeAnother')}</Translation>}</div>
                                    </div>
                                    <div
                                        className='back'
                                        onClick={() => this.props.history.push("/my-memories")}
                                    >
                                        <i className="fas fa-image"></i>
                                        <div className='take-another'>{<Translation>{(t) => t('memories')}</Translation>}</div>
                                    </div>
                                    <div 
                                        className='back'
                                        onClick={() => {
                                            this.setState({ showNewLabel: true });
                                        }}
                                    >
                                        <i class="fas fa-solid fa-plus"></i>
                                        <div className='take-another'>New label</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {!cameraIsOn && labels.length === 0 && <Loading />}
                </div>
                {
                    this.state.showNewLabel &&
                    <NewLabel 
                        imageId={imageID}
                        handleNewLabel={(label) => this.handleNewLabel(label, imageID)}
                        closeAreYouSure={() => this.setState({ showNewLabel: false })}
                        setNewLabelData={(data) => this.setState({ newLabelData: { label: data.label } })}
                    />
                }
            </div>
        )
    }
}

export default withRouter(PictureMode);

 /* <WordModal
canModify={true}
show={this.state.show}
handleClose={this.hideModal}
changeToPrimary={() => this.changeWordToPrimary(selectedWord)}
>
<h1>Picture Settings</h1>
<button
style={{ backgroundColor: this.state.editMode ? "green" : "#333" }}
onClick={() => {
this.setState({ editMode: !this.state.editMode }, () => console.log("Edit mode is on:", this.state.editMode));
}}
>
Edit Mode
</button>
</WordModal>
<div className='mode-selection-settings' onClick={() => this.showModal()}>
<i className="fas fa-cog"></i>
</div>

OLD ONCLICK 
// let children = e.target.parentNode.childNodes;

for (let i = 0; i < children.length; i++) {
if (children[i].classList.contains("selected")) children[i].classList.remove("selected");
}

e.target.className += ' selected';
*/