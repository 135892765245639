import React, { useRef, useState, useEffect, useCallback } from 'react';
// import * as tf from '@tensorflow/tfjs';
import * as cocossd from "@tensorflow-models/coco-ssd";

import Webcam from 'react-webcam';

import { drawRect } from '../../helper/utilities';
import { toast } from 'react-toastify';

const VIDEO_CONSTRAINTS = {
    audio: false,
    facingMode: "environment"
}

export function TFCamera(props) {
    
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    const [detected, setDetected] = useState(false);
    const [image, setImage] = useState("");
    
    const [pictureTaken, setPictureTaken] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [example, setExample] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [definition, setDefinition] = useState("");

    const runCoco = async () => {
        const net = await cocossd.load();

        setInterval(() => {
            detect(net);
        }, 1000);
    }

    
    const capture = useCallback(() => {
        if (webcamRef !== null) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc);
        }
    }, [webcamRef]);
    
    const detect = async (net) => {
        if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
            // Get Video Properties
            const video = webcamRef.current.video;
            const videoWidth = webcamRef.current.video.videoWidth;
            const videoHeight = webcamRef.current.video.videoHeight;

            // Set Video Width
            webcamRef.current.video.width = videoWidth;
            webcamRef.current.video.height = videoHeight;

            // Set Canvas Height And Width
            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;

            // 4. TODO - Make Detections
            const obj = await net.detect(video);

            // Make sure tensorflow is always detecting something
            if (obj.length > 0) {
                console.log(obj);
                // TODO if the object the user wants to detect is detected by the camera
                if (obj[0].class === props.wordToDetect) {
                    // stop the detections
                    if (pictureTaken) {
                        capture();
                    }
                    capture();
                    setTimeout(() => {
                        setPictureTaken(true);
                        setDetected(true);
                    }, 2000);
                    
                    // TODO then tell the user the item has been found
                    
                    // TODO then fetch information about the item (wordhippo)

                    // TODO Game loop ends and then give option to exit or try again
                }
            }
            // draw mesh
            const ctx = canvasRef.current.getContext("2d");
            
            // TODO - Update drawing utility
            drawRect(obj, ctx);
        }
    }
    
    useEffect(() => {
        async function getWordInfo() {
            try {
                const response = await fetch(`word/word-info?word=${props.wordToDetect}`, {
                    method: "GET",
                    headers: {
                        token: localStorage.token
                    }
                })
    
                const parseResponse = await response.json();
                console.log(parseResponse);
                setExample(parseResponse.example);
                setDefinition(parseResponse.definition);
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        }
        getWordInfo();
    }, [props.wordToDetect])

    useEffect(() => {
        runCoco();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detected]);

    useEffect(() => {
        localStorage.setItem("whichUrl", "/tensor");
    }, []);

    return (
        <div className="App">
            <div className="App-header">
                <div className="camera-container">
                    { !pictureTaken && <Webcam audio={ false } ref={ webcamRef } muted={ true }  className="webcam" videoConstraints={ VIDEO_CONSTRAINTS } screenshotFormat="image/png" screenshotQuality={ 1 } /> }
                    <canvas ref={ canvasRef } className="webcam-c" />
                    { 
                        detected && 
                        (
                            <div className='p-modal-container'>
                                <div className={ `p-modal` }>
                                    <div className="p-modal-header">
                                        <h2>You found it!</h2>
                                        {/* <h3>Here is some information about this word:</h3> */}
                                        <div>
                                            <p>Word: </p>
                                            <p>{ props.wordToDetect }</p>
                                        </div>
                                        {/* <div>
                                            <p>Definition: </p>
                                            <p>{ definition }</p> 
                                        </div>
                                        <div>
                                            <p>Example: </p>
                                            <p>{ example }</p>
                                        </div> */}
                                        { image && <img className="detected-img" src={ image } alt={ props.wordToDetect }  /> }
                                    </div>
                                    <div className="p-modal-footer">
                                        <button onClick={() => props.resetFunction()}>Select Different Item?</button>
                                        <button onClick={ () => {
                                            props.myFunction()
                                            setPictureTaken(false)
                                            setDetected(false)
                                            // setShow(!show)
                                        } }>Finish</button>
                                    </div>
                                </div>
                            </div>
                            
                        )
                    }
                </div>
            </div>
        </div>
    )
}