import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let fallbackData = {
    en: {
      translation: {
            pictureMode: "Picture Mode",
            memories: "Memories",
            scanFind: "Scan & Learn",
            reviewMemories: "Review Memories",
            achievements: "Achievements",
            settings: "Settings",
            questionAmount: "Question Amount",
            questionInfo: "How many words do you want to be tested on in Review Memories?",
            randomizer: "Randomizer",
            randomizerInfo: "Toggle between a randomizer for Eye Mode.",
            selectedLanguage: "Change selected Language",
            selectedLanguageInfo: "Change the language that you are comfortable with.",
            targetLanguage: "Change target language",
            targetLanguageInfo: "Change the language that you are trying to learn.",
            eyeModeInstructions: 'Scan & Learn instructions',
            eyeModeInstructionsInfo: 'Stand or sit in one spot and scan the room around you with your camera',
            eyeModeInstructionsInfo2: 'Slowly scan for 10 seconds',
            eyeModeInstructionsInfo3: 'Then you will be prompted by one of many activities based on the scan of the room you did',
            activity1: 'Find it around you',
            activity2: 'Find the imposter item',
            activity3: 'What is this object?',
            activity4: 'Where was this object?',
            activity5: 'What does this describe?',
            back: 'Back',
            pickForMe: 'Pick for me',
            skip: 'Skip',
            next: 'next',
            check: 'Check',
            showList: 'Show list',
            takeAnother: 'Take another',
            definition: 'Definition',
            example: 'Example',
            finish: 'Finish',
            quiz1: 'Which image has ',
            quiz2: 'Found in ',
            quiz3: 'Which picture has a word that fills in the blank',
            quiz4: 'Fill in the blank with the words you just reviewed',
            phase: 'Phase',
            results: 'Results',
            attempt: 'attempt',
            attempts: 'attempts',
            correct: 'Correct',
            incorrect: 'Incorrect',
            saving: 'Saving Results...',
            clue: 'Clue',
            englishExample: 'English example',
            showDefinition: 'Show definition',
            showHint: 'Show hint',
            feedback: 'Feedback',
            scanComplete: 'Scan complete',
            scanInfo: 'You scanned your room, nice. Please wait while we construct activities based on your scan',
            startActivities: 'Start activities',
            startActivity: 'Start Activity',
            timeRemaining: 'Time remaining',
            findObj: 'Find Object',
            activity1Title: 'Explore & Identify',
            activity1Info: 'Find',
            activity1Info2: 'near you',
            activity1Info3: 'Nice work! You found',
            activity1Info4: "Save the image to learn more about what's in this picture",
            activity1Info5: 'Explore your world! Take a scan, and find',
            activity1Info6: 'which is around you, based on your initial scan',
            save: 'Save',
            saveImage: 'Save image',
            nextActivity: 'Next Activity',
            continue: 'Continue',
            activity2Title: 'Imposter Item',
            activity2Info: "Play detective! Snap a scan, find the 'imposter' item that was not around you in your scan. Test your skills!",
            activity2Info1: 'You guessed',
            activity2Info2: 'Related Item to that was',
            activity2Info3: 'Select the item that you think was not present in the scan you made',
            exampleSentence: 'Example sentence',
            redo: 'Redo',
            redoScan: 'Redo scan',
            exit: 'Exit',
            showOurAnswer: 'Show our answer',
            activity3Title: 'What is this',
            activity3Info: 'Most relavent object in your scan',
            activity3Info2: "Boost your vocabulary with this mode! With you surroundings scanned, we'll show you an image to identify. Guess the word for the image and learn as you play!",
            activity3Info3: 'What object in is this picture?',
            info: 'Info',
            newActivity: 'New activity',
            activity4Title: 'Discover',
            activity4Info: 'Which object would you like to find?',
            activity4Info1: "Click 'Info' button on the image to view all detections on current image. Swipe left or right to traverse through sections of your scan.",
            activity4Info2: 'Items in this image',
            activity4Info3: 'Select any object from the list retrieved from your scan and view the object information in depth and find where it was?',
            close: 'Close',
            activity5Info: 'Select the object that best describes from the set of images presented the word:',
            activity5Info1: 'Find the object that best describes',
            activity5Info2: 'Find the object for the action word shown on the next page',
            help: 'Help',
            swipe: 'Swipe',
            left: 'left',
            or: 'or',
            right: 'right',
            basedOn: 'based and select the',
            basedOn2: 'button to see if your guess that the answer to the hint is in this picture',
            newHint: 'Try new hint'
        }
    },
    fr: {
      translation: {
            pictureMode: "Mode image",
            memories: "Souvenirs",
            scanFind: "Numériser et trouver",
            reviewMemories: "Revoir les souvenirs",
            achievements: "Réalisations",
            settings: "Paramètres",
            questionAmount: "Montant des questions",
            questionInfo: "Sur combien de mots voulez-vous être testé dans Review Memories ?",
            randomizer: "Aléatoire",
            randomizerInfo: "Basculer entre un randomiseur pour le mode œil.",
            selectedLanguage: "Modifier la langue sélectionnée",
            selectedLanguageInfo: "Changez la langue avec laquelle vous êtes à l'aise.",
            targetLanguage: "Changer la langue cible",
            targetLanguageInfo: "Changez la langue que vous essayez d'apprendre.",
            eyeModeInstructions: "Instructions de numérisation et d'apprentissage",
            eyeModeInstructionsInfo: "Tenez-vous debout ou asseyez-vous au même endroit et scannez la pièce autour de vous avec votre appareil photo",
            eyeModeInstructionsInfo2: "Scannez lentement pendant 10 secondes",
            eyeModeInstructionsInfo3: "Ensuite, vous serez invité par l'une des nombreuses activités basées sur le scan de la pièce que vous avez fait",
            activity1: "Trouvez-le autour de vous",
            activity2: "Trouver l'objet imposteur",
            activity3: "Quel est cet objet?",
            activity4: "Où était cet objet?",
            activity5: "Qu'est-ce que cela décrit?",
            back: "Dos",
            pickForMe: "Sélecteur pour moi",
            skip: "Sauter",
            next: "suivant",
            check: 'vérifier',
            showList: "Afficher la liste",
            takeAnother: 'Prends un autre',
            definition: 'Définition',
            example: 'Exemple',
            finish: 'Finir',
            quiz1: 'quelle image a ',
            quiz2: 'Trouvé en ',
            quiz3: 'Quelle image a un mot qui remplit le blanc',
            quiz4: 'Remplissez le blanc avec les mots que vous venez de revoir',
            phase: 'Phase',
            results: 'Résultats',
            attempt: 'tentative',
            attempts: 'tentatives',
            correct: 'Correct',
            incorrect: 'Incorrect',
            saving: 'Enregistrement des résultats...',
            clue: 'Indice',
            englishExample: 'Exemple anglais',
            showDefinition: 'Afficher la définition',
            showHint: 'Dévoiler un indice',
            feedback: 'Retour',
            scanComplete: 'Numérisation terminée',
            scanInfo: 'Vous avez scanné votre chambre, sympa. Veuillez patienter pendant que nous construisons des activités basées sur votre analyse',
            startActivities: 'Démarrer les activités',
            timeRemaining: 'Temps restant',
            findObj: "Rechercher un objet",
            activity1Title: 'Explorer & Identifier',
            activity1Info: "Trouver",
            activity1Info2: "près de toi",
            activity1Info3: "Bon travail! Tu as trouvé",
            activity1Info4: "Enregistrez l'image pour en savoir plus sur le contenu de cette image",
            activity1Info5: "Explorez votre monde ! Faites un scan et trouvez",
            activity1Info6: 'qui se trouve autour de vous, en fonction de votre analyse initiale',
            save: "Sauvegarder",
            saveImage: "Enregistrer l'image",
            nextActivity: "Activité suivante",
            continue: "Continuer",
            activity2Title: "Article d'imposteur",
            activity2Info: "Jouez au détective ! Faites un scan, trouvez l'élément « imposteur » qui n'était pas autour de vous dans votre scan. Testez vos compétences!",
            activity2Info1: "Tu as deviné",
            activity2Info2: "Article connexe à qui était",
            activity2Info3: "Sélectionnez l'élément qui, selon vous, n'était pas présent dans l'analyse que vous avez effectuée.",
            exampleSentence: "Exemple de phrase",
            redo: "Refaire",
            redoScan: "Refaire l'analyse",
            exit: "Sortie",
            showOurAnswer: "Montrez notre réponse",
            activity3Title: "Qu'est-ce que c'est",
            activity3Info: "Objet le plus pertinent dans votre scan",
            activity3Info2: "Boostez votre vocabulaire avec ce mode ! Avec votre environnement scanné, nous vous montrerons une image à identifier. Devinez le mot correspondant à l'image et apprenez en jouant !",
            activity3Info3: 'Dans quel objet se trouve cette image?',
            info: "Info",
            newActivity: "Nouvelle activité",
            activity4Title: 'Découvrir',
            activity4Info: "Quel objet aimerais-tu trouver ?",
            activity4Info1: "Cliquez sur le bouton \"Info\" sur l'image pour afficher toutes les détections sur l'image actuelle. Balayez vers la gauche ou la droite pour parcourir les sections de votre numérisation.",
            activity4Info2: "Articles dans cette image",
            activity4Info3: "Sélectionnez n'importe quel objet dans la liste extraite de votre analyse et affichez les informations sur l'objet en profondeur et trouvez où il se trouvait?",
            close: "Fermer",
            activity5Info: "Sélectionnez l'objet qui décrit le mieux parmi l'ensemble d'images présenté le mot:",
            activity5Info1: "Trouvez l'objet qui décrit le mieux",
            activity5Info2: "Trouvez l'objet pour le mot d'action indiqué sur la page suivante",
            help: "Aider",
            swipe: "Glisser",
            left: "gauche",
            or: "ou",
            right: "droite",
            basedOn: "base et sélectionnez le",
            basedOn2: "bouton pour voir si vous devinez que la réponse à l'indice est dans cette image",
            newHint: "Essayez un nouvel indice"
        }
    },
    es: {
        translation: {
            pictureMode: "Modo de imagen",
            memories: "Recuerdos",
            scanFind: "Escanear y encontrar",
            reviewMemories: "Revisar recuerdos",
            achievements: "Logros",
            settings: "Ajustes",
            questionAmount: "Importe de la pregunta",
            questionInfo: "¿Cuántas palabras quieres que te evalúen en Review Memories?",
            randomizer: "Aleatorizador",
            randomizerInfo: "Alterne entre un aleatorizador para el modo ojo.",
            selectedLanguage: "Cambiar idioma seleccionado",
            selectedLanguageInfo: "Cambia el idioma con el que te sientas cómodo.",
            targetLanguage: "Cambiar el idioma de destino",
            targetLanguageInfo: "Cambia el idioma que estás tratando de aprender.",
            eyeModeInstructions: "Instrucciones de escaneo y aprendizaje",
            eyeModeInstructionsInfo: "Párese o siéntese en un lugar y escanee la habitación a su alrededor con su cámara",
            eyeModeInstructionsInfo2: "Escanea lentamente durante 10 segundos",
            eyeModeInstructionsInfo3: "Luego, se le indicará una de las muchas actividades basadas en el escaneo de la habitación que hizo.",
            activity1: "Encuéntralo a tu alrededor",
            activity2: "Encuentra el objeto impostor",
            activity3: "¿Qué es este objeto?",
            activity4: "¿Dónde estaba este objeto?",
            activity5: "¿Qué describe esto?",
            back: "Atrás",
            pickForMe: "recolector para mi",
            skip: "Saltar",
            next: "próximo",
            check: 'controlar',
            showList: "Mostrar lista",
            takeAnother: "Toma otro",
            definition: "Definición",
            example: "Ejemplo",
            finish: 'Finalizar',
            quiz1: 'que imagen tiene ',
            quiz2: 'Encontrado en ',
            quiz3: '¿Qué imagen tiene una palabra que llena el espacio en blanco?',
            quiz4: 'Complete el espacio en blanco con las palabras que acaba de revisar',
            phase: 'Fase',
            results: 'Resultados',
            attempt: 'intentar',
            attempts: 'intentos',
            correct: 'Correcto',
            incorrect: 'Incorrecto',
            saving: 'Guardando resultados...',
            clue: 'Clave',
            englishExample: 'ejemplo en ingles',
            showDefinition: 'Mostrar definición',
            showHint: 'Mostrar pista',
            feedback: 'Comentario',
            scanComplete: 'Escaneo completo',
            scanInfo: 'Escaneaste tu habitación, lindo. Espere mientras construimos actividades basadas en su escaneo',
            startActivities: 'Iniciar actividades',
            timeRemaining: 'tiempo restante',
            findObj: "Buscar objeto",
            activity1Title: 'Explorar e identificar',
            activity1Info: "Encontrar",
            activity1Info2: "cerca de usted",
            activity1Info3: "¡Buen trabajo! Encontraste",
            activity1Info4: "Guarde la imagen para obtener más información sobre lo que hay en esta imagen.",
            activity1Info5: '¡Explora tu mundo! Tome un escaneo y encuentre',
            activity1Info6: 'que está a tu alrededor, según tu exploración inicial',
            save: "Ahorrar",
            saveImage: "Guardar imagen",
            nextActivity: "Siguiente actividad",
            continue: "Continuar",
            activity2Title: "Artículo impostor",
            activity2Info: "¡Juega a ser detective! Haga un escaneo, encuentre el 'elemento impostor que no estaba a su alrededor en su escaneo. ¡Pon a prueba tus habilidades!",
            activity2Info1: "Acertó",
            activity2Info2: "Artículo relacionado con eso fue",
            activity2Info3: "Seleccione el elemento que cree que no estaba presente en el escaneo que realizó.",
            exampleSentence: "Oración de ejemplo",
            redo: "Rehacer",
            redoScan: "Rehacer escaneo",
            exit: "Salida",
            showOurAnswer: "Mostrar nuestra respuesta",
            activity3Title: "Qué es esto",
            activity3Info: "Objeto más relevante en su escaneo",
            activity3Info2: "¡Mejora tu vocabulario con este modo! Con su entorno escaneado, le mostraremos una imagen para identificar. ¡Adivina la palabra de la imagen y aprende mientras juegas!",
            activity3Info3: '¿En qué objeto está esta imagen?',
            info: "Información",
            newActivity: "Nueva actividad",
            activity4Title: 'Descubrir',
            activity4Info: "¿Qué objeto te gustaría encontrar?",
            activity4Info1: "Haga clic en el botón 'Info' en la imagen para ver todas las detecciones en la imagen actual. Deslice el dedo hacia la izquierda o hacia la derecha para recorrer las secciones de su escaneo.",
            activity4Info2: "Elementos en esta imagen",
            activity4Info3: 'Seleccione cualquier objeto de la lista recuperada de su escaneo y vea la información del objeto en profundidad y encuentre dónde estaba.',
            close: "Cerca",
            activity5Info: "Seleccione el objeto que mejor describa del conjunto de imágenes presentadas la palabra:",
            activity5Info1: "Encuentra el objeto que mejor describe",
            activity5Info2: "Encuentre el objeto de la palabra de acción que se muestra en la página siguiente",
            help: "Ayuda",
            swipe: "Golpe fuerte",
            left: "izquierda",
            or: "o",
            right: "bien",
            basedOn: "basado y seleccione el",
            basedOn2: "botón para ver si adivinas que la respuesta a la pista está en esta imagen",
            newHint: "Prueba una nueva pista"
        }
    },
    pt: {
        translation: {
            pictureMode: "Modo de imagem",
            memories: "Recordações",
            scanFind: "Escanear e aprender",
            reviewMemories: "Rever Memórias",
            achievements: "Conquistas",
            settings: "Configurações",
            questionAmount: "Valor da pergunta",
            questionInfo: "Com quantas palavras você quer ser testado nas memórias de revisão?",
            randomizer: "randomizador",
            randomizerInfo: "Alternar entre um randomizador para o modo Eye.",
            selectedLanguage: "Alterar idioma selecionado",
            selectedLanguageInfo: "Altere o idioma com o qual você se sente confortável.",
            targetLanguage: "Alterar idioma de destino",
            targetLanguageInfo: "Altere o idioma que você está tentando aprender.",
            eyeModeInstructions: "Instruções de digitalização e aprendizado",
            eyeModeInstructionsInfo: "Fique de pé ou sente-se em um local e escaneie a sala ao seu redor com sua câmera",
            eyeModeInstructionsInfo2: "Digitalize lentamente por 10 segundos",
            eyeModeInstructionsInfo3: "Em seguida, você será solicitado por uma das muitas atividades com base na varredura da sala que você fez",
            activity1: "Encontre-o ao seu redor",
            activity2: "Encontre o item impostor",
            activity3: "O que é este objeto?",
            activity4: "Onde estava esse objeto?",
            activity5: "O que isso descreve?",
            back: "Voltar",
            pickForMe: "Escolha para mim",
            skip: "Pular",
            next: "próximo",
            check: "Verificar",
            showList: "Mostrar lista",
            takeAnother: "pegue outro",
            definition: "Definição",
            example: "Exemplo",
            finish: "Terminar",
            quiz1: "qual imagem tem",
            quiz2: "Encontrado em",
            quiz3: "Qual imagem tem uma palavra que preenche o espaço em branco",
            quiz4: "Preencha o espaço em branco com as palavras que você acabou de revisar",
            phase: "Estágio",
            results: "Resultados",
            attempt: "tentar",
            attempts: "tentativas",
            correct: "Correto",
            incorrect: "Incorreta",
            saving: "Salvando resultados...",
            clue: "Dica",
            englishExample: "exemplo em inglês",
            showDefinition: "Mostrar definição",
            showHint: "Mostrar dica",
            feedback: "Opinião",
            scanComplete: "Digitalização concluída",
            scanInfo: "Você examinou seu quarto, legal. Aguarde enquanto construímos atividades com base em sua varredura",
            startActivities: "Iniciar atividades",
            startActivity: "Iniciar Atividade",
            timeRemaining: "Tempo restante",
            findObj: "Localizar objeto",
            activity1Title: "Explorar e identificar",
            activity1Info: "Encontrar",
            activity1Info2: "perto de você",
            activity1Info3: "Bom trabalho! Você encontrou",
            activity1Info4: "Salve a imagem para saber mais sobre o que há nesta imagem",
            activity1Info5: "Explore o seu mundo! Faça uma varredura e encontre",
            activity1Info6: "que está ao seu redor, com base em sua varredura inicial",
            save: "Salvar",
            saveImage: "Salvar imagem",
            nextActivity: "Próxima atividade",
            continue: "Continuar",
            activity2Title: "Item impostor",
            activity2Info: "Brinque de detetive! Faça uma varredura, encontre o item 'impostor' que não estava ao seu redor em sua varredura. Teste suas habilidades!",
            activity2Info1: "Você adivinhou",
            activity2Info2: "Item relacionado a isso foi",
            activity2Info3: "Selecione o item que você acha que não estava presente na digitalização que você fez",
            exampleSentence: "Frase de exemplo",
            redo: "refazer",
            redoScan: "Refazer verificação",
            exit: "Saída",
            showOurAnswer: "Mostrar nossa resposta",
            activity3Title: "O que é isso",
            activity3Info: "Objeto mais relevante em sua varredura",
            activity3Info2: "Aumente seu vocabulário com este modo! Com seus arredores digitalizados, mostraremos uma imagem para identificar. Adivinhe a palavra da imagem e aprenda enquanto joga!",
            activity3Info3: "Em que objeto está esta imagem?",
            info: "Informações",
            newActivity: "Nova atividade",
            activity4Title: "Descobrir",
            activity4Info: "Qual objeto você gostaria de encontrar?",
            activity4Info1: "Clique no botão 'Informações' na imagem para visualizar todas as detecções na imagem atual. Deslize para a esquerda ou para a direita para percorrer as seções de sua digitalização.",
            activity4Info2: "Itens nesta imagem",
            activity4Info3: "Selecione qualquer objeto da lista recuperada de sua varredura e visualize as informações do objeto em profundidade e descubra onde ele estava?",
            close: "Fechar",
            activity5Info: "Selecione o objeto que melhor descreve do conjunto de imagens apresentadas a palavra:",
            activity5Info1: "Encontre o objeto que melhor descreve",
            activity5Info2: "Encontre o objeto para a palavra de ação mostrada na próxima página",
            help: "Ajuda",
            swipe: "Deslize",
            left: "esquerda",
            or: "ou",
            right: "certo",
            basedOn: "com base e selecione o",
            basedOn2: "botão para ver se você acha que a resposta para a dica está nesta imagem",
            newHint: "Tente nova dica"
        }
    }
};

async function fetchDefaultLanguage() {
    try {
        const response = await fetch('auth/is-verify', {
            method: "GET",
            headers: { token: localStorage.token }
        });
        const data = await response.json();
        return data.selected_language;
    } catch  (error) {
        console.error('Error fetching default language:', error);
        return 'en';
    }
}

async function fetchStaticTranslations() {
    try {
        const response = await fetch('translation/static-translation', {
            method: "GET",
            headers: { token: localStorage.token }
        });
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching static translations:', error);
        return fallbackData;
    }
}

// Initialize i18n dynamically with the retrieved default language
async function initializeI18n() {
    const defaultLanguage = await fetchDefaultLanguage();
    // console.log('default language', defaultLanguage);
    // const translationMaps = await fetchStaticTranslations();

    i18n.use(initReactI18next).init({
        lng: defaultLanguage,
        fallbackLng: "en",
        resources: fallbackData
    })
}

// run i18n
initializeI18n();