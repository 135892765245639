import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { LoginContext } from '../../helper/LoginContext';

export function DeleteUser() {
    const { logout } = useContext(LoginContext);
    async function deleteUser(e) {
        try {
            const response = await fetch('auth/delete-user', {
                method: "DELETE",
                headers: { token: localStorage.getItem("token") }
            });
            const parseResponse = await response.json();
            if (parseResponse) logout(e);
            console.log(parseResponse);
        } catch (error) {
            console.error(error.message);
            toast.error("Oops! Looks Like Something Went Wrong");
        }
    }

    return (
        <>
            <h1 className='section-header'>Delete Account</h1>
            <div className="DeleteUser">
                <h2>Are you sure you want to <span className='delete'>delete your account</span>? After clicking the delete button all your LangEye information will be deleted.</h2>
                <div className="card-container">
                    <div
                        className="menu-card delete-button"
                        onClick={(e) => deleteUser(e)}
                    >
                        <h2 className="card-title">Delete Account</h2>
                    </div>
                </div>
            </div>
        </>
    )
}