import React, { Component } from 'react';
import { Translation as I18Translation } from 'react-i18next';
import Speech from "react-speech";
import { toast } from 'react-toastify';
import AboutCard from '../../components/AboutCard';
import ImageContent from '../../components/ImageContent';
import Loading from '../../components/Loading';
import Translation from '../../components/Translation';
import { getRandomIndex } from '../../helper/randomizer';
import image3 from '../../images/img3.png';

// activity 3; user is shown an image and have to guess which item is that image
export class Activity3 extends Component {
    constructor() {
        super();
        
        this.canvasRef = React.createRef();

        this.state = {
            show: true,
            mode: 0,
            pictureArray: [],
            detectionArray: [],
            guess: '',
            picture: '',
            detections: [],
            showHint: false,
            imageData: [],
            highestDetectionScore: [],
            sentence: '',
            definition: '',
            labels: [],
            imageID: 0,
            showInfo: false,
            isCorrect: null,
            answer: ''
        }
    }

    componentDidMount() {
        console.log("Activity 3 mounted");
        console.log("Activity 3:", this.props.pictureArray, this.props.detectionArray);
        this.setState({ pictureArray: this.props.pictureArray, detectionArray: this.props.detectionArray }, () => {
            // get random index from picture array
            let randomIndex = getRandomIndex(this.state.pictureArray.length);

            // get randomly selected picture from index
            let picture = this.state.pictureArray[randomIndex];

            // get randomly selected detection array based on selected picture
            let detections = this.state.detectionArray[randomIndex];

            // lowercase all detections
            detections = detections.map(item => item.toLowerCase());

            // set picture to the randomly selected one
            this.setState({ picture: picture });
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mode !== prevState.mode) {
            if (this.state.mode === 1) {
                this.handleGetImageLocalization(this.state.picture).then(data => {
                    if (data.length < 1) {
                        toast.error("No meaningful detections were made, please try again.")
                        return;
                    }

                    // store all item names
                    let detections = data.map(item => item.name);

                    // store object with highest score to show final results for
                    const maxScore = data.reduce((a, b) => a.score > b.score ? a: b);          
                                        
                    this.setState({ imageData: [...data], detections: detections, highestDetectionScore: maxScore }, () => {
                        this.wordInfo(this.state.highestDetectionScore.name).then(data => {
                            this.setState({
                                definition: data.definition === undefined ? "Unable to find a definition" : data.definition,
                                example: data.example === undefined ? "Could not find example sentence" : data.example 
                            })
                        })
                    });
                });
            }

            if (this.state.mode === 2) {
                this.wordInfo(this.state.highestDetectionScore.name).then(data => {
                    console.log(data);
                    if (data.definition) {
                        this.setState({ definition: data.definition });
                    } else {
                        this.setState({ definition: 'Could not find definition, try again later or try scanning in a different location' })
                    }
                    if (data.example) {
                        this.setState({ sentence: data.example });
                    } else {
                        this.setState({ sentence: 'Could not find an example sentence, try again later or try scanning in a different location' })
                    }
                })
            }
        }

        // invoked when user clicks 'showHint'
        if (this.state.showHint !== prevState.showHint) {
            if (this.state.showHint) {
                // let canvasContext = this.canvasRef.current.getContext('2d');
                // this.canvasRef.current.style.width = '100%';
                // this.canvasRef.current.style.height = '100%';
                // // remove duplicates from list
                // let filteredData = this.state.imageData.filter((item, pos) => this.state.imageData.findIndex(v2 => (item.name === v2.name)) === pos);
                // console.log(filteredData);
                // Utility_DrawRect(filteredData, canvasContext);
            }
        }

        // mainly for the guessing mode itself to see the answer and the definition of the word
        if (this.state.isCorrect !== prevState.isCorrect) {
            if (this.state.isCorrect) {
                this.wordInfo(this.state.answer).then(data => {
                    console.log(data);
                    if (data.definition) {
                        this.setState({ definition: data.definition });
                    } else {
                        this.setState({ definition: 'Could not find definition, try again later or try scanning in a different location' })
                    }
                    if (data.example) {
                        this.setState({ sentence: data.example });
                    } else {
                        this.setState({ sentence: 'Could not find an example sentence, try again later or try scanning in a different location' })
                    }
                })
            }
        }
    }

    // get objects in a given picture
    handleGetImageLocalization = async (picture) => {
        try {
            let dataUri = picture;
            let body = { dataUri };
            const response = await fetch(`api/bound-image`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.token
                },
                body: JSON.stringify(body)
            });
            const data = await response.json();

            return data;
        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    }


    translate = async (text) => {
        try {
            let body = { text };
            const response = await fetch(`translation/translate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                },
                body: JSON.stringify(body)
            });
            const data = await response.json();
          //   console.log("api called");
            return data;
        } catch (error) {
            console.error(error);
        }
    }


    componentWillUnmount() {
        console.log("Activity 3 Unmounted");
    }

    handleChange = (e) => {
        this.setState({ guess: e.target.value });
    }

    // fetch example sentence and definition guess and randomItem words
    async wordInfo(word) {        
        try {
            const response = await fetch(`word/word-info`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    token: localStorage.token
                },
                body: JSON.stringify({ label: word })
            });
            const data = await response.json();
            console.log('word', word);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    // handle saving the photo to get more information on it
    handlePhoto() {
        if (this.state.picture === null) return;

        this.setState({ pictureTaken: true }, async () => {
            try {
                const response = await fetch(`api/image`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        token: localStorage.token
                    },
                    body: JSON.stringify({ dataUri: this.state.picture })
                });
                const data = await response.json();

                console.log('data', data);

                this.setState({ 
                    labels: data[0].labels,
                    imageID: data[0].data_id
                });
            } catch (error) {
                console.error(error);
                toast.error(error);
            }
        })
    }

    findMatch = (item, array = []) => {
        this.translate(item).then(data => {
            if (item === null || array.length === 0) {
                toast.error("An unknown error occured, try the scan again");
            }
            // const match = array.find(item => item.toLowerCase().includes(item.toLowerCase()));
            const match = item.toLowerCase().trim() === data.translations[0].toLowerCase().trim()
            // const match2 = item.toLowerCase() === data[0].toLowerCase();
            console.log(item.toLowerCase(), data.translations[0]);
    
            console.log(item, array, match);
            // if (match.toLowerCase().includes(item.toLowerCase())) this.setState({ isCorrect: true, answer: match });
            if (match) this.setState({ isCorrect: true, answer: match });
            else this.setState({ isCorrect: false });
        });

    }

    setLabels = (newLabels) => this.setState({ labels: newLabels });

    render() {
        const speechStyle = { // some attributes are required even though they are not really used
            container: {},
            text: {},
            play: {
                hover: {
                    backgroundColor: 'transparent'
                },
                button: {
                    width: '34',
                    height: '34',
                    cursor: 'pointer',
                    pointerEvents: 'none',
                    outline: 'none',
                    backgroundColor: 'transparent',
                    border: 'none',
                }
            },
            stop: {
                button: {}
            },
            pause: {
                button: {}
            },
            resume: {
                button: {}
            }
        }
        return (
            <div>
                
                { this.state.mode === 0 ? 
                    <AboutCard
                        // cardTitle={ 'What is this?' }
                        // cardInstructions={ 'Type in the text box which item you think is shown in the image?' }
                        image={ image3 }
                        cardTitle={ <I18Translation>{(t) => t('activity3')}</I18Translation> }
                        cardInstructions={ <I18Translation>{(t) => t('activity3Info2')}</I18Translation> }
                        btnText={ <I18Translation>{(t) => t('startActivity')}</I18Translation> }
                        StateFunction={() => this.setState({ mode: 1 }) }           
                        cssClass="about-popup"            
                    />
                : null }
                { this.state.mode === 1 ? 
                    <div className='picture-mode-container'>
                        <div className='image-content'>
                        { this.state.showHint ? 
                            <div className='p-modal-container'>
                            {/* <canvas ref={ this.canvasRef } width='325' height='243' className="image-canvas" />  */}
                                <div className='p-modal'>
                                    <div className='p-modal-header'>
                                        <h1>
                                            <strong><I18Translation>{(t) => t('hint')}</I18Translation></strong>
                                        </h1>
                                    </div>
                                    <div className='p-modal-body'>
                                        "<i>{ this.state.definition }</i>"
                                    </div>
                                    <div className='p-modal-footer'>
                                        <button onClick={() => this.setState({ showHint: !this.state.showHint })}><I18Translation>{(t) => t('exit')}</I18Translation></button>
                                    </div>
                                </div>
                            </div>
                        : null }     
                            <div className='image-container'>
                                <h2><I18Translation>{(t) => t('activity3Info3')}</I18Translation></h2>
                                { this.state.isCorrect ? <h2 style={{ color: '#20c997' }}><I18Translation>{(t) => t('correct')}</I18Translation></h2> : null }
                                { this.state.isCorrect === false ? <h2 style={{ color: '#ed5249' }}><I18Translation>{(t) => t('incorrect')}</I18Translation></h2> : null }
                                
                                <div className='image-label'>
                                    { this.state.isCorrect ? <h1>{ this.state.answer }</h1> : null }
                                    { this.state.isCorrect ? 
                                        <i onClick={() => this.setState({ showInfo: !this.state.showInfo })} className='fas fa-info-circle'></i>
                                     : null }
                                    <img src={ this.state.picture } width={window.screen.width - 70} alt="capture-pic" />
                                </div>

                                { this.state.isCorrect !== true ? 
                                <div className='user-input-content'>
                                    <input className='input-field' type="text" value={ this.state.guess } onChange={ this.handleChange } />
                                    <button type="submit" className='general-btn' onClick={() => this.findMatch(this.state.guess, this.state.detections) }>Check</button>
                                </div> : null }
                            </div>

                            <div className='image-info'>
                                <div className='image-nav'> 
                                    <div className='back' onClick={() => this.setState({ mode: 0, detections: [], picture: this.state.pictureArray[getRandomIndex(this.state.pictureArray.length)], guess: '', showHint: false })}>
                                        <i class="fas fa-solid fa-backward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('redo')}</I18Translation></div>
                                    </div>       
                                    <div className='back' onClick={() => this.setState({ showHint: !this.state.showHint })}>
                                        <i class="fas fa-eye"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('hint')}</I18Translation></div>
                                    </div>  
                                    <div className='back' onClick={() => this.setState({ mode: 2 })}>
                                        <i class="fas fa-solid fa-arrow-right"></i>
                                        <div className='take-another' style={{ textAlign: 'center' }}><I18Translation>{(t) => t('showOurAnswer')}</I18Translation></div>
                                    </div>      
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 2 ? 
                    <div className='picture-mode-container' style={{ overflowY: 'scroll', height: '100%', paddingBottom: '80px' }}>
                        <div className='image-content'>
                            <div className='image-container'>
                                <h2><I18Translation>{(t) => t('activity3Info')}</I18Translation></h2>
                                <div className='image-label'>
                                    <img src={ this.state.picture } width={ window.screen.width - 70 } alt="captured-pic" />
                                    <div className='image-label-info'>
                                        <h1>
                                            <Speech
                                                styles={speechStyle}
                                                text={`${this.state.highestDetectionScore.name}`}
                                                voice="Google UK English Female"
                                                textAsButton={true}
                                                displayText={<i className="fas fa-volume-up"></i>}
                                            />
                                            <Translation text={ this.state.highestDetectionScore.name } />
                                        </h1>
                                        <i className='fas fa-info-circle' onClick={() => this.setState({ showInfo: !this.state.showInfo })}></i>
                                    </div>
                                    {/* <div className='text-container'>
                                        <h3 className='display-items'>Definition</h3> 
                                        <p>{ this.state.definition === '' ? <Loading /> : this.state.definition }</p>
                                    </div>
                                    <div className='text-container'>
                                        <h3 className='display-items'>Example Sentence</h3> 
                                        <p>{ this.state.sentence === '' ? <Loading /> : this.state.sentence }</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className='image-info'>
                                <div className='image-nav' style={{ width: '100%', overflowX: 'scroll' }}> 
                                    <div className='back' onClick={() => this.setState({ mode: 0, detections: [], picture: this.state.pictureArray[getRandomIndex(this.state.pictureArray.length)], guess: '', showHint: false })}>
                                        <i class="fas fa-solid fa-backward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('redoScan')}</I18Translation></div>
                                    </div>  
                                    {/* <div className='back' onClick={() => this.setState({ mode: 1 })}>
                                        <i class="fas fa-solid fa-arrow-left"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('back')}</I18Translation></div>
                                    </div>   */}

                                    {/* <div className='back' onClick={() => this.setState({ showInfo: !this.state.showInfo })}>
                                        <i class="fas fa-solid fa-info"></i>
                                        <div className='take-another'>Description</div>
                                    </div>   */}
                                    {/* save picture and change mode */}
                                    <div className='back' onClick={() => {
                                        this.handlePhoto();
                                        this.setState({ mode: 3 });
                                    }}>
                                        <i class="fas fa-solid fa-arrow-right"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('info')}</I18Translation></div>
                                    </div> 
                                    <div className='back' onClick={() => this.props.handleOptionChange()}>
                                        <i class="fas fa-solid fa-forward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('newActivity')}</I18Translation></div>
                                    </div>                 
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 3 ?
                    <div className='picture-mode-container' style={{ overflowY: 'scroll', height: '100%', paddingBottom: '80px' }}>
                        <div className='image-content'>
                            <ImageContent picture={ this.state.picture } labels={ this.state.labels } setLabels={ this.setLabels } imageID={ this.state.imageID }  />
                            <div className='image-info'>
                                <div className='image-nav'> 
                                    <div className='back' onClick={() => this.setState({ mode: 2 })}>
                                        <i class="fas fa-solid fa-arrow-left"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('back')}</I18Translation></div>
                                    </div>
                                    <div className='back' onClick={() => this.props.handleOptionChange()}>
                                        <i class="fas fa-solid fa-forward"></i>
                                        <div className='take-another'><I18Translation>{(t) => t('newActivity')}</I18Translation></div>
                                    </div>                 
                                </div>
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.mode === 3 && this.state.labels.length === 0 && <Loading /> }
                { this.state.showInfo ? 
                    <div className='p-modal-container'>
                        <div className='p-modal'>
                            <div className='p-modal-header'>
                                <h1 className='display-items'><Translation text={ this.state.highestDetectionScore.name } /></h1>
                            </div>

                            <div className='p-modal-body'>
                                <div className='text-container'>
                                    <h3 className='display-items'><I18Translation>{(t) => t('definition')}</I18Translation></h3> 
                                    <p>{ this.state.definition === '' ? <Loading /> : this.state.definition }</p>
                                </div>
                                <div className='text-container'>
                                    <h3 className='display-items'><I18Translation>{(t) => t('exampleSentence')}</I18Translation></h3> 
                                    <p>{ this.state.sentence === '' ? <Loading /> : this.state.sentence }</p>
                                </div>
                            </div>

                            <div className='p-modal-footer'>
                                <button className='btn' onClick={() => this.setState({ showInfo: !this.state.showInfo }) }><I18Translation>{(t) => t('close')}</I18Translation></button>
                            </div>
                        </div>
                    </div>
                : null }
            </div>
        )
    }
}