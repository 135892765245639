import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export function Register() {
    const [inputs, setInputs] = useState({ email: "" });

    const { email } = inputs;

    const onChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        try {
            const body = { email };
            const response = await fetch(`auth/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            });
            const parseResponse = await response.json();

            if (parseResponse.token) { // jwt token to local storage
                localStorage.setItem("token", parseResponse.token);
                toast.success(`An Email Has Been Sent To ${email}`);
            } else {
                toast.error(parseResponse);
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    return (
        <div className="page-wrapper light">
            <div className="login-container">
                <h1 className="login-title">Register</h1>
                <form onSubmit={e => onSubmitForm(e)} className="form-container">
                    <input type="email" name="email" placeholder="Email..." className="form-input" value={email} onChange={e => onChange(e)} />
                    <button className="sign-in-btn" type="submit">Register</button>
                </form>
                <div>Already have an account? <Link to="/login">Login</Link></div>
            </div>
        </div>
    )
}