import React, { Component } from 'react';
import { LoginContext } from '../helper/LoginContext';
import Loading from './Loading';

export default class Translation extends Component {
    // use context
    static contextType = LoginContext;

    constructor() {
        super();
        this.state = {
            user_language: "",
            text: "",
            loading: false
        };
    }

    async translate() {
        this.setState({ loading: true });
        try {
            let body = { text: this.props.text };
            const abortController = new AbortController();

            const response = await fetch(`translation/translate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token,
                },
                body: JSON.stringify(body),
                signal: abortController.signal
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || "Failed to fetch translation");
            }

            this.setState({ loading: false });
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    componentDidMount() {
        // console.log(this.props);
        if (this.context.language !== 'en') {
            this.setState({
                user_language: this.context.language,
                text: this.props.text
            }, async () => {
                this.translate().then(data => {
                    if(data && data.translations && data.translations.length > 0) {
                        this.setState({ text: data.translations[0] });
                    }
                });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.text !== this.props.text) {
            if (this.context.language !== 'en') {
                this.setState({
                    user_language: this.context.language,
                    text: this.props.text
                }, async () => {
                    this.translate().then(data => {
                        console.log('translation:', data);
                        if (data && data.translations && data.translations.length > 0) {
                            this.setState({ text: data.translations[0] });
                        }
                    });
                });
            } else {
                this.setState({ text: this.props.text });
            }
        }
    }


    // add functionality for longpress
    render() {
        if (this.state.loading) return <Loading />
        const { text } = this.state;
        return (
            // <LongPress text={ this.state.text.length > 0 ? this.state.text : this.props.text } />
            <>{ text ? this.state.text : this.props.text }</>
            // </LongPress>
            
        )
    }
}
