import React, { useState, useEffect } from 'react';
import { generateLayout } from '../../node_modules/crossword-layout-generator/src/layout_generator';
import { toast } from 'react-toastify';
// import { useHistory } from 'react-router-dom';
// import { Loading } from '../component/Loading';
import { Grid } from './Grid';

export function Crossword(props) {

    // eslint-disable-next-line no-unused-vars
    const [inputArray, setInputArray] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [crosswordJson, setCrossWordJson] = useState(null);
    const [table, setTable] = useState([]);

    const [across, setAcross] = useState(null);
    const [down, setDown] = useState(null);

    const [collapsible, setCollapsible] = useState("clues-collapsible");

    useEffect(() => {
        setInputArray([...props.detectedItems]);
        async function getCrosswordJson() {
            queryBuilder(props.detectedItems)
            if (props.detectedItems && queryString !== "") {
                try {
                    const response = await fetch(`word/cross-word?${queryString}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            token: localStorage.token
                        }
                    });
                    const data = await response.json();
                    console.log(data);
                    setCrossWordJson([...data]);
                    if (data) {
                    }
                } catch (error) {
                    console.error(error.message);
                    toast.error(error);
                }
            }
        }
        getCrosswordJson();
    }, [props.detectedItems, queryString]);

    useEffect(() => {
        if (crosswordJson !== null) {
            crosswordJson.forEach((item, index) => {
                // console.log(item);
                if (item.clue === "") {
                    crosswordJson.splice(index, 1)
                }
            })
            let layout = generateLayout(crosswordJson);
            // sets the number of rows in the table
            // let rows = layout.rows;
            // sets the number of columns in the table
            // let cols = layout.cols;
            let table = layout.table;
            console.log(layout);

            let downArray = [];
            let acrossArray = [];
            for (let i = 0; i < crosswordJson.length; i++) {
                if (crosswordJson[i].orientation === "down") {
                    downArray.push(crosswordJson[i]);
                }
                if (crosswordJson[i].orientation === "across") {
                    acrossArray.push(crosswordJson[i]);
                }
            }

            setDown(downArray);
            setAcross(acrossArray);
            setTable(table);
        }
    }, [crosswordJson]);


    function queryBuilder(arr = []) {
        let result = "words=";
        // str = str.replace(/\s/g, '');
        if (arr.length >= 6) {
            for (let i = 0; i < 6; i++) {
                result += arr[i] + "&words=";
                if (arr[i].indexOf(" ") >= 0) {
                    arr[i] = arr[i].replace(/\s/g, '');
                    // console.log(arr[i]);
                }
            }
        } else {
            for (let i = 0; i < arr.length; i++) {
                result += arr[i] + "&words=";
                if (arr[i].indexOf(" ")) {
                    arr[i] = arr[i].replace(/\s/g, '');
                    // console.log(arr[i]);
                }
            }
        }

        setQueryString(result.slice(0, -7));
    }

    useEffect(() => {
        if (down !== null && across !== null) {
            console.log(down);
            console.log(across);
        }
    }, [down, across]);

    return (
        <div className="crossword">
            <Grid array={table} />
            {/* <div className="clue">
                <h3 style={{ fontWeight: "bold" }}>Down</h3>
                {   
                    down && 
                    down.map((item, index) => (
                        <div key={ index }>
                            <p className="clue-number">clue #{ index + 1 }:</p>
                            <p className="clue-sentence"> { item.clue }</p>
                        </div>
                    ))
                }
                <h3 style={{ fontWeight: "bold", marginTop: 10 }}>Across</h3>
                {
                    across &&
                    across.map((item, index) => (
                        <div key={ index }>
                            <p className="clue-number">clue #{ index + 1 }:</p>
                            <p className="clue-sentence"> { item.clue }</p>
                        </div>
                    ))
                }
            </div> */}
            <div className="expandable-clues">
                <h1 onClick={() => {
                    if (collapsible === "clues") {
                        setCollapsible("clues-collapsible")
                    } else if (collapsible === "clues-collapsible") {
                        setCollapsible("clues");
                    }
                }}>{collapsible === "clues" ? <>&#8593;</> : <>&#8595;</>}</h1>
                <div className={collapsible} >
                    <div className="clue">
                        <h3 style={{ fontWeight: "bold" }}>Down</h3>
                        {
                            down &&
                            down.map((item, index) => (
                                <div key={index}>
                                    <p className="clue-number">clue #{index + 1}:</p>
                                    <p className="clue-sentence"> {item.clue}</p>
                                </div>
                            ))
                        }
                        <h3 style={{ fontWeight: "bold", marginTop: 10 }}>Across</h3>
                        {
                            across &&
                            across.map((item, index) => (
                                <div key={index}>
                                    <p className="clue-number">clue #{index + 1}:</p>
                                    <p className="clue-sentence"> {item.clue}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}