import React, { useState, useRef, useEffect, useContext } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { useUserMedia } from '../../views/PictureMode/UserMedia';

import { useHistory } from 'react-router-dom';
import 'react-html5-camera-photo/build/css/index.css';
import { LoginContext } from '../../helper/LoginContext';
import { Modal } from '../../components/Modal';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';

const CAMERA_CONFIG = {
    audio: false,
    video: FACING_MODES.ENVIRONMENT
}

export function TreasureHunt() {
    const { huntWord, huntImage } = useContext(LoginContext);

    const [dataUri, setDataUri] = useState("");
    const [wordToHunt, setWordToHunt] = useState("");
    const [lastImage, setLastImage] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [previousImage, setPreviousImage] = useState([]);
    const [huntArray, setHuntArray] = useState([]);

    const [huntState, setHuntState] = useState("INITIAL");
    // eslint-disable-next-line no-unused-vars
    const [pictureTaken, setPictureTaken] = useState(false);
    const [loading, setLoading] = useState("");
    const [success, setSuccess] = useState(false);

    const [labels, setLabels] = useState([]);

    const [similarityFound, setSimilarityFound] = useState(false);

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const videoRef = useRef();
    const mediaStream = useUserMedia(CAMERA_CONFIG);

    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }

    let history = useHistory();

    const handleTakePhoto = async (dataUri) => {
        try {
            setDataUri(dataUri);
            setPictureTaken(false);

            const body = { dataUri };
            const response = await fetch(`api/image`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            })
            const parseResponse = await response.json();
            localStorage.removeItem("galleryImages");
            if (parseResponse) {
                setHuntArray((oldArray) => [...oldArray, "FIRST"]);

                setPictureTaken(true);
            }

            console.log("parse Response", parseResponse[0].labels);
            setLabels(parseResponse[0].labels);
        } catch (error) {
            console.error(error.message);
        }
    }

    const getWord = async () => {
        try {
            setLoading(true);
            const response = await fetch(`word/hunt`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                }
            });
            const parseResponse = await response.json();
            setWordToHunt(parseResponse);
            setLoading(false);
        } catch (error) {
            console.error(error.message);
        }
    }

    const getTreasureHunt = async () => {
        try {
            setLoading(true);
            const response = await fetch(`word/treasure-hunt`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                }
            })
            const parseResponse = await response.json();
            console.log("parse Response:", parseResponse);
            if (parseResponse) {
                setSuccess(true);
                setLastImage(parseResponse.last);
                setPreviousImage(parseResponse.previous);
                foundMatch(parseResponse.last, parseResponse.previousArray);
            }
            setLoading(false);
        } catch (error) {
            console.error(error.message);
        }
    }

    const foundMatch = (array1, array2) => {
        if ((array1 !== null || array1 !== undefined) && (array2 !== null || array2 !== undefined)) {
            let found = array1.some((element) => array2.includes(element));
            console.log("First array:", array1);
            console.log("Second array:", array2);
            if (found === true) {
                setSimilarityFound(true);
            } else {
                setSimilarityFound(false);
            }
        }
    };

    const cameraComponent = () => {
        return (
            <div className="camera-container">
                <Camera
                    onTakePhoto={(dataUri) => {
                        handleTakePhoto(dataUri)
                    }}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    isImageMirror={false}
                    isFullscreen={false}
                    sizeFactor={1.0}
                    isMaxResolution={false}
                    imageCompression={1.0}
                    imageType={IMAGE_TYPES.PNG}
                />
            </div>
        )
    }
    const renderPage = () => {
        switch (huntState) {
            case "INITIAL":
                return (
                    <div className="hunt-container">
                        <button className="exit-btn" onClick={() => { history.push("/dash-board") }}><i class="fas fa-angle-left"></i></button>
                        {cameraComponent()}
                    </div>
                );
            case "PREVIEW":
                return (
                    <div className="hunt-container">
                        {
                            wordToHunt && (
                                <h2 className="text-center">
                                    Take a picture of a word similar to <p className="display-word" data-toggle="modal" data-target="#selectModal">{wordToHunt}</p>
                                </h2>
                            )
                        }
                        <Modal id={"selectModal"} labelby={"selectModal"} labels={labels} myFunction={(e) => setWordToHunt(e.target.innerText)} />
                        <div className="img-container">
                            <img src={dataUri} alt="treasure-hunt-img" className="label-img" />
                        </div>
                        <div className="button-container">
                            <button className="btn" onClick={() => {
                                setHuntState("FIRSTPICTURE");
                            }}>Take Picture</button>
                            <button className="btn" onClick={() => {
                                history.push("/dash-board")
                            }}>Exit</button>
                        </div>
                    </div>
                );
            case "FIRSTPICTURE":
                return (
                    <div className="hunt-container">
                        {
                            wordToHunt && (
                                <h2 className="text-center">
                                    Take a picture of a word similar to <p className="display-word">{wordToHunt}</p>
                                </h2>
                            )
                        }
                        {cameraComponent()}
                    </div>
                );
            case "SECONDPICTURE":
                return (
                    <div className="hunt-container">
                        {
                            similarityFound ? (
                                <div>
                                    <h2 className="text-center">
                                        You took a picture of <p className="display-word">{lastImage[0]}</p> which is contextually similar to <p className="display-word">{wordToHunt}</p>
                                    </h2>
                                </div>
                            ) : (
                                <div>
                                    <h2 className="text-center">
                                        <p className="display-word">{lastImage[0]}</p> does not seem to be contextually similar to <p className="display-word">{wordToHunt}</p>
                                    </h2>
                                </div>
                            )
                        }
                        <div className="img-container">
                            <img src={dataUri} alt="second-pic" className="label-img" />
                        </div>
                        {
                            success && similarityFound ? (
                                <div className="button-container">
                                    <button className="btn" onClick={() => {
                                        setHuntState("RETAKE");
                                    }}>Retake</button>
                                    <button className="btn" onClick={() => {
                                        setHuntArray((oldArray) => [...oldArray, "FIRST"]);
                                        setWordToHunt(lastImage[0]);
                                        setHuntState("CONTINUE");
                                    }}>Continue</button>
                                    <button className="btn" onClick={() => {
                                        setHuntState("INITIAL");
                                    }}>Restart</button>
                                </div>
                            ) : (
                                <div className="button-container">
                                    <button className="btn" onClick={() => {
                                        setHuntState("CONTINUE");
                                    }}>Try Again</button>
                                    <button className="btn" onClick={() => {
                                        setHuntArray([]);
                                        setWordToHunt("");
                                        setHuntState("INITIAL");
                                    }}>New Hunt</button>
                                    <button className="btn" onClick={() => {
                                        history.push("/dash-board")
                                    }}>Exit</button>
                                </div>
                            )
                        }
                    </div>
                );
            case "CONTINUE":
                return (
                    <div className="hunt-container">
                        <div>
                            <h2 className="text-center">Take a picture of a word similar to <p className="display-word">{wordToHunt}</p></h2>
                        </div>
                        {cameraComponent()}
                    </div>
                );
            case "RETAKE":
                return (
                    <div className="hunt-container">
                        <button className="exit-btn" onClick={() => { history.push("/dash-board") }}><i className="fas fa-angle-left"></i></button>
                        <div>
                            <h2 className="text-center">Take a picture of a word similar to <p className="display-word">{wordToHunt}</p></h2>
                        </div>
                        {cameraComponent()}
                    </div>
                );
            default:
                break;
        }
    }

    if (loading) {
        <Loading />
    }

    useEffect(() => {
        if (huntArray.length === 0 && huntWord !== "") {
            console.log("FIRST INVOKED");
            setWordToHunt(huntWord);
            setDataUri(huntImage);
            setHuntArray((oldArray) => [...oldArray, "FIRST"]);
        } else if (huntArray.length === 0) {
            console.log("SECOND INVOKED");
            setHuntState("INITIAL");
        } else if (huntArray.length % 2 !== 0) {
            console.log("THIRD INVOKED");
            if (huntWord === "") {
                getWord().then(() => {
                    setHuntState("PREVIEW");
                })
            } else {
                setHuntState("PREVIEW");
            }
        } else if (huntArray.length % 2 === 0 && huntArray.length !== 0) {
            console.log("FOURTH INVOKED");
            getTreasureHunt().then(() => {
                setHuntState("SECONDPICTURE");
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [huntArray]);

    useEffect(() => {
        localStorage.setItem("whichUrl", "/treasure-hunt")
    }, []);

    // useEffect(() => {
    //     console.log("Labels:", labels);
    // }, [labels]);

    useEffect(() => {
        console.log("CURRENT STATE:", huntState);
    }, [huntState]);

    useEffect(() => {
        console.log("WORD TO HUNT:", wordToHunt);
    }, [wordToHunt]);

    useEffect(() => {
        if (huntState === "INITIAL") {
            toast.success("Take a picture of an object near you");
        }
    }, [huntState])

    return (
        <div className="hunt-container">
            {/* <button className="exit-btn" onClick={() => { history.push("/dash-board") }}><i className="fas fa-angle-left"></i></button> */}
            {renderPage()}
        </div>
    )
}