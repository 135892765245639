import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSpeechSynthesis } from 'react-speech-kit';
import { toast } from 'react-toastify';
import Translation from '../../components/Translation';
import { LoginContext } from '../../helper/LoginContext';


function Results(props) {
    const history = useHistory();
    const { phase, results, nextPhase } = props;
    const phase2And3Attributes = [{ icon: 'fa-image', correctType: 'chosenCorrectPicture' }, { icon: 'fa-keyboard', correctType: 'spelledCorrect' }];

    const [savingResults, setSavingResults] = useState(false);

    const { language } = useContext(LoginContext);
    const [voice, setVoice] = useState(1);
    const { speak, voices } = useSpeechSynthesis();

    const [audioSrc, setAudioSrc] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        if (audioSrc) {
            const audioElement = document.getElementById("audioElement");
            audioElement.play();
        }
    }, [audioSrc]);

    useEffect(() => {
        if (language === "fr") setVoice(11)
        else if (language === "es") setVoice(9)
        else if (language === "pt") setVoice(9)
        else setVoice(0);
    }, [language]);

    async function postResults() {
        // console.log(results)
        setSavingResults(true);
        try {
            await fetch('results/postResults', {
                method: "POST",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                body: JSON.stringify({ results })
            });
            history.push("/dash-board");
            setSavingResults(false); // probably don't need this
        } catch (error) {
            console.error(error.message);
        }
    }

    async function handleTextToSpeech(text) {
        try {
             const response = await fetch(`translation/tts?text=${encodeURIComponent(text)}`, {
                  method: "GET",
                  headers: {
                       "Content-Type": "application/json",
                       token: localStorage.token
                  }
             });
             const data = await response.json();
            setAudioSrc('');

             setAudioSrc(data.audio);
        } catch (error) {
             console.error("Error fetching audio:", error);
             toast.error("Audio generation temporarily unavailable");
        }
   }


    return (
        <div className='results-container'>
        <div className='Results'>
            <div>
                <h1 className='header'>{t('results')}</h1>
            </div>
            <div>
                {results.map((result, i) =>
                    <ul className='word-result' key={i}>
                        <li className='word' id={`speech-id-${i}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Translation text={result.word} />
                            <i className="fas fa-volume-up" onClick={(e) => {
                                handleTextToSpeech(document.getElementById(`speech-id-${i}`).innerText || "");
                                // speak({ 
                                //     text: document.getElementById(`speech-id-${i}`).innerText, // should probably not use this way of get text but using now because translation throwing mad errors
                                //     voice: voices[voice] 
                                // });
                            }} ></i>
                            <audio id="audioElement" src={audioSrc}></audio>
                        </li>
                        <li><i className='fas fa-images'></i>{t('quiz2')} {result.attempts} {result.attempts > 1 ? <>{t('attempts')}</> : <>{t('attempt')}</>}</li>
                        {phase2And3Attributes.map((map_phase, p) => {  // Phase 2 and 3 share the same result format...
                            return (
                                phase > p + 1 && // Show phase results once the phase is completed
                                <li key={p}>
                                    <i className={`fas ${map_phase.icon}`}></i>
                                    { // switch case
                                        {
                                            null: <>N/A</>,
                                            true: <span className='correct'>{t('correct')} <i className='fas fa-check'></i></span>,
                                            false: <span className='incorrect'>{t('incorrect')} <i className='fas fa-times'></i></span>
                                        }[result[map_phase.correctType]]
                                    }
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
            <div className='buttons'>
                {phase < 3 &&
                    <button disabled={savingResults} onClick={() => nextPhase()}>
                        {t('phase')} {phase + 1} <i className='fas fa-arrow-right'></i>
                    </button>
                }
                <button disabled={savingResults} onClick={() => postResults()}>
                    {savingResults ? "Saving Results..." : t('finish')}
                </button>
            </div>
        </div>
        </div>
    )
}

export default Results