import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { toast } from 'react-toastify';
import { MenuCard } from './MenuCard';
// import logo from "../images/logo_transparent.png"; 
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../helper/LoginContext';
import { PhotoUpload } from './PhotoUpload';

export function Dashboard() {
    let history = useHistory();
    const { i18n, t } = useTranslation();

    const { language, setLanguage, setImageCount } = useContext(LoginContext);

    const [canAccessQuiz, setCanAccessQuiz] = useState("");
    const [canAccessMemories, setCanAccessMemories] = useState(0);


    async function getName() {
        try {
            const response = await fetch(`dashboard`, {
                method: "GET",
                headers: {
                    token: localStorage.getItem("token")
                }
            });
            const parseResponse = await response.json();
            // console.log("user_id:", parseResponse.user_id);
        } catch (error) {
            console.error(error.message);
            toast.error(error.message);
        }
    }

    async function getLanguage() {
        try {
            const response = await fetch(`translation/translation-user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.token
                }
            });
            const data = await response.json();
            setLanguage(data.user_language);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchQuizContent() {
        try {// handle fetch abort midway of this transaction
             const abortController = new AbortController();
             const response = await fetch(`api/quiz-mode`, {
                  method: "POST",
                  headers: { "Content-Type": "application/json", token: localStorage.token },
                  signal: abortController.signal,
                  body: JSON.stringify({ questionAmount: 3 })
             });
             const data = await response.json();
             setCanAccessQuiz(data);
             // console.log(data);
        } catch (error) {
             if (error.name === "AbortError") console.error("Fetch aborted");
             console.error(error);
        }
    }

    async function fetchGalleryImages() {
        try {
            const abortController = new AbortController();
            const response = await fetch("api/gallery-images", {
                method: "GET",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                signal: abortController.signal
            });
            const data = await response.json();
            setImageCount(data.length);
            setCanAccessMemories(data);
        } catch (error) {
            if (error.name === "AbortError") console.error("Fetch aborted");
            console.error(error);
        }
    }

    async function logAction(action, detail) {
        try {
            const abortController = new AbortController();
            await fetch('auth/user-action', {
                method: "POST",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                body: JSON.stringify({
                    action: action,
                    detail: detail
                }),
                signal: abortController.signal
            });

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    useEffect(() => {
        getName();
    }, []);

    useEffect(() => {
        fetchQuizContent();
        fetchGalleryImages();
    }, []);

    useEffect(() => {
        localStorage.setItem("whichUrl", "/dash-board");
    }, []);

    /*<div className="main-wrapper" style={{ backgroundColor: color }}></div> <img className="logo" src={ logo } alt="logo" /> */
    return (
        <div className='Home'>
            <h1 className='section-header' style={{ marginTop: 0 }}>LangEye</h1>
            <div className="menu-wrapper">

                <div className="picture-options">
                    {/* <MenuCard 
                        icon_class={"fas fa-camera"} 
                        // title={"Picture Mode"} 
                        title={t("pictureMode")}
                        myFunction={() => {
                            // log picture mode action
                            logAction('picture mode', '');

                            history.push("/eye-mode");
                        }}     
                    /> */}
                    {/* <MenuCard 
                        icon_class={"fas fa-image"} 
                        // title={"Memories"} 
                        title={t("memories")}
                        myFunction={() => {
                            // // log memory action
                            // logAction('memories', '');

                            if (typeof canAccessMemories === "string") {
                                toast.warning(canAccessMemories)
                                // log memory action, detail: unable to access
                                logAction('memories', 'Unable to access, not enough pictures');
                            }
                            else { 
                                history.push("/my-memories") 
                                logAction('memories', '');
                            } //setTimeout(() => history.push("/my-memories"), 100);
                        }} /> */}
                </div>
                <div className="activities">
                    {/* <MenuCard 
                        icon_class={"fas fa-eye"} 
                        // title={"Scan & Find"} 
                        title={t("scanFind")}
                        myFunction={() => {
                            // log scan & learn action
                            logAction('Scan & Learn', '');

                            setTimeout(() => history.push("/around-me-instructions"), 100)
                        }} /> */}
                    <MenuCard 
                        icon_class={"fas fa-image"} 
                        // title={"Memories"} 
                        title={t("memories")}
                        myFunction={() => {
                            // // log memory action
                            // logAction('memories', '');

                            if (typeof canAccessMemories === "string") {
                                toast.warning(canAccessMemories)
                                // log memory action, detail: unable to access
                                logAction('memories', 'Unable to access, not enough pictures');
                            }
                            else { 
                                history.push("/my-memories") 
                                logAction('memories', '');
                            } //setTimeout(() => history.push("/my-memories"), 100);
                    }} />
                    <MenuCard 
                        icon_class={"fas fa-scroll"} 
                        // title={"Review Memories"} 
                        title={t("reviewMemories")}
                        myFunction={() => {
                            console.log(canAccessQuiz);
                            if (canAccessQuiz === undefined) {
                                toast.error("Unable to access quiz due to server error, try refreshing the page");
                            // } else if (canAccessQuiz.quizContent.length < 3) {
                            // if not enough picture then show not enough pictures message
                            } else if (typeof canAccessMemories === "string") {
                                toast.warning('Unable to access quiz, not enough picture for quiz');
                                // log quiz action, detail: unable to access
                                logAction('quiz', 'Unable to access, not enough pictures for quiz')
                            }
                            else {
                                history.push("/quiz");
                                // log quiz action
                                logAction('quiz', '');
                            } // setTimeout(() => history.push("/quiz"), 100);
                        }} />
                    <MenuCard 
                        icon_class={"fas fa-trophy"} 
                        // title={"Achievements"} 
                        title={t("achievements")}
                        myFunction={() => {
                            if (canAccessQuiz.length > 0) {
                                toast.warning(canAccessQuiz);
                                // log achievements action, unable to access
                                logAction('achievements', 'Unable to access, no achievements in quiz');
                            }
                            else {
                                history.push("/achievements")
                                // log achievements action
                                logAction('achievements', '');
                            } //setTimeout(() => history.push("/achievements"), 100);
                        }} />
                    <MenuCard 
                        icon_class={"fas fa-comment"} 
                        // title={"Achievements"} 
                        title={t("feedback")}
                        myFunction={() => {
                            // log feedback action
                            logAction('feedback', '');

                            if (canAccessQuiz.length > 0) toast.warning(canAccessQuiz);
                            else history.push("/feedback") //setTimeout(() => history.push("/achievements"), 100);
                        }} />
                    
                    {/* <MenuCard icon_class={"fas fa-map-marked-alt"} title={"Treasure Hunt"} myFunction={() => setTimeout(() => history.push("/hunt-options"), 100)} /> */}
                </div>
                <div className="settings">
                    <MenuCard 
                    icon_class={"fas fa-cog"} 
                    // title={"Settings"} 
                    title={t("settings")}
                    myFunction={() => {
                        // log settings action
                        logAction('settings', '');

                        setTimeout(() => history.push("/settings"), 100)
                    }} />
                </div>
                {/* <MenuCard icon_class={ "fas fa-cog" } title={ "Eye Mode" } myFunction={() => setTimeout(() => {
                history.push("/tensorflow-camera")
            })} /> */}
                {/* <MenuCard icon_class={ "" } title={ "Tensor" } myFunction={() => setTimeout(() => {
                history.push("/tensor")
            }, 100)} /> */}
                {/* <MenuCard icon_class={ "" } title={ "Crossword" } myFunction={() => setTimeout(() => {
                history.push("/crossword-game")
            }, 100)} /> */}

                {/* <div 
                    style={{ 
                         position: 'fixed', 
                         right: 10, 
                         bottom: 60, 
                         backgroundColor: 'white',
                         borderRadius: '5px', 
                         padding: '5px' }} 
                    className='upload-wrapper'
                    >
                         <PhotoUpload />
               </div> */}
            </div>
        </div>
    )
}