import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AboutCard from '../../components/AboutCard';
import { MenuCard } from '../../components/MenuCard';
import { LoginContext } from '../../helper/LoginContext';
import { generateRandomNumber } from '../../helper/randomizer';

// Import components
import { toast } from 'react-toastify';
import { Activity1 } from './Activity1';
import { Activity2 } from './Activity2';
import { Activity3 } from './Activity3';
import { Activity4 } from './Activity4';
import { Camera } from './Camera';

class ScanMode extends Component {
    // use context type, this now allows us to use this.context to get all context values
    static contextType = LoginContext;
    
    constructor() {
        super();

        this.state = {
            mode: 0,
            show: false,
            option: 0,
            pictureArray: [],
            detectionArray: [],
            key: 0,
            showOptions: true,
            whichOption: 0,
            usePreviousScan: true
        }
    }

    componentDidMount() {
        // when randomizer is NOT on, show the options
        if (!this.context.isRandom) this.setState({ showOptions: true })
        // when randomizer IS on, then do not show the options
        else this.setState({ showOptions: false, show: true })
    }

    // handle mount/unmounting of camera component
    handleCameraShow = () => {
        this.setState({
            show: !this.state.show
        })
    }

    // function handles option change by generating new function
    handleOptionChange = () => {
        console.log("changing option");
        this.setState({
            //option: generateRandomNumber(3)
            mode: 0,
            option: 0,
            showOptions: true
        });
    }

    // remount camera
    handleCameraUnmountRemount = () => {
        this.setState({ 
            key: this.state.key + 1
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.state.mode, this.state.option, this.state.show);
        if (this.state.show !== prevState.show) {
            this.setState({ mode: 2 });
        }

        // if the previous option is the same as the current option then generate a new different option and option isn't 0 (0 => info for round of activities)
        if (this.state.option === prevState.option && this.state.option !== 0) {
            this.setState({
                option: generateRandomNumber(4)
            })
        }

    }

    async logAction(action, detail) {
        try {
            const abortController = new AbortController();
            await fetch('auth/user-action', {
                method: "POST",
                headers: { "Content-Type": "application/json", token: localStorage.token },
                body: JSON.stringify({
                    action: action,
                    detail: detail
                }),
                signal: abortController.signal
            });

        } catch (error) {
            console.error(error);
        }
    }

    renderCardBasedOption() {
        const { option, pictureArray, detectionArray } = this.state;

        switch (option) {
            case 0:
                return <AboutCard 
                    iconImage={ 'fas fa-solid fa-magnifying-glass' } 
                    cardTitle={ <Translation>{(t) => t('scanComplete')}</Translation> } 
                    cardInstructions={ <Translation>{(t) => t('scanInfo')}</Translation> } 
                    btnText={ <Translation>{(t) => t('startActivities')}</Translation> } 
                    // change to generateRandomNumber(<x>) when done testing
                    StateFunction={() => this.setState({ option: this.state.whichOption })}
                />
            case 1:
                return <Activity1 option={ 1 } handleOptionChange={ this.handleOptionChange } {...this.props} pictureArray={ pictureArray } detectionArray={ detectionArray } />
            case 2:
                return <Activity2 option={ 2 } handleOptionChange={ this.handleOptionChange } {...this.props} pictureArray={ pictureArray } detectionArray={ detectionArray } />
            case 3:
                return <Activity3 option={ 3 } handleOptionChange={ this.handleOptionChange } {...this.props} pictureArray={ pictureArray } detectionArray={ detectionArray } />
            case 4:
                return <Activity4 option={ 4 } handleOptionChange={ this.handleOptionChange } {...this.props} pictureArray={ pictureArray } detectionArray={ detectionArray } />
            // case 5:
            //     return <Activity5 option={ 5 } handleOptionChange={ this.handleOptionChange } {...this.props} pictureArray={ pictureArray } detectionArray={ detectionArray } />    
            default:
                break;
        }
    }

    render() {
        const { show } = this.state;

        return (
            <div>
                {/* { this.state.pictureArray.length > 0 && this.state.showOptions ? <h1 onClick={() => console.log(this.state.pictureArray, this.state.detectionArray)}>Use previous scan</h1> : <></> } */}
                { this.state.showOptions ? 
                    <div className='Home' style={{ overflowY: 'scroll', height: '100%' }}>
                        <h1 className='section-header'>LangEye</h1>
                        <div className='menu-wrapper'>
                            <div className='activites'>
                                { 
                                    this.state.pictureArray.length > 0 && this.state.detectionArray.length > 0 && this.state.showOptions ?
                                    <label className='scan-learn-use-previous'>
                                        <div className='settings-card randomizer-scan-learn' >
                                            <div className='randomizer-head-scan-learn' style={{ width: '100%' }}>
                                                <h2>Use Previous Scan?</h2>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.usePreviousScan}
                                                        onClick={() => {
                                                            console.log(this.state.usePreviousScan);
                                                            this.setState({ usePreviousScan: !this.state.usePreviousScan }, () => {
                                                                if (this.state.pictureArray.length <= 0 || this.state.detectionArray.length <= 0) {
                                                                    toast.error("You have no scans")
                                                                    this.setState({ usePreviousScan: false }, () => console.log(this.state.usePreviousScan))
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </label>
                                    :
                                    <></>
                                }
                                <MenuCard icon_class={ "fas fa-solid fa-compass" } title={ <Translation>{(t) => t('activity4Title')}</Translation> } 
                                    myFunction={() => {
                                        // log activity 1 action
                                        this.logAction('Scan & Learn', 'Activity 1: Discover');
                                        // if previous scan state is true then DO NOT use camera, otherwise use camera
                                        // previously below statement was => this.setState({ showOptions: false, whichOption: 4, show: true })
                                        this.setState({ showOptions: false, whichOption: 4, show: this.state.pictureArray.length <= 0 && this.state.detectionArray.length <= 0 ? true : !this.state.usePreviousScan }, () => {
                                            // if length of picture and detection array is 0 then show camera
                                            // otherwise usePreviousScan
                                            this.setState({ mode: 2 })
                                        });
                                    }} />
                                <MenuCard icon_class={ "fas fa-eye" } title={ <Translation>{(t) => t('activity1')}</Translation> } 
                                    myFunction={() => {
                                        // log activity 2 action
                                        this.logAction('Scan & Learn', 'Activity 2: Find it around you');
                                        
                                        this.setState({ showOptions: false, whichOption: 1, show: this.state.pictureArray.length <= 0 && this.state.detectionArray.length <= 0 ? true : !this.state.usePreviousScan }, () => {
                                            this.setState({ mode: 2 });
                                        });
                                    }} />
                                {/* <MenuCard icon_class={ "fas fa-solid fa-question" } title={ <Translation>{(t) => t('activity3')}</Translation> } 
                                    myFunction={() => {
                                        // log activity 3 action
                                        this.logAction('Scan & Learn', 'Activity 3: What is this object?');

                                        this.setState({ showOptions: false, whichOption: 3, show: !this.state.usePreviousScan }, () => {
                                            this.setState({ mode: 2 })
                                        })
                                    }} /> */}
                                <MenuCard icon_class={ "fas fa-solid fa-user-secret" } title={ <Translation>{(t) => t('activity2')}</Translation> } 
                                    myFunction={() => {
                                        // log activity 4 action
                                        this.logAction('Scan & Learn', 'Activity 4: Imposter Item');
                                        this.setState({ showOptions: false, whichOption: 2, show: this.state.pictureArray.length <= 0 && this.state.detectionArray.length <= 0 ? true : !this.state.usePreviousScan }, () => {
                                            this.setState({ mode: 2 })
                                        })
                                    }} />
                                {/* <MenuCard icon_class={ "fas fa-solid fa-spell-check" } title={ <Translation>{(t) => t('activity5')}</Translation> } myFunction={() => this.setState({ showOptions: false, whichOption: 5, show: true })} /> */}
                            </div>

                            <div className='settings' style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>  
                                <MenuCard icon_class={"fas fa-solid fa-arrow-left" } title={ <Translation>{(t) => t('back')}</Translation> } myFunction={() => this.props.history.push("/around-me-instructions")} />
                                <MenuCard icon_class={"fas fa-solid fa-dice" } title={ <Translation>{(t) => t('pickForMe')}</Translation> } 
                                    myFunction={() => {
                                        // log randomizer option
                                        this.logAction('Scan & Learn', 'Random pick');

                                        this.setState({ showOptions: false, show: true, whichOption: generateRandomNumber(5) })
                                    }} />
                            </div>
                        </div>
                    </div>
                : null }
                { this.state.show ?
                    <Camera 
                        mode={ this.state.mode }
                        show={ show }
                        handleCameraShow={ this.handleCameraShow }
                        {...this.props}
                        sendPictureArray={(data) => this.setState({ pictureArray: data })}
                        sendDetections={(data) => this.setState({ detectionArray: data })}
                        handleCameraUnmountRemount={(data) => this.setState({ key: data })}
                    />
                : null }
                { this.state.mode === 2 ? <div>{ this.renderCardBasedOption() }</div> : <></> }
            </div>
        )
    }
}


/**
 * Camera component
 * NOTE: Camera will unmount as soon as the timer runs out
 */

// class Camera extends Component {
//     constructor() {
//         super();

//         this.cameraRef = React.createRef();

//         this.state = {
//             initialTime: 10,
//             progress: 100,
//             screenWidth: 0,
//             screenHeight: 0,
//             intervalId: 0,
//             videoConstraints: {
//                 facingMode: "environment"
//             },
//             pictureArray: [],
//             detectionArray: [],
//             key: 0
//         }
//     }


//     // set screen height and width and start timer when camera mounts
//     componentDidMount() {
//         // set screen height and width
//         this.setState({ screenWidth: window.screen.width, screenHeight: window.screen.height });

//         // start timer
//         this.interval = workerTimers.setInterval(() => {
//             this.setState({ initialTime: this.state.initialTime - 0.1 });
//             if (this.state.initialTime <= 0.1) {
//                 workerTimers.clearInterval(this.interval);
//             }
//         }, 100);

//         // scan new images every 2 seconds
//         let timerInterval = setInterval(() => this.scan(), 2000);

//         this.setState({ intervalId: timerInterval });

//         console.log("Camera mounted");
//     }

//     // unmount component when initialTime state updates to <= 0.01
//     componentDidUpdate(prevProps, prevState) {
//         if (this.state.initialTime < prevState.initialTime) {
//             // check when timer has ended
//             if (this.state.initialTime <= 0.01) {
//                 console.log("timer ended");

//                 toast.success('Please wait while we finish processing the images', { autoClose: 3500 });
                
//                 // send picture to activities
//                 this.props.sendPictureArray(this.state.pictureArray);

//                 // process images and then unmount camera and send detections after 2 seconds
//                 this.handleImages().then(() => setTimeout(() => {
//                     this.props.sendDetections(this.state.detectionArray);
//                     this.props.handleCameraShow();
//                 }, 2000));
//             }
//         }
//     }

//     componentWillUnmount() {
//         clearInterval(this.state.intervalId);
//         console.log("Camera unmounted");
//     }

//     // processes each picture taken
//     async handleImages() {
//         let labelList = [];
//         for (let i = 0; i < this.state.pictureArray.length; i++) {
//             let dataUri = this.state.pictureArray[i];
//             let body = { dataUri };
//             try {
//                 const response = await fetch(`api/detect-images`, {
//                     method: "POST",
//                     headers: {
//                         'Content-Type': 'application/json',
//                         token: localStorage.token
//                     },
//                     body: JSON.stringify(body)
//                 });

//                 const data = await response.json();

//                 labelList.push(data);
//             } catch (error) {
//                 console.error(error);
//                 toast.error(error);
//             }
//         }

//         if (labelList.flat().length === 0) {
//             this.props.handleCameraUnmountRemount(this.state.key + 1);
//         }

//         // set detections made
//         this.setState({ detectionArray: labelList }, () => console.log(this.state.detectionArray));
//     }

//     // function to scan area around you within the timer and pushes new images every 2 seconds
//     scan() {
//         let newTime = this.state.initialTime - 1;
//         if (newTime >= 0) {
//             if (this.cameraRef.current !== 'undefined' || this.cameraRef.current !== null) {
//                 const imageSrc = this.cameraRef.current.getScreenshot();

//                 this.setState({ pictureArray: [...this.state.pictureArray, imageSrc] });
//             }
//         } else {
//             clearInterval(this.state.intervalId);
//             console.log("Scan finished");
//         }
//     }

//     render() {
//         const { initialTime, progress } = this.state;

//         return (
//             <div className='picture-mode-container'>
//                 <div className='camera-container'>
//                     <div className='timer-scan-mode'>
//                         <p>Time Remaining: { initialTime >= 0 ? Math.round(initialTime) : 0 }s</p>
//                         <div className='progress' style={{ width: `${(progress/10) * this.state.initialTime}%`, transition: '0.2s' }}></div>
//                     </div>

//                     <div>
//                         <Webcam 
//                             audio={ false }
//                             ref={ this.cameraRef }
//                             width={ this.state.screenWidth }
//                             screenshotFormat="image/png"
//                             className='webcam'
//                             videoConstraints={ this.state.videoConstraints }
//                             onUserMediaError={() => {
//                                 console.log("Camera cannot be found");
//                                 this.props.history.push("/dash-board");
//                             }}
//                         />
//                     </div>

//                     <div className='camera-nav'>
//                         <div className='flip-mode' onClick={() => {
//                             let { videoConstraints } = this.state;

//                             if (videoConstraints.facingMode === 'environment') {
//                                 this.setState({ videoConstraints: { facingMode: "user" }})
//                             } else {
//                                 this.setState({ videoConstraints: { facingMode: "environment" }})
//                             }
//                         }}
//                         ><img src={ RotateIcon } alt="Camera Rotate" /></div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }




// activity 1; user is asked to find a prompted object near them
// TODO (October 12 2022) []: FINISH the logic to show info on object




// class Activity1 extends Component {
//     constructor() {
//         super();

//         this.state = {
//             show: true,
//             mode: 0,
//             pictureArray: [],
//             detectionArray: [],
//             objectToFind: '',
//             previousObject: '',
//             picture: '',
//             pictureTaken: true,
//             labels: [],
//             imageID: 0
//         }
//     }

//     handleCameraShow = () => {
//         this.setState({
//             show: !this.state.show
//         }, () => this.setState({ mode: 2 }));
//     }

//     componentDidMount() {
//         console.log("Activity 1 mounted");
//         console.log("Activity 1:", this.props.pictureArray, this.props.detectionArray);
//         // save picture and detections to component
//         this.setState({ pictureArray: this.props.pictureArray, detectionArray: this.props.detectionArray }, () => {
//             // select random picture to find
//             let index = getRandomIndex(this.state.pictureArray.length);

//             // get random item from list [[] []], this is a 2d array, so index will select an array with the corresponding picture based on the index
//             let detection = this.state.detectionArray[index];

//             // select random item from list
//             let object = detection[getRandomIndex(detection.length)];

//             // set object to find
//             this.setState({ objectToFind: object, previousObject: object }, () => console.log(this.state.objectToFind));
//         })
//     }

//     componentWillUnmount() {
//         console.log("Activity 1 Unmounted");
//     }


//     // handle saving the photo to get more information on it
//     handlePhoto() {
//         if (this.state.picture === null) return;

//         this.setState({ pictureTaken: true }, async () => {
//             try {
//                 const response = await fetch(`api/image`, {
//                     method: "POST",
//                     headers: {
//                         'Content-Type': 'application/json',
//                         token: localStorage.token
//                     },
//                     body: JSON.stringify({ dataUri: this.state.picture })
//                 });
//                 const data = await response.json();

//                 console.log('data', data);

//                 this.setState({ 
//                     labels: data[0].labels,
//                     imageID: data[0].data_id
//                 });
//             } catch (error) {
//                 console.error(error);
//                 toast.error(error);
//             }
//         })
//     }

//     setLabels = (newLabels) => this.setState({ labels: newLabels });

//     render() {
//         const { show, previousObject, objectToFind, picture, labels, imageID } = this.state;

//         return (
//             <div>
//                 { this.state.mode === 0 ? 
//                     <AboutCard 
//                         iconImage={ 'fas fa-solid fa-magnifying-glass' }
//                         cardTitle={ 'Find Object X' }
//                         cardInstructions={ `Find ${objectToFind} near you` }
//                         btnText={ 'Start Activity' }
//                         StateFunction={() => this.setState({ mode: 1 })}
//                     />
//                 : null }
//                 { this.state.show && this.state.mode === 1 ? 
//                     <Camera 
//                         show={ show }
//                         handleCameraShow={ this.handleCameraShow }
//                         {...this.props}
//                         sendPictureArray={(data) => this.setState({ pictureArray: data })}
//                         sendDetections={(data) => {
//                             // loop throught picture array then find individually
//                             for (let pictureIdx = 0; pictureIdx < this.state.detectionArray.length; pictureIdx++) {
//                                 for (let detectionIdx = 0; detectionIdx < this.state.detectionArray[pictureIdx].length; detectionIdx++) {
//                                     // store picture from picture array
//                                     this.setState({ picture: this.state.pictureArray[pictureIdx] });
//                                     console.log("Picture and word found");
//                                 }
//                             }

//                             // select random picture to find
//                             let index = getRandomIndex(this.state.pictureArray.length);

//                             // get random item from list [[] []], this is a 2 array, so index will select an array with the corresponding picture based on the index
//                             let detection = data[index];
                                                    
//                             // select random item from list
//                             let object = detection[getRandomIndex(detection.length)];
                                                    
//                             // set object to find
//                             this.setState({ objectToFind: object }, () => console.log(this.state.objectToFind));
//                         }}
//                     />
//                 : null }
//                 { this.state.mode === 2 ? 
//                     <div className='picture-mode-container'>
//                         <div className='image-content'>
//                             <div className='image-container'>
//                                 <h1>Nice work! You found { previousObject }</h1>
//                                 <div className='image-label'>
//                                     <img src={ this.state.picture } alt='user-pic-taken' />
//                                 </div>
//                                 <div className='instructions'>
//                                     <p>Save the image to learn more about what's in this picture.</p>
                                    
//                                 </div>
//                             </div>
//                             <div className='image-info'>
//                                 <button className='general-btn' onClick={() => {
//                                         this.setState({ mode: 3 });
//                                         this.handlePhoto();
//                                 }}>Save Image</button>
//                                 <button className='general-btn' onClick={() => this.props.handleOptionChange()} >Next</button>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 && this.state.pictureTaken && labels.length > 0 ? 
//                     <div className='picture-mode-container'>
//                         <div className='image-content'>
//                             <ImageContent picture={ picture } labels={ labels } setLabels={ this.setLabels } imageID={ imageID } />
//                             <div className='image-info'>
//                                 <div className='image-nav'>
//                                     <div className='back' onClick={() => this.setState({ pictureTaken: false, picture: "", labels: [] })}>
//                                         <i className="fas fa-camera"></i>
//                                         <div className='take-another'>Take Another</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.history.push("/my-memories")}>
//                                         <i className="fas fa-image"></i>
//                                         <div className='take-another'>Memories</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.handleOptionChange()}>
//                                         <i className='fas fa-solid fa-arrow-right'></i>
//                                         <div className='take-another'>Continue</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 && labels.length === 0 && <Loading /> }
//             </div>
//         )
//     }
// }

// activity 2; user is asked to find the 'fake' item present in the list
// TODO (October 12 2022) [X]: Work on showing info on the imposter item like example sentence and the associated word with it


// class Activity2 extends Component {
//     constructor() {
//         super();

//         this.state = {
//             show: true,
//             mode: 0,
//             pictureArray: [],
//             detectionArray: [],
//             guess: '',
//             randomItem: '',
//             isCorrect: false,
//             guessSentence: '',
//             guessDefinition: '',
//             randomItemSentence: '',
//             randomItemDefinition: '',
//             showDef: true,
//             showSent: false
//         }
//     }

//     /**
//      * TODO: RECHECK LOGIC
//      */
//     componentDidMount() {
//         console.log("Activity 2 mounted");
//         console.log("Activity 2:", this.props.pictureArray, this.props.detectionArray);
//         this.setState({ detectionArray: this.props.detectionArray, pictureArray: this.props.pictureArray }, () => {
//             // flatten detection array
//             let flattenDetections = [...this.state.detectionArray.flat()];

//             // get random item from list
//             let randomItem = flattenDetections[getRandomIndex(this.state.detectionArray[0].length)];

//             console.log("Random item", randomItem);

//             // get related object to random item
//             this.getRelatedObject(randomItem).then(data => {
//                 console.log("Related word is", data[0].word);
//                 // randomItem: randomItem
//                 this.setState({ guess: data[0].word, randomItem: randomItem }, () => {
//                     var rand = Math.floor(Math.random() * (this.state.detectionArray.flat().length - 0)) + 1
//                     flattenDetections.splice(rand, 0, this.state.guess);

//                     // update state of detection array and remove all duplicates after inserting element (!might have to remove duplicates before!)
//                     this.setState({ detectionArray: [...new Set(flattenDetections)] });
//                 });
//             });
//         })
//     }

//     componentWillUnmount() {
//         console.log("Activity 2 Unmounted");
//     }

//     // triggers the redo functionality when mode changes to back to 1 or anything but 2
//     componentDidUpdate(prevProps, prevState) {
//         if (this.state.mode !== prevState.mode) {
//             // if the previous state was 2 (not current state) meaning the mode changed to something but 2
//             if (prevState.mode === 2) {
//                 this.setState({ detectionArray: this.props.detectionArray, pictureArray: this.props.pictureArray }, () => {
//                     // flatten detection array
//                     let flattenDetections = [...this.state.detectionArray.flat()];
        
//                     // get random item from list
//                     let randomItem = flattenDetections[getRandomIndex(this.state.detectionArray[0].length)];
        
//                     console.log("Random item", randomItem);
        
//                     // get related object to random item
//                     this.getRelatedObject(randomItem).then(data => {
//                         console.log("Related word is", data[0].word);
//                         // randomItem: randomItem
//                         this.setState({ guess: data[0].word, randomItem: randomItem }, () => {
//                             var rand = Math.floor(Math.random() * (this.state.detectionArray.flat().length - 0)) + 1
//                             flattenDetections.splice(rand, 0, this.state.guess);
        
//                             // update state of detection array and remove all duplicates after inserting element (!might have to remove duplicates before!)
//                             this.setState({ detectionArray: [...new Set(flattenDetections)] });
//                         });
//                     });
//                 });
//             }
//             if (this.state.mode === 2) {
//                 // if mode 2 is mounted then fetch word info
//                 this.wordInfo(this.state.guess).then(data => this.setState({ guessSentence: data.example, guessDefinition: data.definition }));
//                 this.wordInfo(this.state.randomItem).then(data => this.setState({ randomItemSentence: data.example, randomItemDefinition: data.definition }) );
//             }
//         }
//     }


//     // function to get a related item to a word
//     async getRelatedObject(item) {
//         try {
//             const abortController = new AbortController();

//             const response = await fetch(`word/datamuse?word=${item}`, {
//                 method: "GET",
//                 headers: {
//                     "Content-Type": "application/json",
//                     token: localStorage.token,
//                     signal: abortController.signal
//                 }
//             });

//             const parseResponse = await response.json();
//             return parseResponse;
//         } catch (error) {
//             console.error(error);
//             toast.error(error);
//         }
//     }

//     // fetch example sentence and definition guess and randomItem words
//     async wordInfo(word) {        
//         try {
//             const response = await fetch(`word/word-info`, {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     token: localStorage.token
//                 },
//                 body: JSON.stringify({ label: word })
//             });
//             const data = await response.json();
//             console.log('word', word);
//             return data;
//         } catch (error) {
//             console.error(error);
//         }
//     }


//     render() {
//         return (
//             <div>
//                 { this.state.mode === 0 ? 
//                     <AboutCard 
//                         cardTitle={ 'Imposter Item' }
//                         cardInstructions={ 'Which item was not present in the scan you made' }
//                         btnText={ 'Start Activity' }
//                         StateFunction={() => this.setState({ mode: 1 }) }
//                     />
//                 : null }
//                 { this.state.mode === 1 ? 
//                     <div className='p-modal-container'>
//                         <div className='p-modal'>
//                             <div className='p-modal-header'>
//                                 <h1>Which item was not present in the scan you made?</h1>
//                             </div>

//                             <ul className='p-modal-body'>
//                                 {
//                                     this.state.detectionArray.map((item, index) => (
//                                         <li key={ index } onClick={(e) => {
//                                             console.log(e.target.innerText);
//                                             if (e.target.innerText !== this.state.guess) {
//                                                 e.target.classList.add("incorrect");
//                                             } else if (e.target.innerText === this.state.guess) {
//                                                 e.target.classList.add("correct");
//                                                 setTimeout(() => {
//                                                     this.setState({ isCorrect: true, mode: 2 })
//                                                 }, 1500);
//                                             }
//                                         }}>{ item }</li>
//                                     ))
//                                 }
//                             </ul>

//                             {/* <div className='p-modal-footer'>
//                                 <button className='btn' onClick={() => this.props.handleOptionChange() }>Next</button>
//                             </div> */}
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 2 ? 
//                     <div className='picture-mode-container' style={{ height: '100%', overflowY: 'scroll' }}>
//                         <div className='image-content'>
//                             <div className='image-container'>
//                                 <div className='inline-text-container'>
//                                     <h3 className='title'>You guessed: </h3>
//                                     <p className='display-items'>{ this.state.guess }</p>
//                                 </div>
//                                 <div className='inline-text-container'>
//                                     <h3 className='title'>Related Item to that was:</h3> 
//                                     <p className='display-items'>{ this.state.randomItem }</p>
//                                 </div>
                                
//                                 <div className='info-container'>
//                                     <div className='inline-text-container-sub'>
//                                         <h3 className='display-items'>{ this.state.guess } </h3>
//                                         <p className='sentence'>Definition: { this.state.guessDefinition === '' ? <Loading /> : this.state.guessDefinition }</p>
//                                         <p className='sentence'>Example Sentence: { this.state.guessSentence === '' ? <Loading /> : this.state.guessSentence }</p>
//                                     </div>

//                                     <div className='inline-text-container-sub'>
//                                         <h3 className='display-items'>{ this.state.randomItem }</h3>
//                                         <p className='sentence'>Definition: { this.state.randomItemDefinition  === '' ? <Loading /> : this.state.randomItemDefinition }</p>
//                                         <p className='sentence'>Example Sentence: { this.state.randomItemSentence === '' ? <Loading /> : this.state.randomItemSentence }</p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='image-info'>
//                                 <div className='image-nav'>
//                                     <div className='back' onClick={() => this.setState({ mode: 1, guessDefinition: '', guessSentence: '', randomItemDefinition: '', randomItemSentence: '' })}>
//                                         <i class="fas fa-solid fa-arrow-left"></i>
//                                         <div className='take-another'>Redo</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.history.push("/my-memories")}>
//                                         <i className='fas fa-image'></i>
//                                         <div className='take-another'>Memories</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.handleOptionChange()}>
//                                         <i className='fas fa-solid fa-arrow-right'></i>
//                                         <div className='take-another'>Continue</div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null}
//             </div>
//         )
//     }
// }

// activity 3; user is shown an image and have to guess which item is that image
// TODO (October 12 2022) []: fix styling 
// class Activity3 extends Component {
//     constructor() {
//         super();
        
//         this.canvasRef = React.createRef();

//         this.state = {
//             show: true,
//             mode: 0,
//             pictureArray: [],
//             detectionArray: [],
//             guess: '',
//             picture: '',
//             detections: [],
//             showHint: false,
//             imageData: [],
//             highestDetectionScore: [],
//             sentence: '',
//             definition: '',
//             labels: [],
//             imageID: 0,
//             showInfo: false,
//             isCorrect: null,
//             answer: ''
//         }
//     }

//     componentDidMount() {
//         console.log("Activity 3 mounted");
//         console.log("Activity 3:", this.props.pictureArray, this.props.detectionArray);
//         this.setState({ pictureArray: this.props.pictureArray, detectionArray: this.props.detectionArray }, () => {
//             // get random index from picture array
//             let randomIndex = getRandomIndex(this.state.pictureArray.length);

//             // get randomly selected picture from index
//             let picture = this.state.pictureArray[randomIndex];

//             // get randomly selected detection array based on selected picture
//             let detections = this.state.detectionArray[randomIndex];

//             // lowercase all detections
//             detections = detections.map(item => item.toLowerCase());

//             // set picture to the randomly selected one
//             this.setState({ picture: picture });
//         })
//     }

//     componentDidUpdate(prevProps, prevState) {
//         if (this.state.mode !== prevState.mode) {
//             if (this.state.mode === 1) {
//                 this.handleGetImageLocalization(this.state.picture).then(data => {
                    
//                     // store all item names
//                     let detections = data.map(item => item.name);

//                     // store object with highest score to show final results for
//                     const maxScore = data.reduce((a, b) => a.score > b.score ? a: b);          
                                        
//                     this.setState({ imageData: [...data], detections: detections, highestDetectionScore: maxScore }, () => {
//                         if (this.state.showHint && data.length > 0) {
//                             let canvasContext = this.canvasRef.current.getContext('2d');
//                             this.canvasRef.current.style.width = '100%';
//                             this.canvasRef.current.style.height = '100%';
//                             Utility_DrawRect(data, canvasContext)
//                         }
//                     });
//                 });
//             }

//             if (this.state.mode === 2) {
//                 this.wordInfo(this.state.highestDetectionScore.name).then(data => {
//                     console.log(data);
//                     if (data.definition) {
//                         this.setState({ definition: data.definition });
//                     } else {
//                         this.setState({ definition: 'Could not find definition, try again later or try scanning in a different location' })
//                     }
//                     if (data.example) {
//                         this.setState({ sentence: data.example });
//                     } else {
//                         this.setState({ sentence: 'Could not find an example sentence, try again later or try scanning in a different location' })
//                     }
//                 })
//             }
//         }

//         // invoked when user clicks 'showHint'
//         if (this.state.showHint !== prevState.showHint) {
//             if (this.state.showHint) {
//                 let canvasContext = this.canvasRef.current.getContext('2d');
//                 this.canvasRef.current.style.width = '100%';
//                 this.canvasRef.current.style.height = '100%';
//                 // remove duplicates from list
//                 let filteredData = this.state.imageData.filter((item, pos) => this.state.imageData.findIndex(v2 => (item.name === v2.name)) === pos);
//                 console.log(filteredData);
//                 Utility_DrawRect(filteredData, canvasContext);
//             }
//         }

//         // mainly for the guessing mode itself to see the answer and the definition of the word
//         if (this.state.isCorrect !== prevState.isCorrect) {
//             if (this.state.isCorrect) {
//                 this.wordInfo(this.state.answer).then(data => {
//                     console.log(data);
//                     if (data.definition) {
//                         this.setState({ definition: data.definition });
//                     } else {
//                         this.setState({ definition: 'Could not find definition, try again later or try scanning in a different location' })
//                     }
//                     if (data.example) {
//                         this.setState({ sentence: data.example });
//                     } else {
//                         this.setState({ sentence: 'Could not find an example sentence, try again later or try scanning in a different location' })
//                     }
//                 })
//             }
//         }
//     }

//     // get objects in a given picture
//     handleGetImageLocalization = async (picture) => {
//         try {
//             let dataUri = picture;
//             let body = { dataUri };
//             const response = await fetch(`api/bound-image`, {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     token: localStorage.token
//                 },
//                 body: JSON.stringify(body)
//             });
//             const data = await response.json();

//             return data;
//         } catch (error) {
//             console.error(error);
//             toast.error(error);
//         }
//     }

//     componentWillUnmount() {
//         console.log("Activity 3 Unmounted");
//     }

//     handleChange = (e) => {
//         this.setState({ guess: e.target.value });
//     }

//     // fetch example sentence and definition guess and randomItem words
//     async wordInfo(word) {        
//         try {
//             const response = await fetch(`word/word-info`, {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     token: localStorage.token
//                 },
//                 body: JSON.stringify({ label: word })
//             });
//             const data = await response.json();
//             console.log('word', word);
//             return data;
//         } catch (error) {
//             console.error(error);
//         }
//     }

//     // handle saving the photo to get more information on it
//     handlePhoto() {
//         if (this.state.picture === null) return;

//         this.setState({ pictureTaken: true }, async () => {
//             try {
//                 const response = await fetch(`api/image`, {
//                     method: "POST",
//                     headers: {
//                         'Content-Type': 'application/json',
//                         token: localStorage.token
//                     },
//                     body: JSON.stringify({ dataUri: this.state.picture })
//                 });
//                 const data = await response.json();

//                 console.log('data', data);

//                 this.setState({ 
//                     labels: data[0].labels,
//                     imageID: data[0].data_id
//                 });
//             } catch (error) {
//                 console.error(error);
//                 toast.error(error);
//             }
//         })
//     }

//     findMatch = (item, array = []) => {
//         if (item === null || array.length === 0) {
//             toast.error("An unknown error occured with our server, try the scan again");
//         }
//         const match = array.find(item => item.toLowerCase().includes(item.toLowerCase()));

//         console.log(item, array, match);
//         if (match.toLowerCase().includes(item.toLowerCase())) this.setState({ isCorrect: true, answer: match });
//         else this.setState({ isCorrect: false });
//     }

//     setLabels = (newLabels) => this.setState({ labels: newLabels });

//     render() {
//         return (
//             <div>
                
//                 { this.state.mode === 0 ? 
//                     <AboutCard
//                         cardTitle={ 'What is this?' }
//                         cardInstructions={ 'Type in the text box which item you think is shown in the image?' }
//                         btnText={ 'Start Activity' }
//                         StateFunction={() => this.setState({ mode: 1 }) }                       
//                     />
//                 : null }
//                 { this.state.mode === 1 ? 
//                     <div className='picture-mode-container'>
//                         <div className='image-content'>
//                             <div className='image-container'>
//                                 <h2>What object is in this picture?</h2>
//                                 { this.state.isCorrect ? <h2 style={{ color: '#20c997' }}>Correct</h2> : null }
//                                 { this.state.isCorrect === false ? <h2 style={{ color: '#ed5249' }}>Incorrect</h2> : null }
                                
//                                 <div className='image-label'>
//                                     { this.state.isCorrect ? <h1>{ this.state.answer }</h1> : null }
//                                     { this.state.isCorrect ? <div className='icon'  onClick={() => this.setState({ showInfo: !this.state.showInfo })} >
//                                         <i className='fas fa-solid fa-info'></i>
//                                     </div> : null }
//                                     <img src={ this.state.picture } width={window.screen.width - 70} alt="capture-pic" />
//                                     { this.state.showHint ? <canvas ref={ this.canvasRef } width='325' height='243' className="image-canvas" /> : null }                        
//                                 </div>

//                                 { this.state.isCorrect !== true ? <div className='user-input-content'>
//                                     <input className='input-field' type="text" value={ this.state.guess } onChange={ this.handleChange } />
//                                     <button type="submit" className='general-btn' onClick={() => this.findMatch(this.state.guess, this.state.detections) }>Check</button>
//                                 </div> : null }
//                             </div>

//                             <div className='image-info'>
//                                 <div className='image-nav'> 
//                                     <div className='back' onClick={() => this.setState({ mode: 0, detections: [], picture: this.state.pictureArray[getRandomIndex(this.state.pictureArray.length)], guess: '', showHint: false })}>
//                                         <i class="fas fa-solid fa-backward"></i>
//                                         <div className='take-another'>Redo</div>
//                                     </div>       
//                                     <div className='back' onClick={() => this.setState({ showHint: !this.state.showHint })}>
//                                         <i class="fas fa-eye"></i>
//                                         <div className='take-another'>Show Hint</div>
//                                     </div>  
//                                     <div className='back' onClick={() => this.setState({ mode: 2 })}>
//                                         <i class="fas fa-solid fa-arrow-right"></i>
//                                         <div className='take-another' style={{ textAlign: 'center' }}>Show our answer</div>
//                                     </div>      
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 2 ? 
//                     <div className='picture-mode-container'>
//                         <div className='image-content'>
//                             <div className='image-container'>
//                                 <h2>Most profound object in your scan.</h2>
//                                 <div className='image-label'>
//                                     <img src={ this.state.picture } width={ window.screen.width - 70 } alt="captured-pic" />
//                                     <h1>{ this.state.highestDetectionScore.name }</h1>
//                                     <div className='icon'  onClick={() => this.setState({ showInfo: !this.state.showInfo })} >
//                                         <i className='fas fa-solid fa-info'></i>
//                                     </div>
//                                     {/* <div className='text-container'>
//                                         <h3 className='display-items'>Definition</h3> 
//                                         <p>{ this.state.definition === '' ? <Loading /> : this.state.definition }</p>
//                                     </div>
//                                     <div className='text-container'>
//                                         <h3 className='display-items'>Example Sentence</h3> 
//                                         <p>{ this.state.sentence === '' ? <Loading /> : this.state.sentence }</p>
//                                     </div> */}
//                                 </div>
//                             </div>
//                             <div className='image-info'>
//                                 <div className='image-nav' style={{ width: '100%', overflowX: 'scroll' }}> 
//                                     <div className='back' onClick={() => this.setState({ mode: 0, detections: [], picture: this.state.pictureArray[getRandomIndex(this.state.pictureArray.length)], guess: '', showHint: false })}>
//                                         <i class="fas fa-solid fa-backward"></i>
//                                         <div className='take-another'>Redo scan</div>
//                                     </div>  
//                                     <div className='back' onClick={() => this.setState({ mode: 1 })}>
//                                         <i class="fas fa-solid fa-arrow-left"></i>
//                                         <div className='take-another'>Back</div>
//                                     </div>  

//                                     {/* <div className='back' onClick={() => this.setState({ showInfo: !this.state.showInfo })}>
//                                         <i class="fas fa-solid fa-info"></i>
//                                         <div className='take-another'>Description</div>
//                                     </div>   */}
//                                     {/* save picture and change mode */}
//                                     <div className='back' onClick={() => {
//                                         this.handlePhoto();
//                                         this.setState({ mode: 3 });
//                                     }}>
//                                         <i class="fas fa-solid fa-arrow-right"></i>
//                                         <div className='take-another'>Info</div>
//                                     </div> 
//                                     <div className='back' onClick={() => this.props.handleOptionChange()}>
//                                         <i class="fas fa-solid fa-forward"></i>
//                                         <div className='take-another'>New activity</div>
//                                     </div>                 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 ?
//                     <div className='picture-mode-container' style={{ overflowY: 'scroll', height: '100%', paddingBottom: '80px' }}>
//                         <div className='image-content'>
//                             <ImageContent picture={ this.state.picture } labels={ this.state.labels } setLabels={ this.setLabels } imageID={ this.state.imageID }  />
//                             <div className='image-info'>
//                                 <div className='image-nav'> 
//                                     <div className='back' onClick={() => this.setState({ mode: 2 })}>
//                                         <i class="fas fa-solid fa-arrow-left"></i>
//                                         <div className='take-another'>Back</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.handleOptionChange()}>
//                                         <i class="fas fa-solid fa-forward"></i>
//                                         <div className='take-another'>New activity</div>
//                                     </div>                 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 && this.state.labels.length === 0 && <Loading /> }
//                 { this.state.showInfo ? 
//                     <div className='p-modal-container'>
//                         <div className='p-modal'>
//                             <div className='p-modal-header'>
//                                 <h1 className='display-items'>{ this.state.highestDetectionScore.name }</h1>
//                             </div>

//                             <div className='p-modal-body'>
//                                 <div className='text-container'>
//                                     <h3 className='display-items'>Definition</h3> 
//                                     <p>{ this.state.definition === '' ? <Loading /> : this.state.definition }</p>
//                                 </div>
//                                 <div className='text-container'>
//                                     <h3 className='display-items'>Example Sentence</h3> 
//                                     <p>{ this.state.sentence === '' ? <Loading /> : this.state.sentence }</p>
//                                 </div>
//                             </div>

//                             <div className='p-modal-footer'>
//                                 <button className='btn' onClick={() => this.setState({ showInfo: !this.state.showInfo }) }>Close</button>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//             </div>
//         )
//     }
// }

// activity 4; select any word found in the scan, display image, and localize selected word
// TODO (October 12 2022) []: show info on the localized images and save image with those words
// class Activity4 extends Component {
//     constructor() {
//         super();

//         this.canvasRef = React.createRef();
//         this.imageRef = React.createRef();

//         this.state = {
//             mode: 0,
//             pictureArray: [],
//             detectionArray: [],
//             pictures: [],
//             objectToLearn: '',
//             currentPictureIndex: 0,
//             currentIndex: 0,
//             toShow: [],
//             key: 0,
//             labels: [],
//             imageID: 0,
//             pictureTaken: false,
//             showInfo: false,
//             imageData: [],
//             touchStart: 0,
//             touchEnd: 0
//         }
//     }

//     componentDidMount() {
//         console.log("Activity 4 mounted");
//         console.log(this.props.pictureArray, this.props.detectionArray);
//         this.setState({ pictureArray: this.props.pictureArray, detectionArray: this.props.detectionArray })
//     }

//     componentWillUnmount() {
//         console.log("Activity 4 unmounted");
//     }

//     // subtract current picture index
//     handlePreviousImage = () => {
//         // if the current image index is 0 then set to the last image index to ensure an image is always shown, then run the localization api on the image and draw the bounding boxes
//         if (this.state.currentPictureIndex === 0) this.setState({ currentPictureIndex: this.state.toShow.length - 1}, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
//         else this.setState({ currentPictureIndex: this.state.currentPictureIndex - 1}, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
//     }

//     // add current picture index
//     handleNextImage = () => {
//         // if the current image index is at the max length then set to the first index to ensure an image is always shown, then run the localization api on the image and draw the bounding boxes
//         if (this.state.currentPictureIndex >= this.state.toShow.length - 1) this.setState({ currentPictureIndex: 0 }, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
//         else this.setState({ currentPictureIndex: this.state.currentPictureIndex + 1 }, () => this.handleGetImageLocalization().then(data => Utility_DrawRect(data, this.canvasRef.current.getContext('2d'))));
//     }

//     // function to send image from the current posted image and get all image localizations
//     handleGetImageLocalization = async () => {
//         try {
//             let dataUri = this.state.toShow[this.state.currentPictureIndex];
//             let body = { dataUri };
//             const response = await fetch(`api/bound-image`, {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     token: localStorage.token
//                 },
//                 body: JSON.stringify(body)
//             });
//             const data = await response.json();

//             // remove duplicates from list
//             let filteredData = data.filter((item, pos) => data.findIndex(v2 => (item.name === v2.name)) === pos)

//             this.setState({ imageData: [...filteredData] });

//             return data;
//         } catch (error) {
//             console.error(error);
//             toast.error(error);
//         }
//     }

//     // when the mode is 2 (initial displaying of images) then run the image localizations
//     componentDidUpdate(prevProps, prevState) {
//         if (this.state.mode !== prevState.mode) {
//             if (this.state.mode === 2) {
//                 this.handleGetImageLocalization().then(data => {
//                     const ctx = this.canvasRef.current.getContext("2d");
//                     Utility_DrawRect(data, ctx);
//                     console.log(data)
//                 });
//             }
//         }
//     }

//     // handle saving the photo to get more information on it
//     handlePhoto() {
//         if (this.state.toShow[this.state.currentPictureIndex] === null) return;

//         this.setState({ pictureTaken: true }, async () => {
//             try {
//                 const response = await fetch(`api/image`, {
//                     method: "POST",
//                     headers: {
//                         'Content-Type': 'application/json',
//                         token: localStorage.token
//                     },
//                     body: JSON.stringify({ dataUri: this.state.toShow[this.state.currentPictureIndex] })
//                 });
//                 const data = await response.json();

//                 console.log('data', data);

//                 this.setState({ 
//                     labels: data[0].labels,
//                     imageID: data[0].data_id
//                 });
//             } catch (error) {
//                 console.error(error);
//                 toast.error(error);
//             }
//         })
//     }

//     // handle right swipe
//     handleSwipeStart = (e) => {
//         this.setState({ touchStart: e.targetTouches[0].clientX });
//     }

//     // handle swipe end
//     handleSwipeEnd = (e) => {
//         this.setState({ touchEnd: e.targetTouches[0].clientX })
//     }

//     // handle swipe
//     handleSwipe = () => {
//         const { touchStart, touchEnd } = this.state;

//         // left swipe
//         if (touchStart - touchEnd > 75) {
//             // go to next picture
//             this.handleNextImage();
//         }

//         // right swipe
//         if (touchStart - touchEnd < -75) {
//             // go to last picture
//             this.handlePreviousImage();
//         }
//     }


//     setLabels = (newLabels) => this.setState({ labels: newLabels });

//     render() {
//         const { objectToLearn } = this.state;

//         return(
//             <div>
//                 {this.state.mode === 0 ? 
//                     <AboutCard
//                         cardTitle={ 'Where was this?' }
//                         cardInstructions={ 'Select any object from the list retrieved from your scan and view the object information in depth and find its where it was?' }
//                         btnText={ 'Start Activity' }
//                         StateFunction={() => this.setState({ mode: 1 }) }                       
//                     />
//                 : null }
                
//                 { this.state.mode === 1 ?
//                     <div className='p-modal-container'>
//                         <div className='p-modal'>
//                             <div className='p-modal-header'>
//                                 <h1>Which object would you like to localize?</h1>
//                                 <h3 style={{ fontWeight: 'bold', color: '#0275d8' }}>{ this.state.objectToLearn }</h3>
//                             </div>

//                             <ul className='p-modal-body'>
//                                 {  
//                                     // flatten array, filter remove duplicates and map through the distinct items
//                                     this.state.detectionArray.flat().filter((i, pos) => this.state.detectionArray.flat().indexOf(i) === pos).map((item, index) => (
//                                         <li key={ index } onClick={(e) => {
//                                             this.setState({ objectToLearn: e.target.innerText }, () => {                                              
//                                                 let occurences = new Map();
//                                                 let pictures = [];
  
//                                                 // loop through array of detections
//                                                 for (let pictureIndex = 0; pictureIndex < this.state.detectionArray.length; pictureIndex++) {
//                                                     // if the detection array at index includes the selected word then push to the MAP the picture index with value [objectSelected, and object to learn]
//                                                     if (this.state.detectionArray[pictureIndex].includes(this.state.objectToLearn)) {
//                                                         occurences.set(pictureIndex, [this.state.objectToLearn, pictureIndex]);
//                                                     }
//                                                 }

//                                                 occurences.forEach((item) => {
//                                                     // push picture index
//                                                     pictures.push(item[1]);
//                                                 })

//                                                 // set all indexes of the pictures with the word
//                                                 this.setState({ pictures: [...pictures] }, () => { 
//                                                     this.setState({ currentPictureIndex: this.state.pictures[0] }, () => {
//                                                         // push all pictures in toShow state
//                                                         for (let i = 0; i < this.state.pictures.length; i++) {
//                                                             this.setState({ toShow: [...this.state.pictureArray, this.state.pictureArray[i]] })
//                                                         }
//                                                     });

                                                    
//                                                 })
//                                             });
//                                         }}>{ item }</li>
//                                     ))
//                                 }
//                             </ul>

//                             <div className='p-modal-footer'>
//                                 <button onClick={() => this.setState({ mode: 2 })}>Next</button>
//                             </div>
//                         </div>
//                     </div>
//                 : null }

//                 { this.state.mode === 2 ?
//                     <div className='picture-mode-container'>
//                         <div className='image-content'>
//                             <div className='image-container'>
//                                 <div className='image-label'>
//                                     <h1>{ objectToLearn }</h1>
//                                     <div className='icon'  onClick={() => this.setState({ showInfo: !this.state.showInfo })} >
//                                         <i className='fas fa-solid fa-info'></i>
//                                     </div>
//                                     <img 
//                                         ref={ this.imageRef } 
//                                         src={ this.state.toShow[this.state.currentPictureIndex] } 
//                                         alt='user-pic-taken' 
//                                     />
//                                     <canvas 
//                                         onTouchStart={ this.handleSwipeStart } 
//                                         onTouchMove={ this.handleSwipeEnd }
//                                         onTouchEnd={ this.handleSwipe }
//                                         ref={ this.canvasRef } 
//                                         width='325' 
//                                         height='243' 
//                                         className="image-canvas" 
//                                     />  
//                                 </div>
//                                 <div className='instructions'>
//                                     <p>Click 'Info' button on the image to view all detections on current image. Swipe left or right to traverse through sections of your scan.</p>
//                                 </div>
//                             </div>
//                             <div className='image-info' style={{ width: '100%' }}>
//                                 <div className='image-nav'> 
//                                     <div className='back' onClick={() => this.setState({ mode: 2 })}>
//                                         <i class="fas fa-solid fa-backward"></i>
//                                         <div className='take-another'>Back</div>
//                                     </div>
//                                     <div className='back' onClick={() => {
//                                         this.handlePhoto();
//                                         this.setState({ mode: 3 });   
//                                     }}>
//                                         <i className="fas fa-solid fa-download"></i>
//                                         <div className='take-another'>Save</div>
//                                     </div>          

//                                     <div className='back' onClick={ () => this.props.handleOptionChange() }>
//                                         <i className="fas fa-solid fa-forward"></i>
//                                         <div className='take-another'>New activity</div>
//                                     </div>         
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 ?
//                     <div className='picture-mode-container' style={{ overflowY: 'scroll' }}>
//                         <div className='image-content'>
//                             <ImageContent picture={ this.state.toShow[this.state.currentPictureIndex] } labels={ this.state.labels } setLabels={ this.setLabels } imageID={ this.state.imageID }  />
//                             <div className='image-info'>
//                                 <div className='image-nav'> 
//                                     <div className='back' onClick={() => this.setState({ mode: 2 })}>
//                                         <i class="fas fa-solid fa-arrow-left"></i>
//                                         <div className='take-another'>Back</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.handleOptionChange()}>
//                                         <i class="fas fa-solid fa-forward"></i>
//                                         <div className='take-another'>New activity</div>
//                                     </div>                 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 && this.state.labels.length === 0 ? <Loading /> : null }
//                 { this.state.showInfo ? 
//                     <div className='p-modal-container'>
//                         <div className='p-modal'>
//                             <div className='p-modal-header'>
//                                 <h1 className='display-items'>Items in this image</h1>
//                             </div>

//                             <hr className='divider' />
                            
//                             <div className='p-modal-body'>
//                                 <ul>
//                                     { this.state.imageData.map((item, index) => 
//                                         <li key={ index } className='display-items'>{ item.name }</li>
//                                     )}
//                                 </ul>
//                             </div>

//                             <div className='p-modal-footer'>
//                                 <button className='btn' onClick={() => this.setState({ showInfo: !this.state.showInfo }) }>Close</button>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//             </div>
//         )
//     }
// }



/** 
 *  Activity 5: Find the object that you can X with
 *  Find the object for the action word, what does this describe?
*/ 
// class Activity5 extends Component {
//     constructor() {
//         super();

//         this.state = {
//             detectionArray: [],
//             pictureArray: [],
//             mode: 0,
//             currentPictureIndex: 0,
//             currImageDetections: [],
//             isCorrect: null,
//             labels: [],
//             imageID: 0,
//             picture: ''
//         }
//     }

//     componentDidMount() {
//         console.log("Activity 5 mounted");
//         // load the detection array from the component tree
//         this.setState({ detectionArray: this.props.detectionArray, pictureArray: this.props.pictureArray, currImageDetections: this.props.detectionArray[this.state.currentPictureIndex] }, () => {
//             let item = this.getRandomWordDetections(this.state.detectionArray);

//             // run stem word to this
//             console.log('item', item);
//             this.fetchStem(item).then(data => this.setState({ toFind: data }, () => console.log('ToFind data:', data)))
//         });
//     }

//     handlePreviousImage = () => {
//         // if the current image index is 0 then set to the last image
//         if (this.state.currentPictureIndex === 0) this.setState({ currentPictureIndex: this.state.pictureArray.length - 1 }, () => this.setState({ currImageDetections: this.state.detectionArray[this.state.currentPictureIndex], picture: this.state.pictureArray[this.state.currentPictureIndex] }, () => console.log(this.state.currImageDetections)));
//         else this.setState({ currentPictureIndex: this.state.currentPictureIndex - 1 }, () => this.setState({ currImageDetections: this.state.detectionArray[this.state.currentPictureIndex], picture: this.state.pictureArray[this.state.currentPictureIndex] }, () => console.log(this.state.currImageDetections)));
//     }

//     handleNextImage = () => {
//         // if the current image is the last image then go back to the first image
//         if (this.state.currentPictureIndex >= this.state.pictureArray.length - 1) this.setState({ currentPictureIndex: 0 }, () => this.setState({ currImageDetections: this.state.detectionArray[this.state.currentPictureIndex], picture: this.state.pictureArray[this.state.currentPictureIndex] }, () => console.log(this.state.currImageDetections)));
//         else this.setState({ currentPictureIndex: this.state.currentPictureIndex + 1 }, () => this.setState({ currImageDetections: this.state.detectionArray[this.state.currentPictureIndex], picture: this.state.pictureArray[this.state.currentPictureIndex] }, () => console.log(this.state.currImageDetections)));
//     }

//     // fetch stem word
//     async fetchStem(item) {
//         try {
//             const abortController = new AbortController();
//             const response = await fetch(`api/stem?word=${item}`, {
//                 method: "GET",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     token: localStorage.token
//                 },
//                 signal: abortController.signal
//             });
//             const data = await response.json();
//             return data;
//         } catch (error) {
//             console.error(error);
//             toast.error(error);
//         }
//     }

//     getRandomWordDetections(detectionArray) {
//         // set random index for picture from detections (first dimension, since this is a 2d array with each arr associated with the picture taken)
//         let randIdx = getRandomIndex(detectionArray.length);

//         // set arr within the item associated with the random index
//         let pictureArr = detectionArray[randIdx];

//         // set random item index within the selected array
//         let randItemIdx = getRandomIndex(pictureArr.length);

//         // finally get the item from the picture array
//         let item = pictureArr[randItemIdx];

//         return item;
//     }


//     // handle saving the photo to get more information on it
//     handlePhoto() {
//         if (this.state.picture === null) return;

//         this.setState({ pictureTaken: true }, async () => {
//             try {
//                 const response = await fetch(`api/image`, {
//                     method: "POST",
//                     headers: {
//                         'Content-Type': 'application/json',
//                         token: localStorage.token
//                     },
//                     body: JSON.stringify({ dataUri: this.state.picture })
//                 });
//                 const data = await response.json();

//                 console.log('data', data);

//                 this.setState({ 
//                     labels: data[0].labels,
//                     imageID: data[0].data_id
//                 });
//             } catch (error) {
//                 console.error(error);
//                 toast.error(error);
//             }
//         })
//     }

//     // find substring match within an array of strings
//     findSubstr(substr, array = []) {
//         console.log(substr, array);
//         const match = array.find(item => item.toLowerCase().includes(substr.toLowerCase()));

//         return match;
//     }

//     // checks match for stem answer and the the substring of the current image detections 
//     checkMatch = () => {
//         let match = this.findSubstr(this.state.toFind.answer, this.state.currImageDetections);

//         if (match.toLowerCase().includes(this.state.toFind.answer.toLowerCase())) this.setState({ isCorrect: true });
//         else this.setState({ isCorrect: false });
//     }

//     // get image localization after answer check
//     async handleGetImageLocalization(picture) {
//         try {
//             let dataUri = picture;
//             let body = { dataUri };
//             const response = await fetch(`api/bound-image`, {
//                 method: "POST",
//                 headers: {
//                     'Content-Type': 'application/json',
//                     token: localStorage.token
//                 },
//                 body: JSON.stringify(body)
//             });
//             const data = await response.json();

//             return data;
//         } catch (error) {
//             console.error(error);
//             toast.error(error);
//         }
//     }

//     setLabels = (newLabels) => this.setState({ labels: newLabels });

//     componentWillUnmount() {
//         console.log("Activity 5 unmounted");
//     }
    
//     render() {
//         const { toFind } = this.state;
//         return(
//             <div>
//                 {this.state.mode === 0 ? 
//                     <AboutCard
//                         iconImage={ 'fas fa-solid fa-question' } 
//                         cardTitle={ 'What does this describe?' }
//                         cardInstructions={ 'Find the object for the action word shown on the next page' }
//                         btnText={ 'Next' }
//                         StateFunction={() => this.setState({ mode: 1 }) }                       
//                     >   
//                     </AboutCard>           
//                 : null }
//                 {this.state.mode === 1 ? 
//                     <AboutCard
//                         iconImage={ 'fas fa-solid fa-question' } 
//                         cardTitle={ 'Find' }
//                         cardInstructions={ `Find the object that you can : ${this.state.toFind.hint}` }
//                         btnText={ "Start Activity" }
//                         StateFunction={() => this.setState({ mode: 2 }) }
//                     >
//                         <MenuCard icon_class={ "fas fa-undo" } title={ "Get new Item" } myFunction={() => {
//                             let item = this.getRandomWordDetections(this.state.detectionArray);

//                             // run stem word to this
//                             console.log('item', item);
//                             this.fetchStem(item).then(data => this.setState({ toFind: data }, () => console.log(data)))
//                         }} />
//                     </AboutCard>
//                 : null }
//                 {/* same idea as looping through the pictures, but the user selects which image has the answer */}
//                 {this.state.mode === 2 ? 
//                     <div className='picture-mode-container' style={{ height: '100%', paddingBottom: '80px' }}>
//                         <div className='image-content'>
//                             <div className='image-container'>
//                                 <p>Find the object that you can '{ toFind.hint }'</p>
//                                 { this.state.isCorrect && <h2 style={{ color: '#20c997' }}>Correct</h2> }
//                                 { this.state.isCorrect === false && <h2 style={{ color: '#ed5249' }}>Incorrect</h2> }
//                                 <div className='image-label'>
//                                     <div className='icon'>
//                                         <p>{this.state.currentPictureIndex + 1} / { this.state.pictureArray.length}</p>
//                                     </div>
//                                     <h1>Hint: { toFind.hint }</h1>
//                                     <img src={ this.state.pictureArray[this.state.currentPictureIndex] } alt='user-pic-taken' />
//                                     {/* <canvas ref={ this.canvasRef } width='325' height='243' className="image-canvas" />   */}
//                                 </div>
//                                 <div className='instructions'>
//                                     <p>Click the 'Check' button to see if your guess that the answer to the hint is in this picture</p>
//                                     { this.state.isCorrect === null || this.state.isCorrect === false ? <button className='general-btn' style={{ width: '100%' }} onClick={ this.checkMatch }>Check</button> : null }
//                                 </div>
//                             </div>
//                             <div className='image-info' style={{ width: '100%', overflowX: 'scroll', paddingLeft: 180, paddingBottom: 55 }}>
//                                 <div className='image-nav'> 
//                                     <div className='back' onClick={() => this.setState({ mode: 1, isCorrect: false })}>
//                                         <i class="fas fa-solid fa-backward"></i>
//                                         <div className='take-another'>Back</div>
//                                     </div>
//                                     <div className='back' onClick={ this.handlePreviousImage }>
//                                         <i class="fas fa-solid fa-arrow-left"></i>
//                                         <div className='take-another'>Last image</div>
//                                     </div>  
//                                     { this.state.isCorrect ? <div className='back' onClick={() => {
//                                         this.handlePhoto();
//                                         this.setState({ mode: 3 });   
//                                     }}>
//                                         <i className="fas fa-solid fa-info"></i>
//                                         <div className='take-another'>Save</div>
//                                     </div> : null }
//                                     <div className='back' onClick={ this.handleNextImage }>
//                                         <i className="fas fa-solid fa-arrow-right"></i>
//                                         <div className='take-another'>Next image</div>
//                                     </div>  
//                                     <div className='back' onClick={ () => this.props.handleOptionChange() }>
//                                         <i className="fas fa-solid fa-forward"></i>
//                                         <div className='take-another'>New activity</div>
//                                     </div>         
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//                 { this.state.mode === 3 ?
//                     <div className='picture-mode-container' style={{ overflowY: 'scroll', paddingBottom: '80px' }}>
//                         <div className='image-content'>
//                             <ImageContent picture={ this.state.picture } labels={ this.state.labels } setLabels={ this.setLabels } imageID={ this.state.imageID }  />
//                             <div className='image-info'>
//                                 <div className='image-nav'> 
//                                     <div className='back' onClick={() => this.setState({ mode: 2 })}>
//                                         <i class="fas fa-solid fa-arrow-left"></i>
//                                         <div className='take-another'>Try new hint</div>
//                                     </div>
//                                     <div className='back' onClick={() => this.props.handleOptionChange()}>
//                                         <i class="fas fa-solid fa-forward"></i>
//                                         <div className='take-another'>New activity</div>
//                                     </div>                 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 : null }
//             </div>
//         )
//     }
// }


export default withRouter(ScanMode);