import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { getRandomIndex } from '../../helper/randomizer';
import { FindObject } from './FindObject';
import { ModalButton } from './ModalButton';
import { ModalDialog } from './ModalDialog';

export class Modal extends Component {
     constructor(props) {
          super(props);

          this.state = {
               randomIndex: 0,
               item: "",
               toFind: "",
               isContinue: false,
               notFound: false
          }
     }

     async fetchStem(item) {
          try {
               const abortController = new AbortController();
               const response = await fetch(`api/stem?word=${item}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json", token: localStorage.token },
                    signal: abortController.signal
               });
               const parseResponse = await response.json();
               return parseResponse;
          } catch (error) {
               if (error.name === "AbortError") console.error("Fetch aborted");
               console.error(error.message);
               toast.error(error);
          }
     }

     componentDidMount() {
          if (this.props.option !== 2) this.setWordClue();
     }

     setRandom() {
          this.setState({ randomIndex: getRandomIndex(this.props.detections.length) }, () => {
               this.setState({ item: this.props.detections[this.state.randomIndex] }, () => {
                    console.log(this.state.item);
               });
          });
     }

     setWordClue() {
          this.setState({ randomIndex: getRandomIndex(this.props.detections.length) }, () => {
               this.setState({ item: this.props.detections[this.state.randomIndex] }, () => {
                    this.fetchStem(this.state.item)
                         .then(data => this.setState({ toFind: data }, () => console.log(data)));
               });
          });
     }

     checkSameWord(relatedItem) {
          if (relatedItem === true) {
               this.setState({ notFound: true });
          } else if (relatedItem === this.props.relatedItem) {
               this.setState({ isContinue: true });
          }
     }

     renderOption(option) {
          const { isContinue, notFound } = this.state;
          switch (option) {
               case 2:
                    if (notFound) return (
                         <ModalDialog title="Better luck next time" body={`The item that was not near you was ${this.props.relatedItem}!`} >
                              <ModalButton buttonName={"Continue"} handleClick={() => window.location.pathname = "/dash-board"} />
                         </ModalDialog>
                    )
                    if (isContinue) return (
                         <ModalDialog title="Nice Job, You Found It" body={`Good Job, ${this.props.relatedItem} was not an item that was near you!`} >
                              <ModalButton buttonName={"Continue"} handleClick={() => window.location.pathname = "/dash-board"} />
                         </ModalDialog>
                    );
                    return (
                         <ModalDialog title={"Which item is not there?"} body={this.props.detections} relatedItem={this.props.relatedItem} sendItem={(item) => this.checkSameWord(item)} option={2}>
                              <ModalButton buttonName={"Back"} handleClick={() => window.location.pathname = "/dash-board"} />
                         </ModalDialog>
                    );
               case 31:
                    if (isContinue) return <FindObject toFind={this.state.toFind} option={option} />
                    return (
                         <ModalDialog title={"Find "} body={this.state.toFind ? `Find ${this.state.toFind.answer} near you` : ""}>
                              <ModalButton buttonName={"Continue"} handleClick={() => this.setState({ isContinue: true })} />
                              <ModalButton buttonName={"Home"} handleClick={() => window.location.pathname = "/dash-board"} />
                              <ModalButton buttonName={"Find Different Object"} handleClick={() => this.setWordClue()} />
                         </ModalDialog>

                    );
               case 4:
                    if (isContinue) return <FindObject toFind={this.state.toFind} option={option} />
                    else return (
                         <ModalDialog title={"Find"} body={this.state.toFind ? `Find the object that you can ${this.state.toFind.hint}` : ""} >
                              <ModalButton buttonName={"Continue"} handleClick={() => this.setState({ isContinue: true })} />
                              <ModalButton buttonName={"Home"} handleClick={() => window.location.pathname = "/dash-board"} />
                              <ModalButton buttonName={"Find Different Object"} handleClick={() => this.setWordClue()} />
                         </ModalDialog>
                    );
               default:
                    break;
          }
     }

     render() {
          return this.renderOption(this.props.option);
     }
}