import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as cocossd from "@tensorflow-models/coco-ssd";
import Webcam from 'react-webcam';
import { VIDEO_CONSTRAINTS } from "../../helper/VideoConstraints";


export function WebCam({ finished, allDetections, toFind, sendImage }) {

     const [cameraReady, setCameraReady] = useState(false);
     const [detectionArray, setDetectionArray] = useState([]);

     const webcamRef = useRef(null);

     const capture = useCallback(() => {
          if (webcamRef !== null && webcamRef !== undefined) {
               const imageSrc = webcamRef.current.getScreenshot();
               return imageSrc;
          }
     }, [webcamRef]);

     const detect = async (net) => {
          if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
               // get video properties
               const video = webcamRef.current.video;
               const videoWidth = webcamRef.current.video.videoWidth;
               const videoHeight = webcamRef.current.video.videoHeight;

               // set video width
               webcamRef.current.video.width = videoWidth;
               webcamRef.current.video.height = videoHeight;

               // make detections
               const obj = await net.detect(video);

               // make sure tensorflow detection objects are not empty data
               if (obj.length !== 0) {
                    if (toFind.answer === obj[0].class) {
                         console.log(obj[0].class);
                         const image = await capture();
                         setTimeout(() => {
                              sendImage(image);
                         }, 1000);
                         webcamRef.current = null;
                    } 
                    console.log(obj[0].class);
                    setDetectionArray(oldArray => [...oldArray, obj[0].class]);
               }
          }
     }

     useEffect(() => {
          const runCoco = async () => {
               const net = await cocossd.load();

               setInterval(() => {
                    detect(net);
               }, 100);
          }
          if (cameraReady && !finished) {
               runCoco();
          }
          if (finished) {
               allDetections(detectionArray);
          }
          if (toFind !== "") {
               console.log(toFind);
          }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [cameraReady, finished]);

     return <Webcam audio={ false } ref={ !finished ? webcamRef : null } muted={ true } className="webcam" videoConstraints={ VIDEO_CONSTRAINTS } screenshotFormat="image/png" screenshotQuality={ 1.0 } onUserMedia={() => setCameraReady(true)} />;

}
